import * as yup from "yup";
export function createYupSchema(formFields: any) {
  const yupSchema: { [key: string]: any } = {};
  let fieldSchema: any = null;

  formFields.forEach((items: any) => {
    const { field, fieldType, validations, required, acceptedValues } = items;

    if (
      fieldType &&
      [
        "string",
        "number",
        "date",
        "boolean",
        "array",
        "object",
        "email",
        "phoneNumber",
      ].includes(fieldType)
    ) {
      if (fieldType === "email") {
        fieldSchema = yup.string().email("Invalid email format.");
      } else if (fieldType === "phoneNumber") {
        const maxValidation = validations.find(
          (num: any) => num.type === "max"
        );
        const maxMessage = maxValidation?.message
          .replace("cannot be longer than 20 characters", "")
          .trim();
        const digitCountMessage = `${maxMessage} should have at least 8 digits and up to 14 digits`;

        fieldSchema = yup
          .string()
          .max(20, validations?.message)
          .test("valid-digit-count", digitCountMessage, (value) => {
            if (!value) return true;
            const digitCount = value.replace(/\D/g, "").length;
            return digitCount >= 8 && digitCount <= 14;
          })
          .matches(
            /^[0-9+\-()\s]*$/,
            "Only numbers, spaces, hyphens (-), parentheses (), and plus (+) are allowed"
          );
      } else if (fieldType === "number") {
        fieldSchema = yup.number().typeError("Field must be a valid number");
      } else if (fieldType === "date") {
        fieldSchema = yup.date().nullable();
      } else {
        fieldSchema = (yup as any)[fieldType]();
      }
      if (acceptedValues && Array.isArray(acceptedValues)) {
        fieldSchema = fieldSchema.test(
          "acceptedValuesCheck",
          `Value must be one of: ${acceptedValues.join(", ")}`,
          (value: any) => {
            return value == null || acceptedValues.includes(value);
          }
        );
      }

      if (validations && validations.length > 0) {
        validations.forEach((validation: any) => {
          switch (validation.type) {
            case "required":
              fieldSchema = fieldSchema.required(
                validation.message || "This field is required."
              );
              break;
            case "min":
              if (fieldType === "number") {
                fieldSchema = fieldSchema
                  .typeError("Field must be a number")
                  .min(
                    validation.value,
                    validation.message ||
                      `Value must be at least ${validation.value}.`
                  );
              } else {
                fieldSchema = fieldSchema.min(
                  validation.value,
                  validation.message ||
                    `Value must be at least ${validation.value}.`
                );
              }

              break;
            case "max":
              if (fieldType === "number") {
                fieldSchema = fieldSchema
                  .typeError("Field must be a number")
                  .max(
                    validation.value,
                    validation.message ||
                      `Value must be at most ${validation.value}.`
                  );
              } else {
                fieldSchema = fieldSchema.max(
                  validation.value,
                  validation.message ||
                    `Value must not exceed ${validation.value} characters.`
                );
              }
              break;
            case "email":
              if (fieldType === "string") {
                fieldSchema = fieldSchema.email(
                  validation.message || "Invalid email format."
                );
              }
              break;
            case "phoneNumber":
              if (fieldType === "string") {
                fieldSchema = fieldSchema.matches(
                  /^(?:\+?[0-9]|0)[0-9]{1,14}$/,
                  validation.message || "Invalid phone number format."
                );
              }
              break;
            case "integer":
              if (fieldType === "number") {
                fieldSchema = fieldSchema.integer(
                  validation.message || "Value must be an integer."
                );
              }
              break;
            case "precision":
              if (fieldType === "float" || fieldType === "number") {
                fieldSchema = fieldSchema
                  .typeError("Field must be a valid number")
                  .test(
                    "is-precision",
                    validation.message ||
                      `Value must have a maximum of 16 digits in total and at most ${validation.value} decimal places.`,
                    (value: any) => {
                      if (value == null) return true; // Allow null or undefined values

                      const valueStr = String(value).replace(/^0+/, ""); // Remove leading zeros

                      // Remove the decimal point and count total digits
                      const totalDigits = valueStr.replace(".", "").length;

                      // Get decimal places count
                      const decimalPlaces = valueStr.includes(".")
                        ? valueStr.split(".")[1].length
                        : 0;

                      return (
                        totalDigits <= 16 && decimalPlaces <= validation.value
                      );
                    }
                  );
              }
              break;
            case "pattern":
              if (fieldType === "string") {
                fieldSchema = fieldSchema.matches(
                  validation.value, // Regex pattern
                  validation.message || "Invalid format."
                );
              } else if (fieldType === "number") {
                fieldSchema = fieldSchema
                  .transform((value: any) =>
                    value !== null && value !== undefined
                      ? String(value)
                      : value
                  )
                  .test(
                    "pattern",
                    validation.message || "Invalid format.",
                    (value: any) => new RegExp(validation.value).test(value)
                  );
              }
              break;
            case "date":
              if (fieldType === "date") {
                fieldSchema = yup
                  .date()
                  .nullable()
                  .typeError(validation.message || "Invalid date format.");
              }
              break;
            case "minDate":
              if (fieldType === "date") {
                fieldSchema = fieldSchema.min(
                  new Date(validation.value),
                  validation.message ||
                    `Date must be on or after ${validation.value}.`
                );
              }
              break;
            case "maxDate":
              if (fieldType === "date") {
                fieldSchema = fieldSchema.max(
                  new Date(validation.value),
                  validation.message ||
                    `Date must be on or before ${validation.value}.`
                );
              }
              break;
            case "alphanumeric":
              if (fieldType === "string") {
                fieldSchema = fieldSchema.matches(
                  /^[a-zA-Z0-9]*$/,
                  validation.message || "Value must be alphanumeric."
                );
              }
              break;
            // case "uuid":
            //   if (fieldType === "string") {
            //     fieldSchema = fieldSchema.matches(
            //       /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
            //       validation.message || "Invalid UUID format."
            //     );
            //   }
            //   break;
            case "enum":
              if (validation.value && Array.isArray(validation.value)) {
                fieldSchema = fieldSchema.oneOf(
                  validation.value,
                  validation.message ||
                    `Value must be one of: ${validation.value.join(", ")}.`
                );
              }
              break;
            case "precision":
              if (fieldType === "number") {
                fieldSchema = fieldSchema
                  .typeError("Field must be a valid number")
                  .test(
                    "is-precision",
                    validation.message ||
                      `Value must have up to ${validation.value} decimal places.`,
                    (value: any) => {
                      // Allow null or undefined values for optional fields
                      if (value == null) return true;
                      if (isNaN(value)) return false;
                      // Check if the value is a number and validate its precision

                      const [_, decimalPart] = String(value).split(".");
                      return (
                        !decimalPart || decimalPart.length <= validation.value
                      );
                    }
                  );
              }
              break;

            default:
              break;
          }
          yupSchema[field] = fieldSchema;
        });
      }
    }
  });
  return yupSchema;
}
