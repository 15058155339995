import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  makeStyles,
  tokens,
  useToastController,
  useId,
  Toaster,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getAssetDetailById,
  getAssetPaymentDetailById,
  getAssetWithdrawalDetailById,
  getAssetWithdrawalsListItems,
} from "../../../../redux/Case/assetSlice";
import { getCaseCategoryByCaseType } from "../../../../utils";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import CaseDetailDrawer from "../CaseDetailDrawer";
import { useToastMessage } from "../../../../components/ToastMessage/ToastMessage";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function AssetWithdrawals({ tab }: { tab: string }) {
  const navigate = useNavigate();
  const styles = useStyles();
  const { showToast, toasterId } = useToastMessage();

  const { clientId, action, caseId, subItemId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const assets = useSelector((state: RootState) => state.assets);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  const [caseType, setCaseType] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getAssetDetailById({ id: caseId, customerId: clientId }));
      dispatch(
        getAssetWithdrawalsListItems({ id: caseId, customerId: clientId })
      );
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (assets?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(assets.activeItem.case_type));
    }
  }, [assets?.activeItem]);

  useEffect(() => {
    if (action && action != "add" && assets?.withdrawals.listItems.length > 0) {
      const withdrawalId =
        subItemId || assets.withdrawals.listItems[0]?.withdrawal_id;
      navigate(
        `/clients/${clientId}/assets/${caseId}/withdrawals/${withdrawalId}/${tab}/${
          action || "view"
        }`
      );
    }
  }, [
    clientId,
    caseId,
    subItemId,
    tab,
    action,
    assets.withdrawals.listItems,
    navigate,
  ]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getAssetWithdrawalDetailById(subItemId));
    }
  }, [subItemId, dispatch]);

  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <CaseNavList
        subItem="withdrawals"
        section="assets"
        tab={tab}
        caseType={caseType}
        caseDetail={assets?.withdrawals.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="assets"
          caseType={caseType}
          caseDetail={assets?.withdrawals.activeItem}
          caseItemList={assets?.withdrawals.listItems}
          tab={tab}
          subItem="withdrawals"
          withdrawalValue={assets.withdrawals.totalWithdrawals}
          notify={showToast}
        />
        <div>
          {assets?.withdrawals.listloading ? (
            <ContentLoader />
          ) : (
            <>
              {assets?.withdrawals.listItems.length > 0 && (
                <CaseList
                  module="withdrawals"
                  data={assets?.withdrawals.listItems}
                  activeItem={assets?.withdrawals.activeItem}
                  section="assets"
                  activeTab={tab}
                />
              )}
              {!action && assets?.withdrawals.listItems.length === 0 && (
                <div className={styles.emptyState}>
                  <Label size="large" style={{ color: "red" }}>
                    There are no active records - click Add New to create a new
                    record
                  </Label>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={assets?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default AssetWithdrawals;
