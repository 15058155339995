// Breadcrumbs.js
import React, { useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import "./Breadcrumbs.css"; // Import your CSS for styling
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbProps,
  BreadcrumbButton,
  BreadcrumbLink,
} from "@fluentui/react-breadcrumb-preview";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  displayRecordCount,
  getItemDisplayValue,
  ItemDisplayKeys,
} from "../../utils";
const breadcrumbNameMap: any = {
  clients: "Clients",
  "action-list": "Action List",
  utilities: "Utilities",
  report: "Reports",
  "system-manager": "System Manager",
  commission: "Commissions",
  distribution: "Distribution",
  "other-fees-charges": "Other Fees/Charges",
  receipting: "Receipting",
  "manage-user": "Manage User",
  "master-settings": "Master Settings",
  "commission-type": "Commission Type",
  "commission-rule": "Commission Rule",
  subscription: "Subscription",
  "company-detail": "Company Details",
  "income-category": "Income Categories",
  "expense-category": "Expense Categories",
  "attitude-risk-categories": "Attitude-to-Risk Categories",
  "attitude-risk-ratings": "Attitude-to-Risk Ratings",
  objectives: "Objectives",
  providers: "Providers",
  "user-defined": "User Defined",
  "case-action": "Case Action",
  "client-action": "Client Action",
  "email-template": "Email ",
  "letter-template": "Letter ",
  "contract-enquiry": "Contract Enquiry",
  integrations: "Integrations",
  "company-details": "Company Details",
  customisations: "Customisations",
  templates: "Templates",
  template: "Templates",
  assets: "Assets",
  asset: "Asset",
  view: "View",
  edit: "Edit",
  add: "Add",
  review: "Review",
  "name-and-address": "Name And Address",
  contact: "Contact",
  nationality: "Nationality",
  personal: "Personal",
  "health-note": "Health Note",
  vulnerability: "Vulnerability",
  employment: "Employment",
  "attitude-to-risk": "Attitude to Risk",
  identity: "Identity",
  administration: "Administration",
  compliance: "Compliance",
  "service-type": "Service Type",
  "client-summary": "Client Summary",
  "case-summary": "Case Summary",
  "contact-history": "Contact History",
  partner: "Partner",
  payments: "Payments",
  withdrawals: "Withdrawals",
  actions: "Actions",
  valuations: "Valuations",
  funds: "Funds",
  fund: "Fund",
  withdrawal: "Withdrawal",
  action: "Action",
  valuation: "Valuation",
  liabilities: "Liabilities",
  liability: "Liability",
  policies: "Policies",
  policy: "Policy",
  dependant: "Dependant",
  income: "Income",
  outgoings: "Outgoings",
  "address-book": "Address Book",
  "fact-find-notes": "Fact Find Notes",
  "fees-charges": "Fees/Charges",
  notes: "Notes",
  "time-allocation": "Time Allocation",
  documents: "Documents",
  contacts: "Contacts",
  email: "Email",
  letter: "Letter",
};

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeClient: any = useSelector(
    (state: RootState) => state.activeClient
  );
  const assets: any = useSelector((state: RootState) => state.assets);
  const liabilities: any = useSelector((state: RootState) => state.liabilities);
  const policies: any = useSelector((state: RootState) => state.policies);
  const pathnames = location.pathname.split("/").filter((x) => x);
  const {
    clientId,
    caseId,
    subItemId,
    subSubItemId,
    action,
    selectedItemId,
    distributionId,
  } = useParams();
  // useEffect(() => {
  // }, [location]);

  const handleNavigationLink = (link: any) => {
    navigate(link);
  };
  const currentPath = location.pathname.replace(/^\/+/, "");
  const hiddenPaths = ["/clients", "/", "/action-list", "/report"];

  if (hiddenPaths.includes(location.pathname)) {
    return <div></div>;
  }

  return (
    <Breadcrumb aria-label="Breadcrumb">
      <BreadcrumbItem>
        <BreadcrumbButton onClick={() => handleNavigationLink("/")}>
          Home
        </BreadcrumbButton>
      </BreadcrumbItem>
      {currentPath == "" && (
        <>
          <BreadcrumbDivider />

          <BreadcrumbItem>
            <BreadcrumbButton current>Clients</BreadcrumbButton>
          </BreadcrumbItem>
        </>
      )}

      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        let displayValue = value;
        const pathSections = location.pathname.split("/");
        const caseType = ["assets", "liabilities", "policies"].find((type) =>
          pathSections.includes(type)
        );
        if (value === clientId) {
          displayValue =
            activeClient?.basicDetails?.first_names &&
            activeClient?.basicDetails?.last_name
              ? `${activeClient?.basicDetails?.last_name} , ${activeClient?.basicDetails?.first_names} `
              : "Client Detail";
        } else if (value === caseId) {
          const idKey =
            caseType === "policies"
              ? "policy_id"
              : caseType === "liabilities"
              ? "liability_id"
              : "asset_id";

          const itemsArray =
            caseType === "policies"
              ? policies?.listItems
              : caseType === "liabilities"
              ? liabilities?.listItems
              : assets?.listItems;
          const activeItem =
            caseType === "policies"
              ? policies?.activeItem
              : caseType === "liabilities"
              ? liabilities?.activeItem
              : assets?.activeItem;
          const activeItemIndex = itemsArray?.findIndex(
            (item: any) => item[idKey] === activeItem?.[idKey]
          );

          if (displayRecordCount().includes(caseType)) {
            displayValue = `Record ${parseInt(activeItemIndex) + 1}`;
          } else {
            displayValue = getItemDisplayValue(
              activeItem,
              caseType ? caseType : "",
              ItemDisplayKeys
            );
          }
        } else if (value === subItemId || value === selectedItemId) {
          if (
            location.state?.index &&
            location.state.index != undefined &&
            displayRecordCount().includes(location.state?.section || "")
          ) {
            displayValue = `Record ${location.state.index}`;
          } else {
            displayValue = getItemDisplayValue(
              location.state?.selectedItem,
              location.state?.section || "",
              ItemDisplayKeys
            );
          }
        } else if (value === subSubItemId) {
          displayValue = "Detail";
        } else if (value === action) {
          displayValue = action
            ? action.charAt(0).toUpperCase() + action.slice(1)
            : "View";
        } else {
          displayValue = breadcrumbNameMap[value] || value;
        }
        return (
          <React.Fragment key={to}>
            <BreadcrumbDivider />

            <BreadcrumbItem>
              {isLast ? (
                <BreadcrumbButton current>
                  {breadcrumbNameMap[displayValue] || displayValue}
                </BreadcrumbButton>
              ) : (
                <BreadcrumbButton onClick={() => handleNavigationLink(to)}>
                  {breadcrumbNameMap[displayValue] || displayValue}
                </BreadcrumbButton>
              )}
            </BreadcrumbItem>
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
