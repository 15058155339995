import { Body1, Button } from "@fluentui/react-components";
import React, { useState } from "react";
import { DismissFilled } from "@fluentui/react-icons";
import ASCButton from "../../components/Button";

const Specifications = ({
  onNewClick,
  cancelSpecification,
  deleteList,
  specificationList,
  setSelectedSpecId,
  selectedSpecId,
  selectedListingEdit,
  setEditData,
}: // selectedCondition,

{
  onNewClick: () => void;
  cancelSpecification: () => void;
  deleteList: (id: any) => void;
  specificationList: any[];
  setSelectedSpecId: (id: any) => void;
  selectedSpecId: any;
  selectedListingEdit: () => void;
  setEditData: any;
  // selectedCondition: () => void;
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "column",
        }}
      >
        <Body1>
          <b>Export Specifications</b>
        </Body1>
        <div>
          <span>
            <Body1>
              Select a saved specification from this list or Click Next to
              create a new one
            </Body1>
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <ASCButton
            shape="rounded"
            appearance="primary"
            className="asc-button-primary"
            size="small"
            onClick={onNewClick}
            label="New"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #D1D1D1",
            maxHeight: "400px",
            minHeight: "250px",
            overflowX: "auto",
          }}
        >
          <span
            onClick={() => setSelectedSpecId}
            style={{
              padding: "5px",
              // background: selectedSpecId ? "#f5f5f5" : "transparent",
            }}
          >
            {/* Export all data */}
          </span>
          {specificationList.map((spec: any) => (
            <div
              key={spec.SearchId}
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                background:
                  selectedSpecId === spec.SearchId ? "#f5f5f5" : "transparent",
              }}
              onClick={() => {
                try {
                  const conditionArray = JSON.parse(spec.Condition);
                  const paths = conditionArray
                    .map((item: any) => {
                      const labelParts = item.label.split(",path:");
                      return labelParts[1] ? labelParts[1].trim() : null;
                    })
                    .filter(Boolean);
                  setSelectedSpecId(spec.SearchId);
                  setEditData(paths);
                } catch (error) {
                  console.error("Error parsing Condition:", error);
                  setSelectedSpecId(spec.SearchId);
                }
              }}
            >
              <div style={{ padding: "5px" }}>
                <span>{spec.Title}</span>
              </div>
              {spec.id !== 1 && (
                <div
                  style={{ padding: "5px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteList(spec.SearchId);
                  }}
                >
                  <DismissFilled />
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "5px",
          }}
        >
          <ASCButton
            shape="rounded"
            size="medium"
            disabled={!selectedSpecId}
            appearance="primary"
            className="asc-button-primary"
            onClick={() => selectedListingEdit()} // Call seems valid
            label="Next"
          />

          <ASCButton
            shape="rounded"
            size="medium"
            appearance="secondary"
            onClick={cancelSpecification}
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default Specifications;
