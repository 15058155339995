import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import Search from "../../components/Search";
import Multiselectsearch from "../../components/MultiSearchDropdown";
import { DropdownDatepicker } from "../../components/DropdownwithDatepicker";
import ModalDialog from "../../components/ModalDialog";
import { useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  type MRT_ColumnDef,
} from "material-react-table";

import { RootState } from "../../redux/store";
import {
  Divider,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Link,
  OverlayDrawer,
  Toast,
  ToastTitle,
  ToastTrigger,
  Toaster,
  makeStyles,
  useId,
  useToastController,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogContent,
} from "@fluentui/react-components";
import apiServices from "../../service";
import { MRT_PaginationState } from "material-react-table";
import { Paginate } from "./Paginate";
import ReactQuillComponent from "../../components/ReactQuillComponent";
import ActionListForm from "./ActionListForm";
import {
  convertStringToArray,
  formatedDate,
  formatedTime,
  getClientType,
  getYes_NoConversion,
  isObjectEmpty,
} from "../../utils";
import {
  openActionListDetailPopup,
  setActionListSelectedItem,
} from "../../redux/appSettingsSlice";
import {
  banksBSocieties,
  clientDetails,
  creditCards,
  General,
  healthAssurance,
  homePersonal,
  incomeProtection,
  Investments,
  lifeAssurance,
  loansLeaseHP,
  Mortages,
  pensions,
  savingsPlans,
  Shares,
  unitLinked,
} from "../../shared/actionlistDetails";
import ContentLoader from "../../components/Loader/ContentLoader";
import { getCustomerReview } from "./Services";
import { TableListLoader } from "../../components/Loader/TableListLoader";
import ASCButton from "../../components/Button";
import defaultTableConfig from "../../shared/tableConfig.json";
import {
  fetchUserPreferences,
  savePreferences,
  selectDefaultConfig,
  selectPreferenceStatus,
  selectTableColumnOrder,
  selectTableColumns,
  setTableColumnOrder,
} from "../../redux/userPreferenceSlice";
interface PropsType {
  setTabs?: Dispatch<SetStateAction<string>>;
}
const useStyles = makeStyles({
  divider: {
    flexGrow: 0,
  },
});
interface RowItem {
  case_type: string;
  business_category: number;
  itemId: string;
  customer_id: string;
  case_id: string;
  category: string;
}
const List = ({ setTabs }: PropsType) => {
  const toasterId = useId("toaster");
  const styles = useStyles();
  const { dispatchToast } = useToastController(toasterId);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateReminder, setUpdateReminder] = useState(false);
  const [actionListItems, setActionListItems] = useState<any>([]);
  const [option, setOption] = useState<string>(
    "category=asset_bank_review&category=asset_shares_review&category=asset_investment_review&category=asset_investment&category=asset_home_review&category=credit_review_date&category=loan_review_date&category=mortgage_review_date&category=policy_review_date&category=client_action&category=case_tracking_action&category=client_review&category=bithday"
  );
  const [rowCount, setRowCount] = useState(0);
  const [optionDate, setOptionDate] = useState("pastDate");
  const [optionDateValue, setOptionDateValue] = useState("Past Dates");
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [selectedDropDownValues, setSelectedDropDownValues] = useState<
    string[]
  >([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [nextPage, setnextPage] = useState(false);
  const [isDetailDataLoading, setIsDetailDataLoading] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(true);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const appSettings = useSelector((state: RootState) => state.appSettings);
  const [selectedConsultantValues, setSelectedConsultantValues] = useState<
    string[]
  >([]);
  const [consultantOptions, setConsultantOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const dispatch: AppDispatch = useDispatch();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [activeRowItem, setActiveRowItem] = useState<any | {}>({});
  const [activeRowItemDetail, setActiveRowItemDetail] = useState<any | {}>({});
  const [activeUpdateRowItemDetail, setActiveUpdateRowItemDetail] = useState<
    any | {}
  >({});
  const [actionCategories, setActionCategories] = useState<any>([
    { label: "Client Action", value: "client_action" },
    { label: "Asset (Banks) Review", value: "asset_bank_review" },
    { label: "Asset (Home/Person)", value: "asset_home_review" },
    {
      label: "Asset (Investment) Review",
      value: "asset_investment_review",
    },
    { label: "Asset (Shares) Review", value: "asset_shares_review" },
    { label: "Case Tracking Action", value: "case_tracking_action" },
    { label: "Client Review", value: "client_review" },
    { label: "Credit Card Review Date", value: "credit_review_date" },
    { label: "Loan Review Date", value: "loan_review_date" },
    { label: "Mortgage Review Date", value: "mortgage_review_date" },
    { label: "Policy Review Date", value: "policy_review_date" },
    { label: "Birthday", value: "birthday" },
  ]);
  const [selectedActionCategories, setSelectedActionCategories] = useState<any>(
    [
      "asset_home_review",
      "credit_review_date",
      "mortgage_review_date",
      "loan_review_date",
      "policy_review_date",
      "client_review",
      "client_action",
      "asset_bank_review",
      "case_tracking_action",
      "asset_investment_review",
      "asset_shares_review",
      "asset_bank_review",
      "birthday",
    ]
  );
  const CaseCateoryListMaping: any = {
    "Unit-linked": "Asset(Investment) Review",
    "Banks/B Societies": "Asset (Banks) Review",
    Shares: "Asset (Shares) Review",
    "Home/Personal": "Asset (Home/Person)",
    "Life Assurance": "Policy Review Date",
    Pensions: "Policy Review Date",
    Investements: "Policy Review Date",
    "Savings Plans": "Policy Review Date",
    "Income Protection": "Policy Review Date",
    "Health Assurance": "Policy Review Date",
    General: "Policy Review Date",
    Mortgages: "Mortgage Review Date",
    "Loans/Lease/HP": "Loan Review Date",
    "Credit Cards": "Credit Card Review Date",
  };
  const [assigneeOptions, setAssigneeOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const tableName = "ACTION_LIST";
  const columns = useSelector(selectTableColumns(tableName));
  const reduxColumnOrder = useSelector(selectTableColumnOrder(tableName));
  const defaultConfig = useSelector(selectDefaultConfig);
  const preferenceStatus = useSelector(selectPreferenceStatus);
  const reduxColumns = useSelector(selectTableColumns(tableName));
  const userPreferences = useSelector(
    (state: RootState) => state.userPreferences.preferences
  );
  const tables = useSelector(
    (state: RootState) =>
      state.userPreferences.preferences?.tables || {
        defaultConfig: {},
        contextSpecific: {},
      }
  );
  const contextSpecific = useSelector(
    (state: RootState) =>
      state.userPreferences.preferences?.tables?.contextSpecific || {}
  );
  const tableSettings = useSelector(
    (state: RootState) =>
      state.userPreferences.preferences?.tables?.contextSpecific?.[tableName] ||
      {}
  );
  const tableInitialState = useSelector(
    (state: RootState) =>
      state.userPreferences.preferences?.tables?.contextSpecific?.[tableName]
        ?.initialState || {}
  );

  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  useEffect(() => {
    if (preferenceStatus === "idle") {
      dispatch(fetchUserPreferences());
    }
  }, [preferenceStatus]);
  useEffect(() => {
    const newOrder =
      reduxColumnOrder.length > 0
        ? reduxColumnOrder
        : columns.map((col: any) => col.accessorKey);
    if (JSON.stringify(newOrder) !== JSON.stringify(columnOrder)) {
      setColumnOrder(newOrder);
    }
  }, [reduxColumnOrder, columns, columnOrder]);

  const customColumns = useMemo<MRT_ColumnDef<any>[]>(() => {
    if (!reduxColumns || reduxColumns.length === 0) {
      return [];
    }

    return reduxColumns.map((col: any) => {
      const enhancedCol: MRT_ColumnDef<any> = { ...col };

      if (typeof col.Cell === "string") {
        switch (col.Cell) {
          case "formatedDate":
            enhancedCol.Cell = ({ row }) => (
              <>{formatedDate(row.original[col.accessorKey])}</>
            );
            break;
          case "formatedTime":
            enhancedCol.Cell = ({ row }) => (
              <>{formatedTime(row.original[col.accessorKey])}</>
            );
            break;
          case "ReactQuillComponent":
            enhancedCol.Cell = ({ row }) => (
              <ReactQuillComponent value={row.original[col.accessorKey]} />
            );
            break;
          default:
            enhancedCol.Cell = ({ row }) => (
              <>{row.original[col.accessorKey]}</>
            );
            break;
        }
      } else if (typeof col.Cell === "function") {
        enhancedCol.Cell = col.Cell;
      }

      return enhancedCol;
    });
  }, [reduxColumns]);

  const config = useMemo(
    () => ({
      ...defaultConfig,
      enablePagination: false,
      enableColumnOrdering: true,
      enableColumnDragging: true,
      initialState: {
        ...defaultConfig.initialState,
        density: "compact" as const,
        columnOrder,
        columnPinning: { right: ["mrt-row-actions"] },
      },
    }),
    [defaultConfig, columnOrder]
  );
  const handleColumnOrderChange = (
    updater: string[] | ((prev: string[]) => string[])
  ) => {
    const newOrder =
      typeof updater === "function" ? updater(columnOrder) : updater;
    setColumnOrder(newOrder);
    dispatch(setTableColumnOrder({ tableName, columnOrder: newOrder }));
  };

  const handleSavePreference = async () => {
    const updatedSettings = {
      ...userPreferences,
      tables: {
        ...tables,
        contextSpecific: {
          ...contextSpecific,
          [tableName]: {
            ...tableSettings,
            initialState: {
              ...tableInitialState,
              columnOrder: columnOrder?.length
                ? columnOrder
                : tableInitialState?.columnOrder,
            },
          },
        },
      },
    };

    const isUpdatedSettingsEmpty =
      !updatedSettings ||
      Object.keys(updatedSettings).length === 0 ||
      !updatedSettings.tables ||
      Object.keys(updatedSettings.tables).length === 0;

    const finalSettings = isUpdatedSettingsEmpty
      ? defaultTableConfig
      : updatedSettings;

    try {
      const { message } = await dispatch(
        savePreferences(finalSettings)
      ).unwrap();

      // ✅ Show success notification
      notify(message, "success");
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };

  useEffect(() => {
    setColumnOrder((prevColumnOrder) => {
      let updatedOrder = [...prevColumnOrder];

      if (!updatedOrder.includes("mrt-row-select")) {
        updatedOrder.push("mrt-row-select");
      }

      return prevColumnOrder === updatedOrder ? prevColumnOrder : updatedOrder;
    });
  }, []);

  useEffect(() => {
    const newOption = selectedConsultantValues.join("&");
    setOption(newOption);
  }, [selectedConsultantValues]);

  useEffect(() => {
    if (appSettings?.actionListDetailPopup) {
      if (!isObjectEmpty(activeRowItem)) {
        setIsDetailLoading(true);
        setActiveRowItemDetail([]);
        if (
          activeRowItem.category === "Client Action" ||
          activeRowItem.category === "Client Review" ||
          activeRowItem.category === "Birthday"
        ) {
          getCustomerDetail(activeRowItem.customer_id);
        } else if (activeRowItem.category === "Case Tracking Action") {
          getCaseActionDetails(activeRowItem);
        } else {
          getCaseDetail(activeRowItem);
        }
      }
    }
  }, [appSettings?.actionListDetailPopup]);
  useEffect(() => {}, [activeUpdateRowItemDetail, activeRowItem]);
  const getCustomerDetail = (customerId: string) => {
    fetchClientDetail(apiServices.client.getDetail, customerId);
  };
  const getCustomerActionDetail = (customerId: string, actionId: string) => {
    apiServices.contactClientAction
      .getById(actionId, customerId)
      .then((response: any) => {
        setIsDetailDataLoading(false);
        if (
          response.data &&
          !response.data.isError &&
          !isObjectEmpty(response.data.data)
        ) {
          setActiveUpdateRowItemDetail({
            date: response.data.data.appointment_date,
            time: response.data.data.appointment_date,
            type: response.data.data.appointment_type,
            description: response.data.data.appointment_note,
            assigned: response.data.data.appointment_assigned,
            status: response.data.data.appointment_status,
            category: "Client Action",

            customer_id: customerId,
            appointment_id: actionId,
            case_type: activeRowItem.case_type,
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsDetailDataLoading(false);
      });
  };
  const getItemDetail = async (row: any) => {
    setIsDetailDataLoading(true);
    setActiveRowItem(row);
    if (!isObjectEmpty(row)) {
      let activeRowItem = row;
      if (activeRowItem.category === "Client Action") {
        getCustomerActionDetail(
          activeRowItem.customer_id,
          activeRowItem.case_id
        );
      } else if (activeRowItem.category === "Client Review") {
        setIsDetailDataLoading(true);
        const customerReview = await getCustomerReview(
          activeRowItem.customer_id
        );

        if (customerReview) {
          setIsDetailDataLoading(false);
          setActiveUpdateRowItemDetail({
            review_interval: customerReview.review_interval,
            review_reason: customerReview.review_note,
            review_assigned: customerReview.review_assigned,
            review_completed: customerReview.review_completed,
            consultant_id: customerReview.consultant_id,
            consultant: customerReview.consultant,
            review_date: customerReview.review_date,
            category: "Client Review",
            customer_id: activeRowItem.customer_id,
            case_type: activeRowItem.case_type,
          });
        } else {
          setIsDetailDataLoading(false);
        }
      } else if (activeRowItem.category === "Case Tracking Action") {
        setActiveUpdateRowItemDetail({
          action_date: activeRowItem.action_date,
          description: activeRowItem.description,
          assigned: activeRowItem.assigned,
          status: activeRowItem.status,
          category: "Case Tracking Action",
          case_id: activeRowItem.case_id,
          customer_id: activeRowItem.customer_id,
          business_category: activeRowItem.business_category,
          case_type: activeRowItem.case_type,
          business_id: activeRowItem.business_id,
        });
        setIsDetailDataLoading(false);
      } else {
        getCaseDetail(activeRowItem);
        setIsDetailDataLoading(false);
      }
    }
  };
  const fetchClientDetail = (
    apiCall: (customerId: string) => Promise<any>,
    customerId: string
  ) => {
    apiCall(customerId)
      .then((response: any) => {
        setIsDetailLoading(false);
        if (
          response.data &&
          !response.data.isError &&
          !isObjectEmpty(response.data.data)
        ) {
          setActiveRowItemDetail(response.data.data);
        }
      })
      .catch((err) => {
        setIsDetailLoading(false);
        console.log(err);
      });
  };
  const getCaseDetail = (rowItem: RowItem) => {
    const apiCall = caseCategoryMap[rowItem.category];

    if (apiCall) {
      fetchDetail(apiCall, rowItem.case_id, rowItem.customer_id);
    } else {
      setIsDetailLoading(false);
      console.error("No matching case_type found");
    }
  };

  const getCaseActionDetails = (rowItem: RowItem) => {
    const apiCall = businessCategoryMap[rowItem.business_category];
    if (apiCall) {
      fetchDetail(apiCall, rowItem.case_id, rowItem.customer_id);
    } else {
      setIsDetailLoading(false);
      console.error("No matching business_category found");
    }
  };
  const fetchDetail = (
    apiCall: (itemId: string, customerId: string) => Promise<any>,
    itemId: string,
    customerId: string
  ) => {
    apiCall(itemId, customerId)
      .then(handleSuccessResponse)
      .catch(handleErrorResponse);
  };
  const handleSuccessResponse = (response: any) => {
    setIsDetailLoading(false);
    if (
      response.data &&
      !response.data.isError &&
      !isObjectEmpty(response.data.data)
    ) {
      setActiveRowItemDetail(response.data.data);
      setActiveUpdateRowItemDetail({
        date: response.data.data.review_date,
        review_interval: response.data.data.review_interval,
        review_reason: response.data.data.review_reason,
        review_assigned: response.data.data.review_assigned,
        category: CaseCateoryListMaping[response.data.data.case_type] || "",
        case_type: response.data.data.case_type || "",
        review_completed: response.data.data.review_completed,
        customer_id: response.data.data.customer_id,
        caseId:
          response.data?.data?.policy_id ||
          response.data?.data?.liability_id ||
          response.data?.data?.asset_id ||
          "",
        owner:
          response.data?.data?.policy_holder ||
          response.data?.data?.joint_indicator ||
          0,
      });
    } else if (response.data.isError) {
      if (
        response.data.error &&
        Object.keys(response.data.error).length !== 0
      ) {
        let errorMessage = "";

        if (Array.isArray(response.data.error.message)) {
          errorMessage = response.data.error.message.join("\n"); // Join all error messages
        } else {
          errorMessage = response.data.error.message;
        }

        notify(errorMessage, "error");
      }
    } else {
      notify("Something went wrong", "error");
    }
  };
  const handleErrorResponse = (err: any) => {
    setIsDetailLoading(false);
    console.log(err);
    notify(err.message, "error");
  };

  const caseCategoryMap: any = {
    "Asset(Banks) Review": apiServices.assets.getDetail,
    "Asset(Home) Review": apiServices.assets.getDetail,
    "Asset(Investment) Review": apiServices.assets.getDetail,
    "Asset(Shares) Review": apiServices.assets.getDetail,
    "Credit Card Review Date": apiServices.liability.getDetail,
    "Loan Review Date": apiServices.liability.getDetail,
    "Mortgage Review Date": apiServices.liability.getDetail,
    "Policy Review Date": apiServices.policy.getDetail,
  };
  const businessCategoryMap: {
    [key: number]: (itemId: string, customerId: string) => Promise<any>;
  } = {
    2: apiServices.assets.getDetail,
    6: apiServices.liability.getDetail,
    7: apiServices.liability.getDetail,
    1: apiServices.policy.getDetail,
  };

  const [selectedAssigneeValues, setSelectedAssigneeValues] = useState<
    string[]
  >([]);
  // consultant list
  const getConsultantOptions = async () => {
    setIsLoadingSkeleton(true);
    try {
      const response = await apiServices.actionList.getConsultant(
        loggedUser.userId
      );
      if (Array.isArray(response.data.data)) {
        const consultantData = response.data.data.map((consultant: string) => ({
          label: consultant,
          value: consultant,
        }));
        setConsultantOptions(consultantData);
      } else {
        notify("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      notify("Something went wrong", "error");
    } finally {
      setIsLoadingSkeleton(false); // Set loading to false after fetching is complete
    }
  };
  // Assignee list
  const getAssigneeOptions = async () => {
    setIsLoadingSkeleton(true);
    try {
      const response = await apiServices.actionList.getAssignee(
        loggedUser.userId
      );
      if (Array.isArray(response.data.data)) {
        const assigneeData = response.data.data.map((assignee: string) => ({
          label: assignee,
          value: assignee,
        }));
        setAssigneeOptions(assigneeData);
      } else {
        notify("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      notify("Something went wrong", "error");
    } finally {
      setIsLoadingSkeleton(false); // Set loading to false after fetching is complete
    }
  };
  useEffect(() => {
    if (loggedUser?.userId !== null) {
      getActionListData();
    }
  }, [loggedUser?.userId, pagination.pageIndex, pagination.pageSize, page]);

  useEffect(() => {
    if (
      actionListItems.length > 0 &&
      Object.keys(rowSelection)[0] !== undefined
    ) {
      let index = Object.keys(rowSelection)[0];
      let selectedRow = actionListItems[index];
      setActiveRowItem(selectedRow);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (Object.keys(activeRowItem).length !== 0) {
      dispatch(setActionListSelectedItem(activeRowItem));
    }
  }, [activeRowItem]);
  useEffect(() => {
    let newOption = selectedDropDownValues.join("&");
    setOption(newOption);
  }, [selectedDropDownValues]);
  function dateSelection(item: any) {
    setOptionDate(item);
  }
  function dateLabel(item: any) {
    setOptionDateValue(item);
  }

  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const getActionListData = async () => {
    if (selectedActionCategories.length > 0) {
      setLoading(true);
      try {
        const response = await apiServices.actionList.listItems(
          loggedUser.userId,
          optionDate,
          selectedActionCategories,
          selectedConsultantValues,
          selectedAssigneeValues,
          page,
          search
        );
        if (response.data && response.data.data && response.data.data) {
          await setRowCount(
            response.data && response.data.data ? response.data.data.count : 0
          );

          if (response.data.data.length > 0) {
            setnextPage(response.data.nextPage);
            const resultData = response.data.data.map((object: any) => {
              return {
                date: object.action_date || "",
                time: object.time || "",
                case_type: object.case_type || "",
                case_category: object.category || "",
                category: object.category || "",
                assigned: object.assigned || "",
                description: object.description || "",
                customer_name: object.customer_name || "",
                home_phone: object.home_phone || "",
                work_phone: object.work_phone || "",
                consultant: object.consultant || "",
                case_id: object.case_id || "",
                customer_id: object.customer_id || "",
                status: object.status || "",
                business_category: object.business_category || "",
                business_id: object.business_id || "",
                type: object.type || "",
              };
            });

            // const resultData = response.data.data.map((object: any) => {
            //   let newItem;
            //   if (object.category) {
            //     if (
            //       [
            //         "asset_bank",
            //         "asset_home",
            //         "asset_investment",
            //         "asset_share",
            //       ].includes(object.category)
            //     ) {
            //       newItem = {
            //         ...object,
            //         date: object.review_date,
            //         time: "",
            //         type: object.case_type,
            //         case_category: "asset",
            //         category: object.category,
            //         assigned: object.review_assigned,
            //         description: object.review_reason,
            //         itemId: object.asset_id,
            //       };
            //     } else if (object.category === "case_tracking_action") {
            //       newItem = {
            //         ...object,
            //         date: object.action_date,
            //         time: "",
            //         type: "Case Action",
            //         case_category: "case_tracking_action",
            //         assigned: object.action_assigned,
            //         description: object.action_note,
            //         itemId: object.case_id,
            //         category: "Case Tracking Action",
            //       };
            //     } else if (object.category === "policy_review") {
            //       newItem = {
            //         ...object,
            //         date: object.review_date,
            //         time: "",
            //         type: "Case Action",
            //         case_category: "policy_review",
            //         category: "Policy Review",
            //         assigned: object.review_assigned,
            //         description: object.review_reason,
            //         itemId: object.policy_id,
            //       };
            //     } else if (object.category === "client_action") {
            //       newItem = {
            //         ...object,
            //         date: object.appointment_date,
            //         time: object.appointment_time,
            //         type: object.appointment_type,
            //         assigned: object.appointment_assigned,
            //         description: object.appointment_note,
            //         consultant: object.consultant,
            //         itemId: object.appointment_id,
            //         category: "Client Action",
            //       };
            //     } else if (object.category === "birthday") {
            //       newItem = {
            //         ...object,
            //         date: object.CurrentYearDateOfBirth,
            //         time: "",
            //         type: "",
            //         assigned: "",
            //         description: `${object.last_name},${object.first_names}(Client)`,
            //         consultant: "",
            //         itemId: object.customer_id,
            //         category: "Birthday",
            //       };
            //     } else if (object.category === "dependant_birthday") {
            //       newItem = {
            //         ...object,
            //         date: object.dependant_date_of_birth,
            //         time: "",
            //         type: "",
            //         assigned: "",
            //         description: `${object.dependant_last_name},${object.dependant_first_names}(Depentant)`,
            //         consultant: "",
            //         itemId: object.customer_id,
            //         category: "Birthday",
            //       };
            //     } else if (object.category === "client_review") {
            //       newItem = {
            //         ...object,
            //         date: object.review_date,
            //         time: "",
            //         type: "",
            //         assigned: object.review_assigned,
            //         description: object.review_reason,
            //         category: "Client Review",
            //         consultant: object.consultant,
            //         itemId: object.customer_id,
            //       };
            //     } else if (
            //       object.category === "loan_review_date" ||
            //       object.category === "mortgage_review_date"
            //     ) {
            //       newItem = {
            //         ...object,
            //         date: object.review_date,
            //         time: object.review_completed,
            //         type: object.case_type,
            //         assigned: object.review_assigned,
            //         description: object.review_reason,
            //         consultant: object.consultant,
            //         itemId: object.liability_id,
            //         case_category: "liability",
            //       };
            //     }
            //   } else {
            //     if (object.case_type) {
            //       if (
            //         [
            //           "Unit-linked",
            //           "Shares",
            //           "Home/Personal",
            //           "Banks/B Societies",
            //         ].includes(object.case_type)
            //       ) {
            //         newItem = {
            //           ...object,
            //           date: object.review_date,
            //           time: object.review_completed,
            //           type: object.case_type,
            //           assigned: object.review_assigned,
            //           description: object.review_reason,
            //           itemId: object.asset_id,
            //           category:
            //             object.case_type === "Unit-linked"
            //               ? "Asset (Investment) Review"
            //               : object.case_type === "Shares"
            //               ? "Asset (Shares)Review"
            //               : object.case_type === "Home/Personal"
            //               ? "Asset (Home/Personal)Review"
            //               : object.case_type === "Banks/B Societies"
            //               ? "Asset (Bank) Review"
            //               : "",
            //         };
            //       }
            //       if (
            //         ["Mortgages", "Loans/Lease/HP", "Credit Cards"].includes(
            //           object.case_type
            //         )
            //       ) {
            //         newItem = {
            //           ...object,
            //           date: object.review_date,
            //           time: object.review_completed,
            //           type: object.case_type,
            //           assigned: object.review_assigned,
            //           description: object.review_reason,
            //           itemId: object.liability_id,
            //           category:
            //             object.case_type === "Mortgages"
            //               ? "Mortgages Review Date"
            //               : object.case_type === "Loans/Lease/HP"
            //               ? "Loan Review Date"
            //               : object.case_type === "Credit Cards"
            //               ? "Credit Cards Review Date"
            //               : "",
            //         };
            //       }
            //     }
            //   }

            //   return newItem;
            // });
            setLoading(false);
            setActionListItems(resultData);
          } else {
            setActionListItems("");
          }
          setLoading(false);
        } else {
          notify(response.data.error, "error");
          setLoading(false);
          return;
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const renderClientType = (type: number) => (type ? getClientType(type) : "-");
  const renderDate = (date: string) => (date ? formatedDate(date) : "-");
  const renderRichText = (text: string) =>
    text ? <ReactQuillComponent value={text} /> : "-";
  const renderYesorNo = (value: number) =>
    value ? getYes_NoConversion(value) : "-";
  const Details = (activeRowItemDetail: any) => {
    if (
      activeRowItem.category === "Policy Review" ||
      (activeRowItem.business_category === 1 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      if (activeRowItem.case_type === "Life Assurance") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              lifeAssurance.length > 0 &&
              lifeAssurance.map((policyLA: any) => (
                <div key={policyLA.lifeAssurance_id} className="details">
                  <p className="details_label">{policyLA.label} </p>
                  <p>
                    {(() => {
                      switch (policyLA.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "mortgage_link":
                          return renderYesorNo(
                            activeRowItemDetail.mortgage_link
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policyLA.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Pensions") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              pensions.length > 0 &&
              pensions.map((policypensions: any) => (
                <div key={policypensions.lifeAssurance_id} className="details">
                  <p className="details_label">{policypensions.label} </p>
                  <p>
                    {(() => {
                      switch (policypensions.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return (
                            activeRowItemDetail[policypensions.value] || "-"
                          );
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Savings Plans") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              savingsPlans.length > 0 &&
              savingsPlans.map((policySP: any) => (
                <div key={policySP.lifeAssurance_id} className="details">
                  <p className="details_label">{policySP.label} </p>
                  <p>
                    {(() => {
                      switch (policySP.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policySP.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Income Protection") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              incomeProtection.length > 0 &&
              incomeProtection.map((policyIP: any) => (
                <div key={policyIP.lifeAssurance_id} className="details">
                  <p className="details_label">{policyIP.label} </p>
                  <p>
                    {(() => {
                      switch (policyIP.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policyIP.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Health Assurance") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              healthAssurance.length > 0 &&
              healthAssurance.map((policyHA: any) => (
                <div key={policyHA.healthAssurance_id} className="details">
                  <p className="details_label">{policyHA.label} </p>
                  <p>
                    {(() => {
                      switch (policyHA.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return activeRowItemDetail[policyHA.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "General") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              General.length > 0 &&
              General.map((policyGeneral: any) => (
                <div key={policyGeneral.lifeAssurance_id} className="details">
                  <p className="details_label">{policyGeneral.label} :</p>
                  <p>
                    {(() => {
                      switch (policyGeneral.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return (
                            activeRowItemDetail[policyGeneral.value] || "-"
                          );
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeRowItem.case_type === "Investments") {
        return (
          <div>
            {Object.keys(activeRowItemDetail).length !== 0 &&
              Investments.length > 0 &&
              Investments.map((policyIInvestments: any) => (
                <div
                  key={policyIInvestments.Investments_id}
                  className="details"
                >
                  <p className="details_label">{policyIInvestments.label} </p>
                  <p>
                    {(() => {
                      switch (policyIInvestments.value) {
                        case "policy_holder":
                          return renderClientType(
                            activeRowItemDetail.policy_holder
                          );
                        case "start_date":
                          return renderDate(activeRowItemDetail.start_date);
                        case "end_date":
                          return renderDate(activeRowItemDetail.end_date);
                        case "status_date":
                          return renderDate(activeRowItemDetail.status_date);
                        case "valuation_date":
                          return renderDate(activeRowItemDetail.valuation_date);
                        case "provider_note":
                          return renderRichText(
                            activeRowItemDetail.provider_note
                          );
                        case "benefit_text":
                          return renderRichText(
                            activeRowItemDetail.benefit_text
                          );
                        case "in_trust_note":
                          return renderRichText(
                            activeRowItemDetail.in_trust_note
                          );
                        case "admin_note":
                          return renderRichText(activeRowItemDetail.admin_note);
                        case "report_note":
                          return renderRichText(
                            activeRowItemDetail.report_note
                          );
                        default:
                          return (
                            activeRowItemDetail[policyIInvestments.value] || "-"
                          );
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      }
    } else if (
      activeRowItem.case_type === "Unit-linked" ||
      (activeRowItem.business_category === 2 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            unitLinked.length > 0 &&
            unitLinked.map((investmentdata: any) => (
              <div key={investmentdata.lifeAssurance_id} className="details">
                <p className="details_label">{investmentdata.label} :</p>
                <p>
                  {(() => {
                    switch (investmentdata.value) {
                      case "policy_holder":
                        return renderClientType(
                          activeRowItemDetail.policy_holder
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "admin_note":
                        return renderRichText(activeRowItemDetail.admin_note);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[investmentdata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Shares") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            Shares.length > 0 &&
            Shares.map((Sharedata: any) => (
              <div key={Sharedata.Shares_id} className="details">
                <p className="details_label">{Sharedata.label} </p>
                <p>
                  {(() => {
                    switch (Sharedata.value) {
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "value_date":
                        return renderDate(activeRowItemDetail.value_date);
                      case "share_date_acquired":
                        return renderDate(
                          activeRowItemDetail.share_date_acquired
                        );
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "withdrawal_note":
                        return renderRichText(
                          activeRowItemDetail.withdrawal_note
                        );
                      case "admin_note":
                        return renderRichText(activeRowItemDetail.admin_note);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[Sharedata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Banks/B Societies") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            banksBSocieties.length > 0 &&
            banksBSocieties.map((Banksdata: any) => (
              <div key={Banksdata.lifeAssurance_id} className="details">
                <p className="details_label">{Banksdata.label} </p>
                <p>
                  {(() => {
                    switch (Banksdata.value) {
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "value_date":
                        return renderDate(activeRowItemDetail.value_date);
                      case "bank_date_opened":
                        return renderDate(activeRowItemDetail.bank_date_opened);
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "withdrawal_note":
                        return renderRichText(
                          activeRowItemDetail.withdrawal_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[Banksdata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Home/Personal") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            homePersonal.length > 0 &&
            homePersonal.map((homePersonaldata: any) => (
              <div key={homePersonaldata.Home_Personal_id} className="details">
                <p className="details_label">{homePersonaldata.label} </p>
                <p>
                  {(() => {
                    switch (homePersonaldata.value) {
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "value_date":
                        return renderDate(activeRowItemDetail.value_date);
                      case "home_date_acquired":
                        return renderDate(
                          activeRowItemDetail.home_date_acquired
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return (
                          activeRowItemDetail[homePersonaldata.value] || "-"
                        );
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (
      activeRowItem.case_type === "Mortgages" ||
      (activeRowItem.business_category === 6 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            Mortages.length > 0 &&
            Mortages.map((Mortagesdata: any) => (
              <div key={Mortagesdata.Mortages_id} className="details">
                <p className="details_label">{Mortagesdata.label} </p>
                <p>
                  {(() => {
                    switch (Mortagesdata.value) {
                      case "joint_indicator":
                        return renderClientType(
                          activeRowItemDetail.joint_indicator
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "amount_outstanding_date":
                        return renderDate(
                          activeRowItemDetail.amount_outstanding_date
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[Mortagesdata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (
      activeRowItem.case_type === "Loans/Lease/HP" ||
      (activeRowItem.business_category === 7 &&
        activeRowItem.category === "Case Tracking Action")
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            loansLeaseHP.length > 0 &&
            loansLeaseHP.map((loandata: any) => (
              <div key={loandata.Loans_Lease_HP_id} className="details">
                <p className="details_label">{loandata.label} </p>
                <p>
                  {(() => {
                    switch (loandata.value) {
                      case "joint_indicator":
                        return renderClientType(
                          activeRowItemDetail.joint_indicator
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "amount_outstanding_date":
                        return renderDate(
                          activeRowItemDetail.amount_outstanding_date
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[loandata.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeRowItem.case_type === "Credit Cards") {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            creditCards.length > 0 &&
            creditCards.map((creditCard: any) => (
              <div key={creditCard.lifeAssurance_id} className="details">
                <p className="details_label">{creditCard.label} </p>
                <p>
                  {(() => {
                    switch (creditCard.value) {
                      case "joint_indicator":
                      case "joint_indicator":
                        return renderClientType(
                          activeRowItemDetail.joint_indicator
                        );
                      case "start_date":
                        return renderDate(activeRowItemDetail.start_date);
                      case "end_date":
                        return renderDate(activeRowItemDetail.end_date);
                      case "amount_outstanding_date":
                        return renderDate(
                          activeRowItemDetail.amount_outstanding_date
                        );
                      case "status_date":
                        return renderDate(activeRowItemDetail.status_date);
                      case "provider_note":
                        return renderRichText(
                          activeRowItemDetail.provider_note
                        );
                      case "note_text":
                        return renderRichText(activeRowItemDetail.note_text);
                      case "report_note":
                        return renderRichText(activeRowItemDetail.report_note);
                      default:
                        return activeRowItemDetail[creditCard.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (
      activeRowItem.category === "Client Review" ||
      activeRowItem.category === "Client Action" ||
      activeRowItem.category === "Birthday"
    ) {
      return (
        <div>
          {Object.keys(activeRowItemDetail).length !== 0 &&
            clientDetails.length > 0 &&
            clientDetails.map((clientDetail: any) => (
              <div key={clientDetail.client_id} className="details">
                <p className="details_label">{clientDetail.label} </p>
                <p>
                  {(() => {
                    switch (clientDetail.value) {
                      case "date_of_birth":
                        return activeRowItemDetail?.date_of_birth
                          ? renderDate(activeRowItemDetail.date_of_birth)
                          : "";
                      case "review_note":
                        return renderRichText(activeRowItem.description);
                      default:
                        if (
                          activeRowItemDetail &&
                          activeRowItemDetail.NameAndAddress
                        ) {
                          return (
                            activeRowItemDetail?.NameAndAddress[
                              clientDetail.value
                            ] ||
                            activeRowItemDetail?.Contact[clientDetail.value] ||
                            activeRowItemDetail?.Nationality[
                              clientDetail.value
                            ] ||
                            activeRowItemDetail?.Personal[clientDetail.value] ||
                            "-"
                          );
                        } else {
                          return "-";
                        }
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {loading ? (
        <TableListLoader rows={10} columns={6} />
      ) : (
        <div className="page-content-area">
          <Toaster
            toasterId={toasterId}
            position="top-end"
            pauseOnHover
            pauseOnWindowBlur
            timeout={1000}
          />

          <div className="action-list-wrapper">
            <MaterialReactTable
              renderTopToolbarCustomActions={({ table }) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="acionlist_filter">
                    <Search
                      placeholder="Search"
                      setSearch={setSearch}
                      search={search}
                      isLoading={loading}
                    />
                    <Multiselectsearch
                      setSelectedDropDownValues={setSelectedActionCategories}
                      selectedDropDownValues={selectedActionCategories}
                      placeholder="Choose by Action Category"
                      options={actionCategories}
                    />
                    <DropdownDatepicker
                      dateSelection={dateSelection}
                      dateLabel={dateLabel}
                      optionDateValue={optionDateValue}
                      optionDate={optionDate}
                    />
                    <Multiselectsearch
                      setSelectedDropDownValues={setSelectedConsultantValues}
                      selectedDropDownValues={selectedConsultantValues}
                      placeholder="Consultants"
                      options={consultantOptions}
                      onOpenChange={(e, data) => {
                        if (data.open) {
                          getConsultantOptions();
                        }
                      }}
                    />
                    <Multiselectsearch
                      setSelectedDropDownValues={setSelectedAssigneeValues}
                      selectedDropDownValues={selectedAssigneeValues}
                      placeholder="Assigned"
                      options={assigneeOptions}
                      onOpenChange={(e, data) => {
                        if (data.open) {
                          getAssigneeOptions();
                        }
                      }}
                    />

                    <ASCButton
                      shape="rounded"
                      className="asc-button-primary"
                      appearance="primary"
                      onClick={() => {
                        setPage(1);
                        getActionListData();
                      }}
                      disabled={selectedActionCategories.length === 0}
                      label="Apply filter"
                    />
                  </div>
                  {convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("action_list_preference") && (
                    <ASCButton
                      shape="rounded"
                      className="asc-button-secondary"
                      appearance="secondary"
                      onClick={handleSavePreference}
                      label="Save Preference"
                    />
                  )}
                </div>
              )}
              columns={customColumns}
              data={actionListItems}
              enableRowSelection={config.enableRowSelection}
              enableColumnActions={config.enableColumnActions}
              enableColumnOrdering={config.enableColumnOrdering}
              enableColumnDragging={config.enableColumnDragging}
              onColumnOrderChange={handleColumnOrderChange}
              // enableSorting={config.enableSorting}
              enablePagination={config.enablePagination}
              enableMultiRowSelection={false}
              enableColumnFilters={config.enableColumnFilters}
              enableGlobalFilter={config.enableGlobalFilter}
              enableDensityToggle={config.enableDensityToggle}
              // manualSorting={config.manualSorting}
              manualFiltering={config.manualFiltering}
              initialState={config.initialState}
              muiTableProps={config.muiTableProps}
              muiTableHeadCellProps={config.muiTableHeadCellProps}
              onRowSelectionChange={setRowSelection}
              state={{
                rowSelection: rowSelection,
                pagination: { ...pagination },
                columnOrder,
              }}
              muiTableBodyRowProps={({ row }: any) => ({
                ...config.muiTableBodyRowProps,
                onDoubleClick: () => {
                  if (row.original?.category != "Birthday") {
                    setRowSelection({ [row.index]: true });
                    setActiveRowItem(row.original);
                    getItemDetail(row.original);

                    setUpdateReminder(true);
                  }
                },
                sx: { cursor: "pointer" },
              })}
              positionToolbarAlertBanner="bottom"
              manualPagination
              onPaginationChange={setPagination}
              rowCount={rowCount}
            />
          </div>

          {actionListItems.length > 0 && (
            <Paginate
              nextPage={nextPage}
              page={page}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
            />
          )}

          <ModalDialog
            options={dialogModalOptions}
            getActionListData={getActionListData}
          />
          <OverlayDrawer
            position="end"
            open={appSettings?.actionListDetailPopup}
            onOpenChange={(_, { open }) =>
              dispatch(openActionListDetailPopup(open))
            }
            style={{ width: "450px", marginTop: "130px" }}
          >
            <DrawerHeader>
              <DrawerHeaderTitle
                action={
                  <ASCButton
                    shape="rounded"
                    appearance="subtle"
                    aria-label="Close"
                    icon={<Dismiss24Regular />}
                    onClick={() => dispatch(openActionListDetailPopup(false))}
                  />
                }
              >
                {activeRowItem.category}
              </DrawerHeaderTitle>
            </DrawerHeader>
            <Divider className={styles.divider} />
            <DrawerBody>
              {isDetailLoading ? (
                <ContentLoader />
              ) : (
                Details(activeRowItemDetail)
              )}
            </DrawerBody>
          </OverlayDrawer>
          <Dialog open={updateReminder} modalType="modal">
            <DialogSurface>
              <DialogBody>
                <DialogContent>
                  <ActionListForm
                    data={activeUpdateRowItemDetail}
                    cancelPopup={() => {
                      setUpdateReminder(false);
                    }}
                    getActionListData={getActionListData}
                    isDetailDataLoading={isDetailDataLoading}
                  />
                </DialogContent>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default List;
