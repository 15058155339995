import { Button, Dropdown, Field, Option } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useState, useEffect } from "react";
import "./style.css";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import moment from "moment";
import ASCButton from "../../components/Button";

interface PropsType {
  [x: string]: any;
}

const ActionListDateFilter = (props: PropsType) => {
  const options = [
    { label: "Define Dates", value: "defineDates" },
    { label: "Include All Dates", value: "allDates" },
    { label: "Past Dates", value: "pastDate" },
    {
      label: "Today Plus Overdue",
      value: "todayDateOverdue",
    },
    {
      label: "Next 7 Days Plus Overdue",
      value: "next7DaysOverdue",
    },
    {
      label: "Next 31 Days Plus Overdue",
      value: "next31DaysOverdue",
    },
    {
      label: "This Year Plus Overdue",
      value: "thisYearOverdue",
    },
  ];
  const [context, setContext] = useState<string>("");
  const [contextLabel, setContextLabel] = useState<string>(
    props.optionDateValue
  );
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [optionDate, setOptionDate] = useState("");

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    let start = new Date(startDate);
    let end = new Date(endDate);
    let newOption = `defineDates&start_date=${
      startDate !== "" ? moment(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : ""
    }&end_date=${
      endDate !== "" ? moment(end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : ""
    }`;
    setOptionDate(newOption);
  }, [startDate, endDate]);

  function onDropDownList(e: any, data: any) {
    setContext(data.optionValue);
    setContextLabel(data.optionText);
    if (data.optionValue !== "defineDates") {
      setOptionDate(data.optionValue);
    }
  }

  // const onFormatDate = (date?: Date): string => {
  //   return `${date?.getMonth()}/${date?.getDate()}/${date?.getFullYear()}`;
  // };
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };

  return (
    <>
      <div className="date-filter-body">
        <Dropdown onOptionSelect={onDropDownList} value={contextLabel}>
          {options.map((option, index) => (
            <Option value={option.value} key={index}>
              {option.label}
            </Option>
          ))}
        </Dropdown>

        <div>
          <label style={{ color: "gray" }}>Choose a date range</label>
          <div style={{ display: "flex", gap: "10px" }}>
            <Field label="Start Date" style={{ width: "100%" }}>
              <DatePicker
                placeholder="mm/dd/yyyy"
                formatDate={onFormatDate}
                allowTextInput
                disabled={context !== "defineDates"}
                value={startDate}
                onSelectDate={(date) => {
                  if (date) {
                    setStartDate(date);
                  }
                }}
              />
            </Field>
            <Field label="End Date" style={{ width: "100%" }}>
              <DatePicker
                placeholder="mm/dd/yyyy"
                formatDate={onFormatDate}
                allowTextInput
                disabled={context !== "defineDates"}
                value={endDate}
                onSelectDate={(date) => {
                  if (date) {
                    setEndDate(date);
                  }
                }}
              />
            </Field>
          </div>
        </div>
      </div>
      <div className="flex_end">
        <ASCButton
          shape="rounded"
          className="asc-button-primary"
          appearance="primary"
          onClick={() => {
            props.dateSelection(optionDate);
            props.dateLabel(contextLabel);
            dispatch(setDialogModalOptions({ open: false }));
          }}
          label="Select"
        />
      </div>
    </>
  );
};

export default ActionListDateFilter;
