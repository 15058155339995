import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import apiServices from "../../../service";
import { title } from "process";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedClient } from "../../../redux/Commission/commissionSlice";
import ASCButton from "../../../components/Button";

interface Item {
  customer_id: string;
  client_name: string;
  first_names: string;
  last_name: string;
  title: string;
}
const AssignClient = (props: any) => {
  const [clients, setClients] = useState<Item[]>([]);
  const dispatch: AppDispatch = useDispatch();

  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  // const [selectedClient, setSelectedClient] = useState<any>(null);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [loading, setLoading] = useState<boolean>(false);
  // const handleCellChange = (
  //   rowIndex: number,
  //   columnKey: keyof Item,
  //   value: string
  // ) => {
  //   const updatedItems = [...items];
  //   updatedItems[rowIndex] = { ...updatedItems[rowIndex], [columnKey]: value };
  //   setItems(updatedItems);
  // };
  const selectedClient: any = useSelector(
    (state: RootState) => state.commission.selectedClient
  );

  const handleAssignClient = () => {
    const selectedClientId = Object.keys(rowSelection)[0]; // Get selected row ID
    if (selectedClientId) {
      const client = clients.find((c) => c.customer_id === selectedClientId);
      dispatch(setSelectedClient(client || null));
    }
  };

  const columns = useMemo<MRT_ColumnDef<Item>[]>(
    () => [
      {
        accessorKey: "client_name",
        header: "Client",
      },
      {
        accessorKey: "first_names",
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "title",
        header: "Titile",
      },
    ],
    []
  );

  useEffect(() => {
    getClientList();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  async function getClientList() {
    setLoading(true);
    try {
      const resp = await apiServices.client.getClients(
        pagination.pageIndex + 1,
        pagination.pageSize,
        searchText
      );
      // const response = await apiServices.notification.clientList(
      //   pagination.pageIndex + 1,
      //   pagination.pageSize,
      //   searchText
      // );
      if (
        resp &&
        resp.data &&
        resp.data.data &&
        resp.data.data.list &&
        resp.data.data.list.length > 0
      ) {
        const transformedData = resp.data.data.list.map((client: any) => ({
          customer_id: client.customer_id,
          client_name: `${client?.NameAndAddress?.first_names || ""} ${
            client?.NameAndAddress?.last_name || ""
          }`.trim(),
          first_names: client?.NameAndAddress?.first_names || "",
          last_name: client?.NameAndAddress?.last_name || "",
          title: client?.NameAndAddress?.title || "",
        }));

        setClients(transformedData);
        setRowCount(
          resp.data.data && resp.data.data.count && resp.data.data.count
            ? resp.data.data.count
            : 0
        );
        setLoading(false);
      } else {
        setLoading(false);
        setClients([]);
        setRowCount(0);
      }

      // if (response && response.data) {

      //   setClients(response.data.data ? response.data.data.res : []);
      //   setRowCount(response.data.data ? response.data.data.count : 0);
      // }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }
  return (
    <Dialog open={props.isclient}>
      <div>
        {/* <DialogTrigger disableButtonEnhancement>
          <Button>Commission Rule</Button>
        </DialogTrigger> */}
        <DialogSurface
          style={{
            height: "400px",
            width: "500px",
            maxHeight: "90vh",
            maxWidth: "90vw",
            overflow: "auto", // Ensure scrolling if content exceeds height/width
          }}
        >
          <DialogBody>
            <DialogTitle>Assign Client</DialogTitle>
            <DialogContent>
              <MaterialReactTable
                columns={columns}
                data={clients}
                onRowSelectionChange={setRowSelection}
                onPaginationChange={setPagination}
                rowCount={rowCount}
                manualPagination
                state={{ pagination, rowSelection }}
                enableGlobalFilter={true}
                enableColumnActions={false}
                enableColumnFilters={true}
                enableRowSelection
                enableFullScreenToggle={false}
                getRowId={(row) => row.customer_id}
                enableMultiRowSelection={false}
                muiTablePaginationProps={{
                  showFirstButton: false,
                  showLastButton: false,
                }}
                onGlobalFilterChange={(text: string) => {
                  if (text) {
                    setSearchText(text);
                  } else {
                    setSearchText("");
                  }
                }}
                positionToolbarAlertBanner="none"
              />
            </DialogContent>
            <DialogActions>
              <Button
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  setRowSelection({});
                  dispatch(setSelectedClient(null));
                }}
              >
                Clear
              </Button>
              <Button
                appearance="primary"
                className="asc-button-primary"
                onClick={() => {
                  props.setisClient(false);
                  handleAssignClient();
                }}
              >
                Assign
              </Button>

              <Button
                appearance="secondary"
                onClick={() => {
                  props.setisClient(false);
                }}
              ></Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </div>
    </Dialog>
  );
};

export default AssignClient;
