import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../service";

const initialState: any = {
  listItems: [],
  funds: {
    listItems: [],
    activeItem: {},
    totalFund: {},
    loading: false,
    activeItemloading: false,
  },
  payments: {
    listItems: [],
    activeItem: {},
    totalPayment: {},
    loading: false,
    activeItemloading: false,
    commissions: {
      listItems: [],
      activeItem: {},
      loading: false,
      activeItemloading: false,
      distribution: {
        listItems: [],
        activeItem: {},
        loading: false,
        activeItemloading: false,
      },
    },
  },
  valuations: {
    listItems: [],
    activeItem: {},
    loading: false,
    activeItemloading: false,
  },
  actions: {
    listItems: [],
    activeItem: {},
    loading: false,
    activeItemloading: false,
  },
  withdrawals: {
    listItems: [],
    activeItem: {},
    totalWithdrawals: {},
    loading: false,
    activeItemloading: false,
  },
  activeItem: {},
  loading: false,
  activeItemloading: false,
};

export const getPolicyListItems = createAsyncThunk(
  "policies/getPolicyListItems",
  async (id: string, { rejectWithValue }) => {
    if (!id) return rejectWithValue("Invalid customer ID");
    try {
      const response: any = await apiServices.policy.getPolicies(id);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch policies list");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyDetailById = createAsyncThunk(
  "policies/getPolicyDetailById",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.policy.getDetail(id, customerId);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyValuationsListItems = createAsyncThunk(
  "policies/getPolicyValuationsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.valuation.getvaluation(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyValuationDetailById = createAsyncThunk(
  "policies/getPolicyValuationDetailById",
  async (valuationId: string, { getState, rejectWithValue }) => {
    if (!valuationId) return rejectWithValue("Invalid valuation ID");

    // Access the current state
    const state: any = getState();

    const valuationsList = state.policies.valuations.listItems;

    // Find the valuation detail by ID in the listItems array
    const valuationDetail = valuationsList.find(
      (item: any) => item.valuation_id === valuationId
    );

    if (valuationDetail) {
      return valuationDetail; // Return the detail if found
    } else {
      return rejectWithValue("Valuation detail not found in state");
    }
  }
);
export const getPolicyActionsListItems = createAsyncThunk(
  "policies/getPolicyActionsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Actions.getActions(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyActionDetailById = createAsyncThunk(
  "policies/getPolicyActionDetailById",
  async (actionId: string, { getState, rejectWithValue }) => {
    if (!actionId) return rejectWithValue("Invalid actionId ID");

    // Access the current state
    const state: any = getState();

    const actionsList = state.policies.actions.listItems;

    // Find the valuation detail by ID in the listItems array
    const actionDetail = actionsList.find(
      (item: any) => item.business_id === actionId
    );

    if (actionDetail) {
      return actionDetail; // Return the detail if found
    } else {
      return rejectWithValue("action detail not found in state");
    }
  }
);
export const getPolicyWithdrawalsListItems = createAsyncThunk(
  "policies/getPolicyWithdrawalsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Withdrawals.getList(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyWithdrawalDetailById = createAsyncThunk(
  "policies/getPolicyWithdrawalDetailById",
  async (withdrawalId: string, { getState, rejectWithValue }) => {
    if (!withdrawalId) return rejectWithValue("Invalid withdrawalId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.policies.withdrawals.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.withdrawal_id === withdrawalId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("withdrawal detail not found in state");
    }
  }
);
export const getPolicyFundsListItems = createAsyncThunk(
  "policies/getPolicyFundsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.fund.getList(customerId, id);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyFundDetailById = createAsyncThunk(
  "policies/getPolicyFundDetailById",
  async (fundId: string, { getState, rejectWithValue }) => {
    if (!fundId) return rejectWithValue("Invalid fundId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.policies.funds.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find((item: any) => item.fund_id === fundId);

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("fund detail not found in state");
    }
  }
);
export const getPolicyPaymentsListItems = createAsyncThunk(
  "policies/getPolicyPaymentsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Payments.getPayments(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyPaymentDetailById = createAsyncThunk(
  "policies/getPolicyPaymentDetailById",
  async (paymentId: string, { getState, rejectWithValue }) => {
    if (!paymentId) return rejectWithValue("Invalid paymentId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.policies.payments.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.payment.payment_id === paymentId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);
export const getPolicyPaymentsCommissionsListItems = createAsyncThunk(
  "policies/getPolicyPaymentsCommissionsListItems",
  async (
    {
      paymentId,
      caseId,
      customerId,
    }: { paymentId: string; caseId: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!paymentId || !caseId || !customerId)
      return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.commission.getCommission(
        paymentId,
        customerId,
        caseId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyPaymentsCommissionDetailById = createAsyncThunk(
  "policies/getPolicyPaymentsCommissionDetailById",
  async (commissionId: string, { getState, rejectWithValue }) => {
    if (!commissionId) return rejectWithValue("Invalid paymentId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.policies.payments.commissions.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.commission_id === commissionId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);

export const getPolicyCommissionDistributionListItems = createAsyncThunk(
  "assets/getPolicyCommissionDistributionListItems",
  async ({ commissionId }: { commissionId: string }, { rejectWithValue }) => {
    if (!commissionId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.distribution.get(commissionId);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getPolicyCommissionDistributionDetailById = createAsyncThunk(
  "assets/getPolicyCommissionDistributionDetailById",
  async (distributionId: string, { getState, rejectWithValue }) => {
    if (!distributionId) return rejectWithValue("Invalid distributionID");

    // Access the current state
    const state: any = getState();

    const itemList = state.policies.payments.commission.distribution.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.commission_allocation_id === distributionId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);
export const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPolicyListItems.pending, (state) => {
        state.loading = true;
        state.listItems = [];
      })
      .addCase(getPolicyListItems.fulfilled, (state, action) => {
        state.loading = false;
        state.listItems = action.payload;
      })
      .addCase(getPolicyListItems.rejected, (state, action) => {
        state.loading = false;
        console.error("Error fetching asset list:", action.payload);
      })
      .addCase(getPolicyDetailById.pending, (state) => {
        state.activeItemloading = true;
        state.activeItem = {};
      })
      .addCase(getPolicyDetailById.fulfilled, (state, action) => {
        state.activeItemloading = false;
        state.activeItem = action.payload;
      })
      .addCase(getPolicyDetailById.rejected, (state, action) => {
        state.activeItemloading = false;
        console.error("Error fetching asset details:", action.payload);
      })
      .addCase(getPolicyValuationsListItems.pending, (state) => {
        state.valuations.loading = true;
        state.valuations.listItems = [];
      })
      .addCase(getPolicyValuationsListItems.fulfilled, (state, action) => {
        state.valuations.loading = false;
        state.valuations.listItems = action.payload;
      })
      .addCase(getPolicyValuationsListItems.rejected, (state, action) => {
        state.valuations.loading = false;
        console.error("Error fetching asset valuations:", action.payload);
      })
      .addCase(getPolicyValuationDetailById.pending, (state) => {
        state.valuations.activeItemloading = true;
        state.valuations.activeItem = {};
      })
      .addCase(getPolicyValuationDetailById.fulfilled, (state, action) => {
        state.valuations.activeItemloading = false;
        state.valuations.activeItem = action.payload;
      })
      .addCase(getPolicyValuationDetailById.rejected, (state, action) => {
        state.valuations.activeItemloading = false;
        console.error("Error fetching asset valuation detail:", action.payload);
      })
      .addCase(getPolicyActionsListItems.pending, (state) => {
        state.actions.loading = true;
        state.actions.listItems = [];
      })
      .addCase(getPolicyActionsListItems.fulfilled, (state, action) => {
        state.actions.loading = false;
        state.actions.listItems = action.payload;
      })
      .addCase(getPolicyActionsListItems.rejected, (state, action) => {
        state.actions.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getPolicyActionDetailById.pending, (state) => {
        state.actions.activeItemloading = true;
        state.actions.activeItem = {};
      })
      .addCase(getPolicyActionDetailById.fulfilled, (state, action) => {
        state.actions.activeItemloading = false;
        state.actions.activeItem = action.payload;
      })
      .addCase(getPolicyActionDetailById.rejected, (state, action) => {
        state.actions.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getPolicyWithdrawalsListItems.pending, (state) => {
        state.withdrawals.loading = true;
        state.withdrawals.listItems = [];
        state.withdrawals.totalWithdrawals = {};
      })
      .addCase(getPolicyWithdrawalsListItems.fulfilled, (state, action) => {
        state.actions.loading = false;
        state.withdrawals.listItems = action.payload?.result || [];
        state.withdrawals.totalWithdrawals = action.payload?.total;
      })
      .addCase(getPolicyWithdrawalsListItems.rejected, (state, action) => {
        state.withdrawals.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getPolicyWithdrawalDetailById.pending, (state) => {
        state.withdrawals.activeItemloading = true;
        state.withdrawals.activeItem = {};
      })
      .addCase(getPolicyWithdrawalDetailById.fulfilled, (state, action) => {
        state.withdrawals.activeItemloading = false;
        state.withdrawals.activeItem = action.payload;
      })
      .addCase(getPolicyWithdrawalDetailById.rejected, (state, action) => {
        state.withdrawals.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getPolicyFundsListItems.pending, (state) => {
        state.funds.loading = true;
        state.funds.listItems = [];
        state.funds.totalFund = {};
      })
      .addCase(getPolicyFundsListItems.fulfilled, (state, action) => {
        state.funds.loading = false;
        state.funds.listItems = action.payload?.result || [];
        state.funds.totalFund = action.payload?.total_fund;
      })
      .addCase(getPolicyFundsListItems.rejected, (state, action) => {
        state.funds.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getPolicyFundDetailById.pending, (state) => {
        state.funds.activeItemloading = true;
        state.funds.activeItem = {};
      })
      .addCase(getPolicyFundDetailById.fulfilled, (state, action) => {
        state.funds.activeItemloading = false;
        state.funds.activeItem = action.payload;
      })
      .addCase(getPolicyFundDetailById.rejected, (state, action) => {
        state.funds.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getPolicyPaymentsListItems.pending, (state) => {
        state.payments.loading = true;
        state.payments.listItems = [];
        state.payments.totalPayment = {};
      })
      .addCase(getPolicyPaymentsListItems.fulfilled, (state, action) => {
        state.payments.loading = false;
        state.payments.listItems = action.payload?.result || [];
        state.payments.totalPayment = action.payload?.total;
      })
      .addCase(getPolicyPaymentsListItems.rejected, (state, action) => {
        state.payments.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getPolicyPaymentDetailById.pending, (state) => {
        state.payments.activeItemloading = true;
        state.payments.activeItem = {};
      })
      .addCase(getPolicyPaymentDetailById.fulfilled, (state, action) => {
        state.payments.activeItemloading = false;
        state.payments.activeItem = action.payload;
      })
      .addCase(getPolicyPaymentDetailById.rejected, (state, action) => {
        state.payments.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getPolicyPaymentsCommissionsListItems.pending, (state) => {
        state.payments.commissions.loading = true;
        state.payments.commissions.listItems = [];
      })
      .addCase(
        getPolicyPaymentsCommissionsListItems.fulfilled,
        (state, action) => {
          state.payments.commissions.loading = false;
          state.payments.commissions.listItems = action.payload;
        }
      )
      .addCase(
        getPolicyPaymentsCommissionsListItems.rejected,
        (state, action) => {
          state.payments.commissions.loading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      )
      .addCase(getPolicyPaymentsCommissionDetailById.pending, (state) => {
        state.payments.commissions.activeItemloading = true;
        state.payments.commissions.activeItem = {};
      })
      .addCase(
        getPolicyPaymentsCommissionDetailById.fulfilled,
        (state, action) => {
          state.payments.commissions.activeItemloading = false;
          state.payments.commissions.activeItem = action.payload;
        }
      )
      .addCase(
        getPolicyPaymentsCommissionDetailById.rejected,
        (state, action) => {
          state.payments.commissions.activeItemloading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      )

      .addCase(getPolicyCommissionDistributionListItems.pending, (state) => {
        state.payments.commissions.distribution.loading = true;
        state.payments.commissions.distribution.listItems = [];
      })
      .addCase(
        getPolicyCommissionDistributionListItems.fulfilled,
        (state, action) => {
          state.payments.commissions.distribution.loading = false;
          state.payments.commissions.distribution.listItems = action.payload;
        }
      )
      .addCase(
        getPolicyCommissionDistributionListItems.rejected,
        (state, action) => {
          state.payments.commissions.distribution.loading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      )
      .addCase(getPolicyCommissionDistributionDetailById.pending, (state) => {
        state.payments.commissions.distribution.activeItemloading = true;
        state.payments.commissions.distribution.activeItem = {};
      })
      .addCase(
        getPolicyCommissionDistributionDetailById.fulfilled,
        (state, action) => {
          state.payments.commissions.distribution.activeItemloading = false;
          state.payments.commissions.distribution.activeItem = action.payload;
        }
      )
      .addCase(
        getPolicyCommissionDistributionDetailById.rejected,
        (state, action) => {
          state.payments.commissions.distribution.activeItemloading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      );
  },
});

export const {} = policiesSlice.actions;
export default policiesSlice.reducer;
