import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  makeStyles,
  tokens,
  useToastController,
  useId,
  Toaster,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getCaseCategoryByCaseType } from "../../../../utils";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import CaseDetailDrawer from "../CaseDetailDrawer";
import {
  getPolicyDetailById,
  getPolicyPaymentDetailById,
  getPolicyPaymentsListItems,
} from "../../../../redux/Case/policySlice";
import { useToastMessage } from "../../../../components/ToastMessage/ToastMessage";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function PolicyPayments({ tab }: { tab: string }) {
  const navigate = useNavigate();
  const styles = useStyles();
  const { showToast, toasterId } = useToastMessage();
  const { clientId, action, caseId, subItemId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const policies = useSelector((state: RootState) => state.policies);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  const [caseType, setCaseType] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getPolicyDetailById({ id: caseId, customerId: clientId }));
      dispatch(
        getPolicyPaymentsListItems({ id: caseId, customerId: clientId })
      );
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (policies?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(policies.activeItem.case_type));
    }
  }, [policies?.activeItem]);

  useEffect(() => {
    if (action && action != "add" && policies?.payments.listItems.length > 0) {
      const paymentId =
        subItemId || policies.payments.listItems[0]?.payment.payment_id;
      navigate(
        `/clients/${clientId}/policies/${caseId}/payments/${paymentId}/${tab}/${
          action || "view"
        }`
      );
    }
  }, [
    clientId,
    caseId,
    subItemId,
    tab,
    action,
    policies.payments.listItems,
    navigate,
  ]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getPolicyPaymentDetailById(subItemId));
    }
  }, [subItemId, dispatch]);

  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <CaseNavList
        subItem="payments"
        section="policies"
        tab={tab}
        caseType={caseType}
        caseDetail={policies?.payments.activeItem}
        handleDetailPopup={handleDetailPopup}
        notify={showToast}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="policies"
          caseType={caseType}
          caseDetail={policies?.payments.activeItem}
          caseItemList={policies?.payments.listItems}
          tab={tab}
          subItem="payments"
          paymentValue={policies?.payments.totalPayment}
        />
        <div>
          {policies?.payments.listLoading ? (
            <ContentLoader />
          ) : (
            <>
              {policies?.payments.listItems.length > 0 && (
                <CaseList
                  module="payments"
                  data={policies?.payments.listItems}
                  activeItem={policies?.payments.activeItem}
                  section="policies"
                  activeTab={tab}
                />
              )}
              {!action && policies?.payments.listItems.length === 0 && (
                <div className={styles.emptyState}>
                  <Label size="large" style={{ color: "red" }}>
                    There are no active records - click Add New to create a new
                    record
                  </Label>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={policies?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default PolicyPayments;
