import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import {
  EyeOff24Filled,
  DocumentBulletList20Regular,
  Dismiss24Regular,
  Eye24Filled,
  Attach20Regular,
} from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import apiServices from "../../../service";
import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import {
  convertStringToArray,
  formatedDate,
  formatedTime,
  getClientNoteCategories,
  getClientType,
  getYes_NoConversion,
  isObjectEmpty,
} from "../../../utils";
import ContentLoader from "../../../components/Loader/ContentLoader";
import ModalDialog from "../../../components/ModalDialog";
import ASCButton from "../../../components/Button";

interface PropsType {
  [x: string]: any;
}

const ContactHistory = (props: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [noteData, setNoteData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [caseData, setCaseData] = useState([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [hide, setHide] = useState<Boolean>(false);
  const [contactHistoryItems, setContactHistoryItems] = useState<any>([]);
  const [contactHistoryData, setContactHistoryData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any | {}>({});
  const [clientDataById, setClientDataById] = useState<PropsType>([]);
  const [activeRowItemDetail, setActiveRowItemDetail] = useState<any | {}>({});
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const selectedRowId = Object.keys(rowSelection)[0];
    if (!selectedRowId) return;

    const selectedRowData = contactHistoryItems.find(
      (item: any) => item.rowId === selectedRowId
    );

    if (selectedRowData) {
      setSelectedItem(selectedRowData);
    }
  }, [rowSelection, contactHistoryItems]);
  useEffect(() => {
    const notes = contactHistoryItems.filter(
      (item: any) => item.type === "Notes"
    );
    const clients = contactHistoryItems.filter(
      (item: any) => item.type === "Client"
    );
    const cases = contactHistoryItems.filter(
      (item: any) => item.type === "Case"
    );

    setNoteData(notes);
    setClientData(clients);
    setCaseData(cases);
  }, [contactHistoryData]);

  useEffect(() => {
    if (props.clientId != undefined) {
      getActionListData(props.clientId);
    }
  }, []);

  function getCaseCategory(
    categoryNumber: number,
    rowItem: { id: number; customer_id: number }
  ) {
    switch (categoryNumber) {
      case 2:
      case 3:
      case 4:
      case 5:
        apiServices.assets
          .getDetail(rowItem.id, rowItem.customer_id)
          .then(handleSuccessResponse)
          .catch(handleErrorResponse);
        break;

      case 1:
        apiServices.policy
          .getDetail(rowItem.id, rowItem.customer_id)
          .then(handleSuccessResponse)
          .catch(handleErrorResponse);
        break;

      case 6:
      case 7:
      case 8:
        apiServices.liability
          .getDetail(rowItem.id, rowItem.customer_id)
          .then(handleSuccessResponse)
          .catch(handleErrorResponse);
        break;

      default:
        console.warn("Unknown category:", categoryNumber);
    }
  }
  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 100000)}`;
  };
  const getActionListData = (userId: any) => {
    setIsLoading(true);
    apiServices.clientHistory
      .getClientHistory(userId)
      .then((response) => {
        setIsLoading(false);
        if (response.data && response.data.data) {
          let resultData: any = [];
          Object.keys(response.data.data).map((key: any, index) => {
            for (const key in response.data.data[index]) {
              if (response.data.data[index].hasOwnProperty(key)) {
                if ("notes" === key) {
                  response.data.data[index][key].map((object: any) => {
                    resultData.push({
                      rowId: generateUniqueId(),
                      date: object.note_date,
                      customer_id: object.customer_id,
                      id: object.note_id,
                      type: "Notes",
                      time: object.note_time,
                      category: getClientNoteCategories(object.category),
                      contact_type: object.note_type,
                      note_text: object.note_text,
                      status: "",
                      file_link: object.file_link,
                      attachment_file: object.attachment_file,
                    });
                  });
                } else if ("client_action" === key) {
                  response.data.data[index][key].map((object: any) => {
                    resultData.push({
                      rowId: generateUniqueId(),
                      category: "Client Action",
                      customer_id: object.customer_id,
                      id: object.appointment_id,
                      type: "Client",
                      date: object.appointment_date,
                      time: object.appointment_time,
                      contact_type: object.appointment_type,
                      note_text: object.appointment_note,
                      status: object.appointment_status,
                      file_link: "",
                      attachment_file: "",
                      assigned: object.appointment_assigned,
                    });
                  });
                } else {
                  response.data.data[index][key].map((object: any) => {
                    resultData.push({
                      rowId: generateUniqueId(),
                      customer_id: object.customer_id,
                      id: object.case_id,
                      type: "case_action",
                      date: object.business_date,
                      time: "",
                      category: "Case Action",
                      contact_type: "",
                      note_text: object.business_action,
                      status: object.business_status,
                      business_category: object.business_category,
                      assigned: object.business_assigned,
                      case_type: object.case_type,
                      file_link: "",
                      attachment_file: "",
                    });
                  });
                }
              }
            }
          });
          setContactHistoryItems(resultData);
          setContactHistoryData(resultData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.date)}</>,
      },
      {
        accessorKey: "time",
        header: "Time",
        Cell: ({ row }: any) => <>{formatedTime(row.original.time)}</>,
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "contact_type",
        header: "Contact Type",
      },
      {
        accessorKey: "note_text",
        header: "Note Text",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.note_text} />
          </>
        ),
      },
      {
        accessorKey: "file_link",
        header: "File Link",
        Cell: ({ row }: any) => {
          return (
            <div>
              {(row.original.file_link && row.original.file_link != "") ||
              (row.original.attachment_file &&
                row.original.attachment_file !== undefined &&
                row.original.attachment_file !== null &&
                row.original.attachment_file !== "") ? (
                <span>
                  <Attach20Regular />
                </span>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "assigned",
        header: "Assigned",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );
  function onHideClick() {
    if (!hide) {
      setContactHistoryItems([...noteData]);
      setHide(true);
    } else {
      setContactHistoryItems([...noteData, ...clientData, ...caseData]);
      setHide(false);
    }
  }

  const handleErrorResponse = (err: any) => {
    console.log(err);
  };

  useEffect(() => {
    if (!isObjectEmpty(selectedItem)) {
      if (selectedItem.type === "case_action") {
        const categoryNum = Number(selectedItem.business_category);
        getCaseCategory(categoryNum, selectedItem);
      } else {
        getClientDetail(selectedItem.customer_id);
      }
    }
  }, [selectedItem]);

  const handleSuccessResponse = (response: any) => {
    if (
      response.data &&
      !response.data.isError &&
      !isObjectEmpty(response.data.data)
    ) {
      setActiveRowItemDetail(response.data.data);
    }
  };

  const getClientDetail = (id: string) => {
    apiServices.client
      .getDetail(id)
      .then((response: any) => {
        setClientDataById(response.data.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const Details = (selectedItem: any) => {
    if (selectedItem.type === "Notes" || selectedItem.type === "Client") {
      return (
        <div className="case_details">
          <b>Title :</b>
          <span>{clientDataById.NameAndAddress?.title || "-"}</span>
          <b>First Name(s) :</b>
          <span>{clientDataById.NameAndAddress?.first_names || "-"}</span>
          <b>Initials:</b>
          <span>{clientDataById.NameAndAddress?.initials || "-"}</span>
          <b>Last Name(s) :</b>
          <span>{clientDataById.NameAndAddress?.last_name || "-"}</span>
          <b>Former Last Name :</b>
          <span>{clientDataById.NameAndAddress?.former_last_name || "-"}</span>
          <b>Honours :</b>
          <span>{clientDataById.NameAndAddress?.honours || "-"}</span>
          <b>Known as :</b>
          <span>{clientDataById.NameAndAddress?.known_as || "-"}</span>
          <b>Street Address :</b>
          <p>
            {clientDataById.NameAndAddress?.address_1 || "-"}
            <br />
            {clientDataById.NameAndAddress?.address_2}
            <br />
            {clientDataById.NameAndAddress?.address_3}
          </p>
          <b>Town :</b>
          <span>{clientDataById.NameAndAddress?.town || "-"}</span>
          <b>County :</b>
          <span>{clientDataById.NameAndAddress?.county || "-"}</span>
          <b>Country :</b>
          <span>{clientDataById.NameAndAddress?.country || "-"}</span>
          <b>Postcode :</b>
          <span>{clientDataById.NameAndAddress?.postcode || "-"}</span>
          <b>Home :</b>
          <span>{clientDataById.Contact?.home_phone || "-"}</span>
          <b>Work :</b>
          <span>{clientDataById.Contact?.work_phone || "-"}</span>
          <b>Fax :</b>
          <span>{clientDataById.Contact?.fax || "-"}</span>
          <b>Mobile :</b>
          <span>{clientDataById.Contact?.mobile || "-"}</span>
          <b>Email :</b>
          <span>{clientDataById.Contact?.email || "-"}</span>
          <b>Email(Work) :</b>
          <span>{clientDataById.Contact?.work_email || "-"}</span>
          <b>Date of Birth :</b>
          <span>
            {formatedDate(clientDataById.Personal?.date_of_birth) || "-"}
          </span>
          <b>Age Next :</b>
          <span>
            {clientDataById.Personal?.preferred_retirement_age || "-"}
          </span>
          <b>NI Number :</b>
          <span>{clientDataById.Personal?.ni_number || "-"}</span>
          <b>Sex :</b>
          <span>{clientDataById.Personal?.sex || "-"}</span>
          <b>Client Type :</b>
          <span>{clientDataById.Administration?.client_type || "-"}</span>
          <b>Consultant :</b>
          <span>{clientDataById.Administration?.consultant || "-"}</span>
          <b>Administrator :</b>
          <span>{clientDataById.Administration?.administrator || "-"}</span>
          <b>Introduced by :</b>
          <span>{clientDataById.Administration?.introduced_by || "-"}</span>
          <b>Fact-find :</b>
          <span>
            {formatedDate(clientDataById.compliance?.fact_find_completed) ||
              "-"}
          </span>
          <b>Terms of Engagement :</b>
          <span>
            {formatedDate(clientDataById.compliance?.terms_of_business_sent) ||
              "-"}
          </span>
          <b>IDD :</b>
          <span>{formatedDate(clientDataById.compliance?.idd) || "-"}</span>
          <b>Menu :</b>
          <span>{formatedDate(clientDataById.compliance?.menu) || "-"}</span>
          <b>Data Protection :</b>
          <span>
            {formatedDate(
              clientDataById.compliance?.data_protection_form_sent
            ) || "-"}
          </span>
          <b>Review Interval(mths) :</b>
          <span>{clientDataById.Administration?.review_interval || "-"}</span>
          <b>Next Review Due :</b>
          <span>
            {formatedDate(clientDataById.Administration?.review_date) || "-"}
          </span>
          <b>Review Completed :</b>
          <span>
            {formatedDate(clientDataById.Administration?.review_completed) ||
              "-"}
          </span>
          <b>Next Review Note :</b>
          <ReactQuillComponent
            value={clientDataById.Administration?.review_note || "-"}
          />
          <b>Last Updated :</b>
          <span>{clientDataById.Administration?.last_updated || "-"}</span>
          <b>Updated by :</b>
          <span>{clientDataById.Administration?.updated_by || "-"}</span>
          <b>Note :</b>
          <ReactQuillComponent
            value={clientDataById.Administration?.note_text || "-"}
          />
        </div>
      );
    } else if (selectedItem.type === "case_action") {
      if (selectedItem.business_category === 2) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Investment Holder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Owner :</b>
            <span>{activeRowItemDetail.owner || "-"}</span>
            <b>Owner Name 1 :</b>
            <span>{activeRowItemDetail.owner_name1 || "-"}</span>
            <b>Owner Name 2 : </b>
            <span>{activeRowItemDetail.owner_name2 || "-"}</span>
            <b>Type : </b>{" "}
            <span>{activeRowItemDetail.investment_type || "-"}</span>
            <b>Subtype : </b>
            <span>{activeRowItemDetail.investment_subtype || "-"}</span>
            <b>Provider : </b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Account Number :</b>
            <span>{activeRowItemDetail.investment_account_number || "-"}</span>
            <b>Current Value: </b>
            <span>{activeRowItemDetail.current_value || "-"}</span>
            <b>Value Date :</b>
            <span>{formatedDate(activeRowItemDetail.value_date) || "-"}</span>
            <b>Purpose : </b> <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.investment_start_date) || "-"}
            </span>
            <b>End Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.investment_end_date) || "-"}
            </span>
            <b>Status : </b>
            <span>{activeRowItemDetail.investment_status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent value={activeRowItemDetail.note_text || "-"} />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 6) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Owner :</b>
            <span>
              {getClientType(activeRowItemDetail.joint_indicator) || "-"}
            </span>
            <b>Borrower :</b>
            <span>{activeRowItemDetail.borrower || "-"}</span>
            <b>Borrower Name 1 :</b>
            <span>{activeRowItemDetail.borrower_name1 || "-"}</span>
            <b>Borrower Name 2 : </b>
            <span>{activeRowItemDetail.borrower_name2 || "-"}</span>
            <b>Type : </b>{" "}
            <span>{activeRowItemDetail.liability_type || "-"}</span>
            <b>Subtype : </b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Repayment Method : </b>
            <span>{activeRowItemDetail.repayment_method || "-"}</span>
            <b>Purpose : </b> <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Provider : </b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Address :</b>
            <p>
              {activeRowItemDetail.provider_address1 || "-"}
              <br />
              {activeRowItemDetail.provider_address2}
              <br />
              {activeRowItemDetail.provider_address3}
            </p>
            <b>Town :</b>
            <span>{activeRowItemDetail.provider_town || "-"}</span>
            <b>County :</b>
            <span>{activeRowItemDetail.provider_county || "-"}</span>
            <b>Country :</b>
            <span>{activeRowItemDetail.provider_country || "-"}</span>
            <b>Postcode :</b>
            <span>{activeRowItemDetail.provider_postcode || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Account Number :</b>
            <span>{activeRowItemDetail.account_number || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term:</b> <span>{activeRowItemDetail.term || "-"}</span>
            <b>Amount Borrowed :</b>
            <span>{activeRowItemDetail.amount_borrowed || "-"}</span>
            <b>Amount Outstanding :</b>
            <span>{activeRowItemDetail.amount_outstanding || "-"}</span>
            <b>Outstanding Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.amount_outstanding_date) || "-"}
            </span>
            <b>Interest Rate :</b>
            <span>{activeRowItemDetail.interest_rate || "-"}</span>
            <b>Interest Basis:</b>
            <span>{activeRowItemDetail.interest_basis || "-"}</span>
            <b>End of Fixed Rate:</b>
            <span>
              {formatedDate(activeRowItemDetail.mortgage_fixed_rate_end) || "-"}
            </span>
            <b>Status :</b> <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Covered by Insurance? :</b>
            <span>{activeRowItemDetail.covered || "-"}</span>
            <b>Property Value :</b>
            <span>{activeRowItemDetail.property_value || "-"}</span>
            <b>Loan to Valuation % :</b>
            <span>{activeRowItemDetail.loan_valuation_percentage || "-"}</span>
            <b>Property Address :</b>
            <p>
              {activeRowItemDetail.property_address1 || "-"}
              <br />
              {activeRowItemDetail.property_address2}
              <br />
              {activeRowItemDetail.property_address3}
            </p>
            <b>Town :</b>
            <span>{activeRowItemDetail.property_town || "-"}</span>{" "}
            <b>County :</b>
            <span>{activeRowItemDetail.property_county || "-"}</span>{" "}
            <b>Country :</b>
            <span>{activeRowItemDetail.property_country || "-"}</span>
            <b>Postcode :</b>
            <span>{activeRowItemDetail.property_postcode || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent value={activeRowItemDetail.note_text || "-"} />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 7) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Owner :</b>
            <span>
              {getClientType(activeRowItemDetail.joint_indicator) || "-"}
            </span>
            <b>Borrower :</b>
            <span>{activeRowItemDetail.borrower || "-"}</span>
            <b>Borrower Name 1 :</b>
            <span>{activeRowItemDetail.borrower_name1 || "-"}</span>
            <b>Borrower Name 2 : </b>
            <span>{activeRowItemDetail.borrower_name2 || "-"}</span>
            <b>Type : </b>{" "}
            <span>{activeRowItemDetail.liability_type || "-"}</span>
            <b>Subtype : </b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Repayment Method : </b>
            <span>{activeRowItemDetail.repayment_method || "-"}</span>
            <b>Purpose : </b> <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Provider : </b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term:</b> <span>{activeRowItemDetail.term || "-"}</span>
            <b>Amount Borrowed :</b>
            <span>{activeRowItemDetail.amount_borrowed || "-"}</span>
            <b>Amount Outstanding :</b>
            <span>{activeRowItemDetail.amount_outstanding || "-"}</span>
            <b>Outstanding Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.amount_outstanding_date) || "-"}
            </span>
            <b>Interest Rate :</b>
            <span>{activeRowItemDetail.interest_rate || "-"}</span>
            <b>Interest Basis:</b>
            <span>{activeRowItemDetail.interest_basis || "-"}</span>
            <b>Status :</b> <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Premium Covered :</b>
            <span>{activeRowItemDetail.covered || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent value={activeRowItemDetail.note_text || "-"} />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Address :</b>
            <p>
              {activeRowItemDetail.provider_address1 || "-"}
              <br />
              {activeRowItemDetail.provider_address2}
              <br />
              {activeRowItemDetail.provider_address3}
            </p>
            <b>Town :</b>
            <span>{activeRowItemDetail.provider_town || "-"}</span>
            <b>County :</b>
            <span>{activeRowItemDetail.provider_county || "-"}</span>
            <b>Country :</b>
            <span>{activeRowItemDetail.provider_country || "-"}</span>
            <b>Postcode :</b>
            <span>{activeRowItemDetail.provider_postcode || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Linked to Mortgage :</b>
            <span>
              {getYes_NoConversion(activeRowItemDetail.link_to_mortgage) || "-"}
            </span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.surrender_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Sum Assured :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>
            <b>Assigned To :</b>
            <span>{activeRowItemDetail.assigned_to || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Contracted Out :</b>
            <span>{activeRowItemDetail.contracted_out || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.transfer_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Expression of Wish :</b>
            <span>{activeRowItemDetail.expression_of_wish || "-"}</span>
            <b>Expression of Wish Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.expression_of_wishnote || "-"}
            />
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>{" "}
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.surrender_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>
            <b>Assigned To :</b>
            <span>{activeRowItemDetail.assigned_to || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.surrender_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>{" "}
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>{" "}
            <b>Assigned To :</b>
            <span>{activeRowItemDetail.assigned_to || "-"}</span>{" "}
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Deferred Period :</b>
            <span>{activeRowItemDetail.deffered_period || "-"}</span>
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.business_category === 1) {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      }
    }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <>
      <div className="">
        <div className="">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "0.4rem",
            }}
          >
            <ASCButton
              shape="rounded"
              onClick={() => {
                setIsOpen(true);
              }}
              size="medium"
              icon={<DocumentBulletList20Regular />}
              disabled={isObjectEmpty(selectedItem) ? true : false}
              label="Details"
            />
          </div>

          <div className="caseSummary">
            <MaterialReactTable
              columns={columns}
              data={contactHistoryItems}
              enableGlobalFilter={false}
              enableColumnActions={false}
              enableRowSelection
              enableMultiRowSelection={false}
              enableRowActions={false}
              positionToolbarAlertBanner="none"
              getRowId={(row) => row.rowId}
              muiTableBodyRowProps={({ row }: any) => ({
                onClick: row.getToggleSelectedHandler(),
                title: "Double-click here to edit the record",
                onDoubleClick: () => {
                  row.getToggleSelectedHandler();
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      contact_history_data: row.original,
                      type: row.original.category,
                      case_type: row.original.case_type,
                      setActiveItem: props.setActiveItem,
                      activeClient: props.activeClient,
                      customerId: props.activeCustomerId,
                      close: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Cancel",
                      },
                    })
                  );
                },
                sx: { cursor: "pointer" },
              })}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Actions",
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                  muiTableBodyCellProps: {
                    align: "center",
                  },
                },
              }}
              enableStickyHeader
              renderTopToolbarCustomActions={({ table }) => (
                <>
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("case/client_action_btn") ? (
                    <Button
                      shape="rounded"
                      disabled={
                        !convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("case/client_action_btn")
                          ? false
                          : true
                      }
                      onClick={onHideClick}
                      size="medium"
                      icon={hide ? <Eye24Filled /> : <EyeOff24Filled />}
                    >{`${hide ? "Show " : "Hide "}Case/Client Action`}</Button>
                  ) : (
                    ""
                  )}
                </>
              )}
              positionActionsColumn="last"
              state={{ rowSelection }}
              onRowSelectionChange={setRowSelection}
            />
          </div>
        </div>
        <OverlayDrawer
          position={"end"}
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
          className={"common-drawer"}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <ASCButton
                  shape="rounded"
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => setIsOpen(false)}
                />
              }
            >
              Details
            </DrawerHeaderTitle>
          </DrawerHeader>
          <DrawerBody>{Details(selectedItem)}</DrawerBody>
        </OverlayDrawer>
      </div>
      <ModalDialog options={dialogModalOptions} />
    </>
  );
};

export default ContactHistory;
