import {
  Badge,
  Button,
  Checkbox,
  makeStyles,
  Menu,
  MenuButton,
  MenuPopover,
  MenuTrigger,
  Tab,
  TabList,
} from "@fluentui/react-components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DateFilter from "./DateFilter";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppliedSelectedDates,
  setCurrentSelectedDates,
} from "../../../redux/Commission/FilterSlice";
import { setSelectedProviders } from "../../../redux/Commission/commissionSlice";
import ASCButton from "../../../components/Button";

enum TabValues {
  selectDate = "tab1",
  SelectProvider = "tab2",
}

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "20px",
    width: "500px",
  },
  fullWidthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    gap: "3px",
    padding: "5px",
  },
});

interface FilterTabProps {
  filterPopup: boolean;
  setFilterPopup: (value: boolean) => void;
  selectedMenuItem: string;
  title: string;
  getReceiptlistData?: any;
  providerOptions: { label: string; checked: boolean; id: any }[];
}

const FilterTab: React.FC<FilterTabProps> = (props) => {
  const {
    filterPopup,
    setFilterPopup,
    title,
    providerOptions,
    getReceiptlistData,
  } = props;
  const dispatch: AppDispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<TabValues>(
    TabValues.selectDate
  );
  const currentSelectedDates = useSelector(
    (state: RootState) => state.filterDate?.currentSelectedDates
  );
  const appliedSelectedDates = useSelector(
    (state: RootState) => state.filterDate?.appliedSelectedDates
  );
  const selectedProviders = useSelector(
    (state: RootState) => state.commission.selectedProviders
  );
  const [currentOptions, setCurrentOptions] = useState<boolean[]>(
    providerOptions.map(() => true)
  );
  const [appliedOptions, setAppliedOptions] = useState<boolean[]>(
    providerOptions.map(() => true)
  );

  // const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

  const styles = useStyles();
  const selectAllRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectAllRef.current) {
      const allChecked = currentOptions.every((checked) => checked);
      const noneChecked = currentOptions.every((checked) => !checked);
      const selectAllCheckbox = selectAllRef.current.querySelector(
        'input[type="checkbox"]'
      ) as HTMLInputElement;
      if (selectAllCheckbox) {
        selectAllCheckbox.indeterminate = !allChecked && !noneChecked;
      }
    }
  }, [currentOptions]);

  useEffect(() => {
    dispatch(setCurrentSelectedDates(appliedSelectedDates));
    setCurrentOptions(appliedOptions);
  }, [filterPopup, appliedSelectedDates, appliedOptions]);

  useEffect(() => {
    dispatch(
      setSelectedProviders(
        providerOptions
          .filter((_, index) => appliedOptions[index])
          .map((option) => option.label)
      )
    );
  }, [appliedOptions, providerOptions]);

  const handleSelectAllChange = (isChecked: boolean) => {
    setCurrentOptions(providerOptions.map(() => isChecked));
  };

  const handleOptionChange = (index: number) => {
    setCurrentOptions((prevOptions) =>
      prevOptions.map((checked, i) => (i === index ? !checked : checked))
    );
  };

  const handleApplyFilters = useCallback(() => {
    getReceiptlistData?.();
    setAppliedOptions(currentOptions);
    dispatch(setAppliedSelectedDates(currentSelectedDates));
    setFilterPopup(false);
  }, [currentOptions, currentSelectedDates, getReceiptlistData]);

  return (
    <Menu open={filterPopup}>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          onClick={() => setFilterPopup(true)}
          style={{
            fontWeight: "normal",
            color: "#606060",
            border: "1px solid grey",
          }}
        >
          <div>
            {!currentSelectedDates && currentOptions.length === 0 ? (
              <span className="customTriggerText">Filter</span>
            ) : (
              <div>
                {currentSelectedDates && (
                  <span className="customTriggerText">
                    {currentSelectedDates},
                  </span>
                )}
                {currentOptions.length > 0 && (
                  <span>
                    {currentOptions.slice(0, 1).join(", ")}
                    {currentOptions.length > 1 && (
                      <>
                        ,{" "}
                        <Badge className="badge">
                          +{currentOptions.length - 1}
                        </Badge>
                      </>
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
        </MenuButton>
      </MenuTrigger>
      <MenuPopover style={{ minWidth: "500px", minHeight: "300px" }}>
        <div className={styles.root}>
          <TabList
            defaultSelectedValue={TabValues.selectDate}
            onTabSelect={(event, data) =>
              setSelectedTab(data.value as TabValues)
            }
          >
            <Tab value={TabValues.selectDate}>Select Dates</Tab>
            <Tab value={TabValues.SelectProvider}>{title}</Tab>
          </TabList>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "stretch",
            padding: "3px",
          }}
        >
          {selectedTab === TabValues.selectDate && (
            <div className={styles.fullWidthContainer}>
              <DateFilter
                optionDateValue={currentSelectedDates}
                setSelectedDates={(value: string | null) => {
                  if (value !== null) {
                    dispatch(setCurrentSelectedDates(value));
                  }
                }}
              />
            </div>
          )}
          {selectedTab === TabValues.SelectProvider && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "3px",
                gap: "5px",
                width: "500px",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              <div ref={selectAllRef}>
                <Checkbox
                  checked={currentOptions.every((checked) => checked)}
                  onChange={(_ev, data: any) =>
                    handleSelectAllChange(data.checked)
                  }
                  label="Select All"
                />
              </div>
              {providerOptions.map((option, index) => (
                <Checkbox
                  key={option.id}
                  checked={currentOptions[index]}
                  onChange={() => handleOptionChange(index)}
                  label={option.label}
                />
              ))}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <ASCButton
            shape="rounded"
            size="medium"
            onClick={() => {
              setFilterPopup(false);
              setSelectedTab(TabValues.selectDate);
            }}
            label="Cancel"
          />

          <ASCButton
            shape="rounded"
            className="asc-button-primary"
            size="medium"
            appearance="primary"
            onClick={() => {
              handleApplyFilters();
              setSelectedTab(TabValues.selectDate);
            }}
            label="Apply"
          />
        </div>
      </MenuPopover>
    </Menu>
  );
};

export default FilterTab;
