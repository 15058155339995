import React from "react";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogContent,
  DialogActions,
  DialogTrigger,
} from "@fluentui/react-components";

import { Spinner } from "@fluentui/react-components";
import ASCButton from "../../../components/Button";
import LoadStandardAction from "../../../components/LoadStandardAction";

interface StandardActionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  loadStandardActionDate: any;
  setLoadStandardActionDate: (value: any) => void;
  selectedLoadStandardActions: any[];
  setSelectedLoadStandardActions: (value: any[]) => void;
  loadActionSelected: (action?: any) => void;
  addLoadStandardActions: () => void;
  actionLoading: boolean;
}

const StandardActionDialog: React.FC<StandardActionDialogProps> = ({
  isOpen,
  onClose,
  loadStandardActionDate,
  setLoadStandardActionDate,
  selectedLoadStandardActions,
  setSelectedLoadStandardActions,
  loadActionSelected,
  addLoadStandardActions,
  actionLoading,
}) => {
  return (
    <Dialog open={isOpen} modalType="modal">
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <LoadStandardAction
              loadStandardActionDate={loadStandardActionDate}
              setLoadStandardActionDate={setLoadStandardActionDate}
              selectedLoadStandardActions={selectedLoadStandardActions}
              loadActionSelected={loadActionSelected}
              type="case"
            />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <ASCButton
                shape="rounded"
                appearance="secondary"
                size="medium"
                onClick={() => {
                  setSelectedLoadStandardActions([]);
                  onClose();
                }}
                label="Close"
              />
            </DialogTrigger>
            <ASCButton
              shape="rounded"
              appearance="primary"
              className="asc-button-primary"
              size="medium"
              onClick={addLoadStandardActions}
              icon={actionLoading ? <Spinner size="tiny" /> : null}
              label={actionLoading ? "Processing..." : "Submit"}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default StandardActionDialog;
