import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";
import {
  FolderFilled,
  DocumentTextRegular,
  MoreVerticalRegular,
  ArrowDownloadRegular,
} from "@fluentui/react-icons";
import "./style.css";
import apiServices from "../../../service";
import { formatedDate } from "../../../utils";
import ASCButton from "../../../components/Button";

interface FileItem {
  type: "file";
  name: string;
  lastModified: string;
  path: string;
  metadata: {
    customerid: string;
    userid: string;
    description: string;
    visible: string;
    documentid: string;
  };
}

interface FolderItem {
  type: "folder";
  name: string;
  lastModified: string;
  path: string;
  children: (FolderItem | FileItem)[];
}

type Item = FolderItem | FileItem;

interface FileFolderItemProps {
  item: Item;
  navigateTo: (path: string[], children: any[]) => void;
  currentPath: string[];
  activeCustomerId: string;
  search: string;
}

const FileFolderItem: React.FC<FileFolderItemProps> = ({
  item,
  navigateTo,
  currentPath,
  activeCustomerId,
  search,
}) => {
  const handleNavigate = () => {
    if (item.type === "folder") {
      if (search) navigateTo([item.path], item.children);
      else navigateTo([...currentPath, item.name], []);
    } else if (item.type === "file") {
      const folderPath = item.path.split("/").slice(0, -1);
      navigateTo(folderPath, []);
    }
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const downloadFile = (fileUrl: string, DocumentTitle: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = DocumentTitle;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = (fileId: string) => {
    apiServices.document
      .getDocument(fileId, activeCustomerId)
      .then((res) => {
        if (res.data && res.data.data) {
          const { fileUrl, DocumentTitle } = res.data.data;
          downloadFile(fileUrl, DocumentTitle);
        }
      })
      .catch((err: any) => console.log(err));
  };

  const formatName = (name: string, type: "file" | "folder") => {
    if (name.length > 30) {
      if (type === "file") {
        const lastFour = name.slice(-4);
        const truncated = name.slice(14, 30);
        return `${truncated}....${lastFour}`;
      } else {
        return `${name.slice(0, 21)}`;
      }
    } else {
      if (type === "file") {
        const truncated = name.slice(14, 30);
        return `${truncated}`;
      } else {
        return `${name.slice(0, 21)}`;
      }
    }
  };

  const formatPath = (fullPath: string): string => {
    const parts = fullPath.split("/").filter((part) => part); // Remove empty segments
    const parentPath = parts.slice(0, -1).join("/"); // Take all but the last segment
    return parentPath ? `${parentPath}/` : "/"; // Ensure it ends with "/" and handle root case
  };

  return (
    <TableRow>
      <TableCell>
        <div
          onClick={item.type === "folder" ? handleNavigate : undefined}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: item.type === "folder" ? "pointer" : "default",
            width: "15vw",
          }}
        >
          {item.type === "folder" ? (
            <FolderFilled primaryFill="#fccc77" fontSize="24px" />
          ) : (
            <DocumentTextRegular primaryFill="#cdcdcd" fontSize="24px" />
          )}
          <span style={{ marginLeft: "8px" }} title={item.name}>
            {formatName(item.name, item.type)}
          </span>
        </div>
      </TableCell>
      <TableCell style={{ width: "5vw" }}>
        {item.type === "folder" ? "Folder" : "File"}
      </TableCell>
      <TableCell style={{ width: "8vw" }}>
        {formatedDate(item.lastModified)}
      </TableCell>
      <TableCell>
        {item.type === "file" ? (
          <span
            dangerouslySetInnerHTML={{
              __html: item.metadata.description || "-",
            }}
          />
        ) : (
          "-"
        )}
      </TableCell>
      {search && (
        <TableCell style={{ wordBreak: "break-word" }}>
          {formatPath(item.path)}
        </TableCell>
      )}
      {item.type === "file" && (
        <TableCell>
          <Popover
            open={isPopoverOpen}
            positioning={"below"}
            onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
          >
            <PopoverTrigger>
              <ASCButton shape="rounded" icon={<MoreVerticalRegular />} />
            </PopoverTrigger>
            <PopoverSurface>
              <div className="flex-column">
                <ASCButton
                  shape="rounded"
                  icon={<ArrowDownloadRegular />}
                  onClick={() => handleDownload(item.metadata.documentid)}
                  label="Download"
                />
              </div>
            </PopoverSurface>
          </Popover>
        </TableCell>
      )}
    </TableRow>
  );
};

export default FileFolderItem;
