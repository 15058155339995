import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getAssetDetailById,
  getAssetFundDetailById,
  getAssetFundsListItems,
} from "../../../../redux/Case/assetSlice";
import { getCaseCategoryByCaseType } from "../../../../utils";
import { Label, Toaster } from "@fluentui/react-components";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import { makeStyles, tokens } from "@fluentui/react-components";
import CaseDetailDrawer from "../CaseDetailDrawer";
import { useToastMessage } from "../../../../components/ToastMessage/ToastMessage";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

const AssetFunds = ({ tab = "fund" }: { tab?: string }) => {
  const navigate = useNavigate();
  const { clientId, action, caseId, subItemId } = useParams();
  const styles = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const assets = useSelector((state: RootState) => state.assets);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const [caseType, setCaseType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(tab);
  const [isOpen, setIsOpen] = useState(false);
  const { showToast, toasterId } = useToastMessage();

  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getAssetDetailById({ id: caseId, customerId: clientId }));
      dispatch(getAssetFundsListItems({ id: caseId, customerId: clientId }));
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (assets?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(assets.activeItem.case_type));
    }
  }, [assets?.activeItem]);
  useEffect(() => {
    if (action && action != "add" && assets?.funds.listItems.length > 0) {
      const fundId = subItemId || assets.funds.listItems[0]?.fund_id;
      navigate(
        `/clients/${clientId}/assets/${caseId}/funds/${fundId}/${tab}/${
          action || "view"
        }`
      );
    }
  }, [
    clientId,
    caseId,
    subItemId,
    tab,
    action,
    assets.funds.listItems,
    navigate,
  ]);
  useEffect(() => {
    if (subItemId) {
      dispatch(getAssetFundDetailById(subItemId));
    }
  }, [subItemId, dispatch, assets.funds.listItems]);

  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <CaseNavList
        subItem="funds"
        section="assets"
        tab={activeTab}
        caseType={caseType}
        caseDetail={assets?.funds?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="assets"
          caseType={caseType}
          caseDetail={assets?.funds?.activeItem}
          caseItemList={assets?.funds?.listItems}
          tab={activeTab}
          subItem="funds"
          fundValue={assets?.funds.totalFund}
          notify={showToast}
        />
        <div>
          {assets?.funds?.loading ? (
            <ContentLoader />
          ) : assets?.funds?.listItems?.length > 0 ? (
            <CaseList
              module="funds"
              data={assets.funds.listItems}
              activeItem={assets.funds.activeItem}
              section="assets"
              activeTab={activeTab}
            />
          ) : (
            !action && (
              <div className={styles.emptyState}>
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </div>
            )
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={assets?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
};

export default AssetFunds;
