import { useParams, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  shorthands,
  tokens,
  TabList,
  Tab,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Subtitle2,
  Caption1,
  Caption1Strong,
  Link,
} from "@fluentui/react-components";
import { ArrowLeft20Regular } from "@fluentui/react-icons";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import ClientPageSidebar from "./ClientPageSidebar";
import apiServices from "../../service";
import OverlayLoader from "../../components/OverlayLoader";
import { removeEmptyValues } from "../../utils";
import { getCustomerInfoById } from "../../redux/activeClientSlice";
import Breadcrumbs from "../../layout/breadcrumb/Breadcrumbs";
import ContentLoader from "../../components/Loader/ContentLoader";
const ClientDetailPage = () => {
  const { clientId } = useParams(); // Get clientId from the URL
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [clientInfo, setClientInfo] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const activeClient: any = useSelector(
    (state: RootState) => state.activeClient
  );
  useEffect(() => {
    // setPageLoading(true);
    if (clientId != undefined) {
      dispatch(getCustomerInfoById(clientId));
      // getClientDetails();
    }
  }, [clientId, dispatch]);
  useEffect(() => {
    if (activeClient?.basicDetails) {
      setClientInfo(activeClient?.basicDetails);
    }
  }, [activeClient]);

  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  return (
    <div
      style={{
        display: "flex",
        height: "calc(100vh - 150px)",
        gap: 10,
      }}
    >
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <OverlayLoader isLoading={pageLoading} />
      <aside
        style={{
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <ClientPageSidebar />
      </aside>
      <main
        style={{
          flex: 1,
          padding: "0 0.6rem",
          width: "calc(100vw - 230px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: 1, overflowY: "auto" }}>
          {activeClient?.loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 250px)",
              }}
            >
              <ContentLoader />
            </div>
          ) : (
            <div>
              <Breadcrumbs />
              <Outlet context={{ clientInfo, notify }} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ClientDetailPage;
