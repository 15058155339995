import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  makeStyles,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import React from "react";
import ASCButton from "../../../components/Button";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  widthcontent: {
    width: "450px",
  },
});
function StopDistribution(props: any) {
  const styles = useStyles();
  return (
    <div>
      <Dialog open={props.stopDistributionpopup}>
        <DialogSurface className={styles.widthcontent}>
          <DialogBody className={styles.content}>
            <div>
              <h4>Stop Commission Payments</h4>
            </div>
            <p>Please enter a date when the payment(s) will be stopped </p>
            <p>
              Most Recent Payment Date : <span>16/10/2024</span>
            </p>

            <p>Next Payment Date : 16/10/2024</p>
            <RadioGroup defaultValue="lastduedate">
              <Radio
                value="lastduedate"
                label="Stop all payments after Most Recent Payment Date "
              />

              <Radio
                value="beforeduedate"
                label="Stop all payment after this date "
              />
              <div style={{ marginLeft: "30px" }}>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  name={"review_completed"}
                />
              </div>
            </RadioGroup>
            <DialogActions>
              <div className="button-end-popup">
                <Button
                  shape="rounded"
                  className="asc-button-primary"
                  appearance="primary"
                  type="submit"
                  size="medium"
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <Button
                  shape="rounded"
                  appearance="secondary"
                  type="submit"
                  size="medium"
                  onClick={() => props.setStopdistributionpopup(false)}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default StopDistribution;
