import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
} from "@fluentui/react-components";
import React, { useState, useEffect } from "react";
import Specifications from "./Specifications";
import TreeComponent from "./TreeComponent";
import SaveSpecification from "./SaveSpecification";
import Confirmation from "./Confirmation";
import ExportSpecification from "./ExportSpecification";
import apiServices from "../../service";
import store, { AppDispatch, RootState } from "../../redux/store";
import { url } from "inspector";
import axios from "axios";
import OverlayLoader from "../../components/OverlayLoader";
import { useDispatch, useSelector } from "react-redux";
import { setString } from "../../redux/SavedSearch/searchId";

interface ExportMainProps {
  isOpen: boolean;
  onClose: () => void;
  searchIds: React.Dispatch<React.SetStateAction<string>>;
  selectedTab: any;
  typeOfExtract: string;
  searchId: any;
}
type PayloadItem = {
  table: string;
  fieldName: string;
  label: string;
  partner: boolean;
};

const ExportMain: React.FC<ExportMainProps> = ({
  isOpen,
  onClose,
  searchIds,
  selectedTab,
  typeOfExtract,
  searchId,
}) => {
  const [checkboxOn, setCheckBoxOn] = useState<{
    header: boolean;
  }>({ header: true });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [isAvailable, setIsAvailable] = useState<boolean | string>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [saveTitle, setSaveTitle] = useState<string>("");
  const [savePayload, setSavePayload] = useState<string>("");
  const [saveDescription, setSaveDescription] = useState<string>("");
  const [active, setActive] = useState<
    | "SPECIFICATION"
    | "TREE"
    | "SAVE_SPECIFICATION"
    | "CONFIRMATION"
    | "EXPORT"
    | null
  >("SPECIFICATION");
  const [selectedSpecId, setSelectedSpecId] = useState<any>(null);
  const [itemsToExport, setItemsToExport] = useState<
    { field: string; path: string }[]
  >([]);
  let timestamp: any = new Date();
  timestamp = timestamp.toISOString();
  const generateNonce = () => {
    const CryptoJS = require("crypto-js");
    const requestData = {};
    const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
    return hash.toString(CryptoJS.enc.Hex);
  };
  const { userProfileInfo } = useSelector((state: any) => state.authUser);

  const nonce = generateNonce();
  const [specificationList, setSpecificationList] = useState<any>([]);
  const [savedData, setSavedData] = useState<{
    title: string;
    description: string;
    isAvailable: boolean | string;
  } | null>(null);
  const [editData, setEditData] = useState<string[]>([]);
  const [modifiedPayload, setModifiedPayload] = useState<any>([]);
  const directExport = savePayload;
  const storedValue = useSelector((state: RootState) => state.string.value);
  const updatedSearchId = useSelector(
    (state: RootState) => state.updatestring.value
  );
  const selectionType = useSelector(
    (state: RootState) => state.selectionType.value
  );
  const dispatch: AppDispatch = useDispatch();

  console.log(storedValue, "storedvalue");
  const resetState = () => {
    setActive("SPECIFICATION");
    setItemsToExport([]);
    setSavedData(null);
    setSaveTitle("");
    setSaveDescription("");
    setSavePayload("");
  };

  const handleClose = () => {
    onClose();
    setValue("");
    setTitle("");
    setDescription("");
    setSaveTitle("");
    setSavePayload("");
    setSaveDescription("");
    setItemsToExport([]);
    setEditData([]);
    setSelectedSpecId(null);
  };

  const handleCloseTree = () => {
    onClose();
    setValue("");
    setTitle("");
    setDescription("");
    setSaveTitle("");
    setSavePayload("");
    setSaveDescription("");
    setItemsToExport([]);
    setEditData([]);
    setSelectedSpecId(null);
  };
  const handleCloseSaveSpec = () => {
    onClose();
    setValue("");
    setTitle("");
    setDescription("");
    setSaveTitle("");
    setSavePayload("");
    setSaveDescription("");
    setItemsToExport([]);
    setEditData([]);
    setSelectedSpecId(null);
  };
  const handleCloseExport = () => {
    onClose();
    setValue("");
    setTitle("");
    setDescription("");
    setSaveTitle("");
    setSavePayload("");
    setSaveDescription("");
    setItemsToExport([]);
    setEditData([]);
    setSelectedSpecId(null);
  };

  const confirmationNo = () => {
    setActive("EXPORT");
    let x = directExport;
  };
  useEffect(() => {
    if (isOpen) {
      resetState();
    }
  }, [isOpen]);

  const handleNewClick = () => {
    setActive("TREE");
    setValue("");
    setTitle("");
    setDescription("");
    setSaveTitle("");
    setSavePayload("");
    setSaveDescription("");
    setItemsToExport([]);
    setEditData([]);
    setSelectedSpecId(null);
  };
  const handleBackClick = () => setActive("SPECIFICATION");

  const handleNewClickToSave = (payload: string) => {
    setActive("SAVE_SPECIFICATION");
    setSavePayload(payload);
  };

  const moveToConfirmation = () => setActive("CONFIRMATION");

  const AddListing = () => {
    setLoading(true); // Start loading

    const data: any = {
      title: saveTitle,
      description: saveDescription,
      condition: savePayload,
      type: "Export",
      Header: String(checkboxOn.header),
    };

    if (!isAvailable && userProfileInfo.user_id) {
      data.UserId = userProfileInfo.user_id; // Only add UserId if it's not null
    }

    apiServices.exportExcel
      .addList(data)
      .then(() => {
        getListing(); // Call after successful API response
      })
      .catch((error) => {
        console.error("Error during export:", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const UpdateListing = () => {
    setLoading(true); // Start loading

    const data: any = {
      title: savedData?.title || saveTitle,
      description: savedData?.description || saveDescription,
      condition: savePayload,
      type: "Export",
      Header: String(checkboxOn.header),
    };

    if (!isAvailable && userProfileInfo.user_id) {
      data.UserId = userProfileInfo.user_id; // Only add UserId if it's not null
    }

    apiServices.exportExcel
      .updateList(data, selectedSpecId)
      .then(() => {
        getListing(); // Call after successful API response
      })
      .catch((error) => {
        console.error("Error during update:", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  // const moveToExport = () => {
  //   AddListing();
  //   getListing();
  //   setActive("EXPORT");
  //   getListing();
  // };
  const moveToExport = () => {
    if (selectedSpecId) {
      UpdateListing();
    } else {
      AddListing();
    }
    getListing();
    setActive("EXPORT");
  };

  const handleSaveData = (data: {
    title: string;
    description: string;
    isAvailable: boolean | string;
  }) => {
    setSavedData(data);
    setSaveTitle(data.title);
    setSaveDescription(data.description);
  };
  const deleteList = async (id: any) => {
    setLoading(true);
    try {
      await apiServices.exportExcel.deleteList(id);
      await getListing();
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      setLoading(false);
    }
  };

  const getListing = async () => {
    setLoading(true);
    try {
      const res = await apiServices.exportExcel.getList();
      const response = res.data?.data;
      setSpecificationList(response);
    } catch (error) {
      console.error("Error fetching listing:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getListing();
  }, []);

  const selectedListing = () => {
    const selectedSpec = specificationList.find(
      (spec: any) => spec.SearchId === selectedSpecId
    );
    if (selectedSpec) {
      setTitle(selectedSpec?.Title);
      setDescription(selectedSpec?.Description);
      // setValue(selectedSpec?.UserId);

      // Check if UserId is null → check the checkbox
      setIsAvailable(selectedSpec?.UserId === null);

      const parsedCondition = JSON.parse(selectedSpec?.Condition || "[]");
      if (Array.isArray(parsedCondition)) {
        const extractedPath = parsedCondition.map((item: any) => {
          const pathMatch = item.label.match(/path:(.*)/);
          return pathMatch ? pathMatch[1] : "";
        });
        setEditData(extractedPath);
      } else {
        setEditData([]);
      }
    }
    setActive("TREE");
  };

  useEffect(() => {
    if (Array.isArray(savePayload)) {
      const updatedPayload = savePayload.map((item) => ({
        ...item,
        label: item.label.split(",path:")[0],
      }));

      setModifiedPayload(updatedPayload);
    } else {
    }
  }, [savePayload]);
  const exportToExcel = async () => {
    try {
      setLoading(true);

      const data = {
        condition: modifiedPayload,
        // Type:
        //   typeOfExtract.length > 0
        //     ? typeOfExtract
        //     : selectionType === "Selection"
        //     ? "Selection"
        //     : "Condition",
        Type: selectionType === "Selection" ? "Selection" : "Condition",
      };

      const token = store.getState().authUser.userToken;
      const userId = store.getState().authUser.userId;
      const refreshToken = store.getState().authUser.refreshToken;
      console.log(searchId, "searchId");
      const baseUrl =
        typeOfExtract === "Selection"
          ? `${process.env.REACT_APP_BASE_URL}client/customer/export/${searchIds}`
          : typeOfExtract === "Condition"
          ? `${process.env.REACT_APP_BASE_URL}client/customer/export/${
              searchId.length > 0 ? searchId : storedValue
            }`
          : `${process.env.REACT_APP_BASE_URL}client/customer/export/${
              storedValue.length > 0 ? storedValue : updatedSearchId
            }`;

      // Append `header=true` if checkboxOn.header is true
      const url = checkboxOn.header ? baseUrl : `${baseUrl}?heading=no`;

      // Make the POST request
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Nonce": nonce,
          "X-Timestamp": timestamp,
          UserId: userId,
          RefreshToken: refreshToken,
        },
        responseType: "blob",
      });

      // Handle the file download
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;

      // Generate a timestamp for the filename
      const timestamp1 = new Date().toISOString().replace(/[-:.]/g, "");
      link.setAttribute("download", `export_${timestamp1}.xlsx`);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Export to Excel failed", error);
    } finally {
      setLoading(false);
      dispatch(setString(""));

      onClose();
    }
  };

  return (
    <>
      <Dialog
        modalType="alert"
        open={isOpen}
        onOpenChange={(event, data) => {
          if (!data.open) handleClose();
        }}
      >
        <DialogSurface>
          <OverlayLoader isLoading={isLoading} />

          <DialogBody>
            <DialogContent>
              {active === "SPECIFICATION" && (
                <Specifications
                  setEditData={setEditData}
                  onNewClick={handleNewClick}
                  cancelSpecification={handleClose}
                  deleteList={deleteList}
                  specificationList={specificationList}
                  setSelectedSpecId={setSelectedSpecId}
                  selectedSpecId={selectedSpecId}
                  selectedListingEdit={selectedListing}
                />
              )}
              {active === "TREE" && (
                <TreeComponent
                  onBackClick={handleBackClick}
                  onMoveToSave={handleNewClickToSave}
                  itemsToExport={itemsToExport}
                  setItemsToExport={setItemsToExport}
                  cancelTree={handleCloseTree}
                  editData={editData}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  checkboxOn={checkboxOn}
                  setCheckBoxOn={setCheckBoxOn}
                />
              )}
              {active === "SAVE_SPECIFICATION" && (
                <SaveSpecification
                  BackToTree={() => setActive("TREE")}
                  MoveToConfirmation={moveToConfirmation}
                  CancelSaveSpecification={handleCloseSaveSpec}
                  onSaveData={handleSaveData}
                  setValue={setValue}
                  value={value}
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                  setIsAvailable={setIsAvailable}
                  isAvailable={isAvailable}
                />
              )}
              {active === "CONFIRMATION" && (
                <Confirmation
                  MoveToExport={moveToExport}
                  cancelConfirmation={confirmationNo}
                  savedData={savedData}
                />
              )}
              {active === "EXPORT" && (
                <ExportSpecification
                  MoveToExport={moveToExport}
                  BackToConfirmation={() => setActive("CONFIRMATION")}
                  CancelExport={handleCloseExport}
                  exportToExcel={exportToExcel}
                  isLoading={isLoading}
                  setLoading={setLoading}
                />
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default ExportMain;
