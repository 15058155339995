import { DrawerProps } from "@fluentui/react-components";
import * as React from "react";
import {
  Hamburger,
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavDrawerProps,
  NavItem,
  NavSubItem,
  NavSubItemGroup,
} from "@fluentui/react-nav-preview";

import { Tooltip, makeStyles, tokens, useId } from "@fluentui/react-components";
import { MailTemplate24Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { checkUserPermission, convertStringToArray } from "../../utils";
import { UserPermissionsList } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    display: "flex",
    height: "calc(100vh - 150px)",
    width: "200px",
  },
  content: {
    flex: "1",
    padding: "16px",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  field: {
    display: "flex",
    marginTop: "4px",
    marginLeft: "8px",
    flexDirection: "column",
    gridRowGap: tokens.spacingVerticalS,
  },
});
type DrawerType = Required<DrawerProps>["type"];
const Sidebar = (props: Partial<NavDrawerProps>) => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(true);
  const [type, setType] = React.useState<DrawerType>("inline");
  const permissions = {
    clientReports: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_CLIENT_REPORTS
    ),
  };
  return (
    <div className={styles.root}>
      <NavDrawer
        open={isOpen}
        type={type}
        size="medium"
        defaultSelectedValue="email"
      >
        <NavDrawerBody>
          {/* {permissions.clientReports && (
            <NavItem
              value="report"
              onClick={() => {
                navigate("/utilities/report");
              }}
            >
              Reports
            </NavItem>
          )} */}

          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "utilities_email"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("/utilities/email");
              }}
              value="email"
              // icon={<MailTemplate24Regular />}
            >
              Email
            </NavItem>
          ) : (
            ""
          )}
          <NavItem
            onClick={() => {
              navigate("/utilities/letter");
            }}
            value="Letter"
            // icon={<MailTemplate24Regular />}
          >
            Letter
          </NavItem>
        </NavDrawerBody>
      </NavDrawer>
    </div>
  );
};

export default Sidebar;
