import { useEffect, useState } from "react";
import {
  Dropdown,
  makeStyles,
  Option,
  useId,
  Badge,
} from "@fluentui/react-components";

type ComponentProps = {
  options: { label: string; value: string }[];
  selectedDropDownValues: string[];
  setSelectedDropDownValues: (values: string[]) => void;
  placeholder?: string;
  onOpenChange?: (e: any, data: { open: boolean }) => void;
};

const useStyles = makeStyles({
  root: {
    width: "fit-content !important",
    maxWidth: "450px !important",
    maxHeight: "60px",
    overflowX: "auto",
  },
});

const Multiselectsearch = (props: ComponentProps) => {
  const comboId = useId("combo-multi");
  const styles = useStyles();
  const [optionState, setOptionState] = useState(props.options);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const uniqueOptions = props.options.filter(
      (option, index, self) =>
        index === self.findIndex((t) => t.value === option.value)
    );
    setOptionState(uniqueOptions);
  }, [props.options]);

  const handleOptionChange = (event: any, option: any) => {
    if (!option) return;
    let newSelectedValues = [...props.selectedDropDownValues];
    const allOptionValues = props.options.map((opt) => opt.value);

    if (option.optionValue === "select-all") {
      if (newSelectedValues.length === allOptionValues.length) {
        newSelectedValues = [];
      } else {
        newSelectedValues = allOptionValues;
      }
    } else {
      if (newSelectedValues.includes(option.optionValue)) {
        newSelectedValues = newSelectedValues.filter(
          (val) => val !== option.optionValue
        );
      } else {
        newSelectedValues.push(option.optionValue);
      }
    }
    props.setSelectedDropDownValues(newSelectedValues);
  };

  const selectedCount = props.selectedDropDownValues.length;
  const displayedOptions = props.selectedDropDownValues.slice(0, 2);
  const remainingCount = selectedCount > 2 ? selectedCount - 2 : 0;

  const renderSelectedOptionsText = () => {
    const selectedLabels = displayedOptions.map((optionValue) => {
      const option = optionState.find((opt) => opt.value === optionValue);
      return option ? option.label : "";
    });

    return remainingCount > 0
      ? `${selectedLabels.join(", ")}, +${remainingCount}`
      : selectedLabels.join(", ");
  };

  return (
    <div className={styles.root}>
      <Dropdown
        className="dropdown_button"
        aria-labelledby={comboId}
        multiselect
        placeholder={
          props.selectedDropDownValues.length > 0
            ? renderSelectedOptionsText()
            : props.placeholder
        }
        selectedOptions={
          props.selectedDropDownValues.length === props.options.length
            ? ["select-all", ...props.selectedDropDownValues]
            : props.selectedDropDownValues
        }
        onOptionSelect={handleOptionChange}
        onOpenChange={(e, data) => {
          setIsDropdownOpen(data.open);
          if (props.onOpenChange) {
            props.onOpenChange(e, data); // Call the provided function if it exists
          }
        }}
      >
        <Option value="select-all" key="option-select-all" text="Select All">
          Select All
        </Option>
        {optionState.map((option, index) => (
          <Option
            value={option.value}
            key={`option-${index}`}
            text={option.label}
          >
            {option.label}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default Multiselectsearch;
