import React, { useEffect, useState } from "react";
import "./style.css";
import apiServices from "../../../service";
import { ElementData } from "./helper";
import { useStyles } from "./helper";
import CreatableSelect from "react-select/creatable";
import FormikInputRequest from "../../../service/hook/useFormik";
import {
  makeStyles,
  shorthands,
  tokens,
  TabList,
  Tab,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Button,
  Label,
  Spinner,
} from "@fluentui/react-components";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { resetFormData, setFormData } from "../../../redux/reducer";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  DismissCircleRegular,
  DismissCircle20Regular,
} from "@fluentui/react-icons";
import ContentLoader from "../../../components/Loader/ContentLoader";
import ASCButton from "../../../components/Button";
import { useFormik } from "formik";
interface Option {
  readonly label: string;
  readonly value: string;
}
const initialValues = {};
const formValues = {};

const UserDefinedForm = (props: any) => {
  const [userDefinedData, setUserDefinedData] = useState([]);
  const [clientDefinedData, setClientDefinedData] = useState<any>([]);
  const [selectable, setSelectable] = useState<any>([]);
  // const createOption = (label: string,value:string) => ({
  //   label,
  //   value: label,
  // });
  const [selectFieldOptions, setSelectFieldOptions] = useState([]);
  const [selectionListFields, setSelectionListFields] = useState<any>([]);
  // const defaultOptions = [createOption("")];
  const formik = useFormik<{ [key: string]: any }>({
    initialValues: {},
    enableReinitialize: true,
    validate: (values) => {},
    onSubmit: async (values) => {
      if (isFormError.length === 0) {
        onSubmitForm();
      }
    },
  });
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [mode, setMode] = useState(props.mode || "");
  const classes = useStyles();
  const [options, setOptions] = useState<any>([]);
  const [selectionListNewItems, setSelectionListNewItems] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [updateItemList, setUpdateItemList] = useState<any>([]);
  const [addItemList, setAddItemList] = useState<any>([]);
  const [formupdated, setFormupdated] = useState(false);
  const [isFormError, setIsFormError] = useState<string[]>([]);
  const toasterId = useId("toaster");
  const [isLoading, setIsLoading] = useState(false);
  const { dispatchToast } = useToastController(toasterId);
  const dispatch: AppDispatch = useDispatch();
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState<{ [key: string]: any } | null>(null);
  const [future, setFuture] = useState<any[]>([]);
  useEffect(() => {
    if (props.activeCustomerId && props.activeCustomerId !== null) {
      getClientDefinedData(props.activeCustomerId);
    }
  }, [props.activeCustomerId]);
  const getClientDefinedData = (customer_id: string) => {
    setLoading(true);
    apiServices.client
      .getUserDefined(customer_id)
      .then((response) => {
        if (!response.data.isError) {
          setClientDefinedData(response.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (clientDefinedData.length > 0) {
      checkClientValue();
    }
  }, [clientDefinedData]);

  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const checkClientValue = () => {
    for (const field of clientDefinedData) {
      for (const value of field.user_defined_value) {
        if (value.customer_id === props.activeCustomerId) {
          if (field.type == 0) {
            setSelectionListNewItems((prevData: any) => {
              const newData = { ...prevData };
              newData[field.name] = { value: value.value, label: value.value };

              return newData;
            });
          }
          formik.setFieldValue(field.name, value.value);
        }
      }
    }
  };
  // Selection list
  // const getSelectionListOptions = async (fieldLabels: any) => {
  //   try {
  //     let fieldLbl = "";
  //     if (fieldLabels.length > 0) {
  //       fieldLabels
  //         // .filter((item: any, index: number, self: any) => {
  //         //   return index === self.findIndex((f: any) => f.label === item.label);
  //         // })
  //         .map((itm: any, index: number) => {
  //           // console.log("index", index);
  //           if (fieldLabels.length - 1 != index) {
  //             fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
  //           } else {
  //             fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
  //           }
  //         });

  //       apiServices.selectionList
  //         .getOptions(fieldLbl)
  //         .then((res) => {
  //           if (res.data && res.data.data) {
  //             const filteredOptions = res.data.data.map((item: any) => {

  //               return item;
  //             });

  //             // Update the state or variable holding the options
  //             setSelectFieldOptions(filteredOptions);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  useEffect(() => {
    getSelectionListOptions();
  }, [userDefinedData]);

  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";

      if (userDefinedData.length > 0) {
        const filteredData = userDefinedData.filter(
          (itm: any) => itm.type === 0
        );

        filteredData.forEach((itm: any, index: number) => {
          fieldLbl += `${itm.name}:UserDefinedList`;
          if (index !== filteredData.length - 1) {
            fieldLbl += ",";
          }
        });
        if (fieldLbl) {
          try {
            const response = await apiServices.selectionList.getOptions(
              fieldLbl
            );
            if (response.data && response.data.data) {
              // console.log(response.data.data[0].options[0].value, "res----")
              // console.log(response.data.data, "res----ooo");

              setSelectFieldOptions(response.data.data);
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          console.log("No fields with type 0 found.");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getUserDefinedValueId(userDefinedFieldId: any, dataArray: any) {
    for (const field of dataArray) {
      if (field.user_defined_field_id === userDefinedFieldId) {
        for (const value of field.user_defined_value) {
          return value.user_defined_value_id;
        }
      }
    }
    return null; // Return null if not found
  }
  function handleAdd(element: any) {
    if (
      clientDefinedData.some(
        (field: any) => field.user_defined_field_id === element.id
      )
    ) {
      let updateReqBody = {
        user_defined_value: {
          value: formik.values[element.name],
        },
      };
      apiServices.client
        .updateUserDefined(props.activeCustomerId, updateReqBody)
        .then((response: any) => {
          if (!response.data.isError) {
            notify(response.data.message, "", "success");
          } else {
            notify("Something went wrong", "", "error");
          }
        })
        .catch((err: any) => {
          console.log(err);
          notify("Something went wrong", "", "error");
        });
    } else {
      let addReqBody = {
        user_defined_value: {
          value: formik.values[element.name],
          user_defined_field_id: element.id,
          customer_id: props.activeCustomerId,
        },
      };
      apiServices.client
        .addUserDefined(addReqBody)
        .then((response: any) => {
          if (!response.data.isError) {
            notify(response.data.message, "", "success");
          } else {
            notify("Something went wrong", "", "error");
          }
        })
        .catch((err: any) => {
          console.log(err);
          notify("Something went wrong", "", "error");
        });
    }

    // console.log({ label: [element.name], value: formik.values[element.name] });
  }
  const newItemAdded = (item: any, field: string, action: string) => {
    if (item != undefined) {
      if (action == "add") {
        setSelectionListNewItems((prevData: any) => {
          const newData = { ...prevData };
          if (newData[field]) {
            newData[field] = { value: item.value, label: item.value };
          } else {
            newData[field] = { value: item.value, label: item.value };
          }

          return newData;
        });
      } else {
        if (field && selectionListNewItems[field]) {
          setSelectionListNewItems((prevItems: any) => {
            const newItems = { ...prevItems };
            delete newItems[field];
            return newItems;
          });
        }
      }
    }
  };
  const newOptionAdded = (element: any, label: string) => {
    // console.log("newOptionAdded -element", element);
    // console.log("newOptionAdded -label", label);
    let optns: any = [...options];
    optns[element.name] = [];
    optns[element.name].push({ label: label, value: label });
    // console.log("optns", optns);
    setOptions((prev: any) => [...prev, optns]);
    // setSelectable(option);
  };
  const addNewField = (fieldName: string) => {
    let newField = {
      field: fieldName,
      option: [],
    };

    setSelectionListFields((prev: any) => [...prev, newField]);
  };
  const addNewOption = (fieldName: string, optionData: any) => {
    let newOptions = selectionListFields.map((opt: any) => {
      if (opt.field === fieldName) {
        return {
          ...opt,
          option: [...opt.option, optionData],
        };
      }
      return opt;
    });
    // console.log("newOptions", newOptions);
    setSelectionListFields(newOptions);
  };

  const addNewFieldOption = (field: string, newOption: any) => {
    const existingItemIndex = selectable.findIndex(
      (item: any) => item.field === field
    );
    if (existingItemIndex !== -1) {
      // Update existing item
      const updatedData = [...selectable];
      updatedData[existingItemIndex] = { field, value: newOption };
      setSelectable(updatedData);
    } else {
      // Add new item
      setSelectable([...selectable, { field, value: newOption }]);
    }
  };
  const optionSelected = (option: any) => {
    // console.log("optionSelected", option);

    setSelectable(option);
  };
  const getFieldValue = (fieldName: string) => {
    const foundItem = selectable.find((item: any) => item.field === fieldName);
    return foundItem?.value ?? null; // Return value or null if not found
  };
  function onChange(key: any, event: any) {}
  const updatePresent = (newState: any) => {
    // setPast([...past, present]);
    setPast((prev) => [...prev, present]);
    setPresent(newState);
    setFuture([]);
  };
  const handleOnChange = (field: string, value: any) => {
    const data = { ...formik.values };
    data[field] = value;
    let updated = false;
    // dispatch(setFormData());
    // if (["title", "first_name", "last_name"].includes(event.target.name)) {
    //   formik.setFieldValue("salutation", getSalutations(data));
    // }
    updatePresent(data);
    // if (isFormDataUpdated) {
    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      updated = false;
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
      updated = true;
    }
    // }

    if (mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(mode === "add" ? "New" : "");
    }
  };
  function onSubmitFn() {}
  function validate() {}
  const undo = () => {
    // console.log("past", past);
    if (past.length === 0) return;

    const previous = past[past.length - 1];
    setFuture((prev) => [present, ...prev]);
    setPast((prev) => prev.slice(0, -1));
    setPresent(previous);
    formik.setValues(previous);

    // const newPast = [...past];

    // const newPresent = newPast.pop() || formikData.initialValues;

    // setPast(newPast);
    // setFuture([present, ...future]);
    // setPresent(newPresent);
    // formikData.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const next = future[0];
    setPast((prev) => [...prev, present]);
    setFuture((prev) => prev.slice(1));
    setPresent(next);
    formik.setValues(next);
    // const newFuture = [...future];
    // const newPresent = newFuture.shift();

    // setPast([...past, present]);
    // setFuture(newFuture);
    // setPresent(newPresent);
    // formikData.setValues(newPresent);
  };

  useEffect(() => {
    apiServices.systemManager.userDefined
      .getList()
      .then((res) => {
        if (res.data !== undefined && res.data.data) {
          setUserDefinedData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getOptionsForField = (fieldName: string) => {
    const field = selectionListFields.find(
      (opt: any) => opt.field === fieldName
    );
    // console.log("field.option", field.option);
    return field ? [] : [];
  };
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const onSubmitForm = async () => {
    if (Object.keys(formik.values).length === 0) return;

    setIsLoading(true); // Show loader

    const addItemList: any[] = [];
    const updateItemList: any[] = [];

    Object.keys(formik.values).forEach((key) => {
      const matchingItem = clientDefinedData.find(
        (item: any) => item.name === key
      );

      if (matchingItem) {
        updateItemList.push({
          user_defined_value_id:
            matchingItem.user_defined_value[0].user_defined_value_id,
          value: formik.values[key],
          user_defined_field_id: matchingItem.user_defined_field_id,
        });
      } else {
        const idObj: any = userDefinedData.find(
          (item: any) => item.name === key
        );
        if (idObj) {
          addItemList.push({
            user_defined_field_id: idObj.id,
            value: formik.values[key],
            customer_id: props.activeCustomerId,
          });
        }
      }
    });

    try {
      const promises = [];

      if (addItemList.length > 0) {
        const addReqBody = { user_defined_values: addItemList };
        promises.push(apiServices.client.addUserDefined(addReqBody));
      }

      if (updateItemList.length > 0) {
        const updateReqBody = { user_defined_values: updateItemList };
        promises.push(
          apiServices.client.updateUserDefined(
            props.activeCustomerId,
            updateReqBody
          )
        );
      }

      const responses = await Promise.all(promises);

      responses.forEach((response) => {
        if (!response.data.isError) {
          notify(response.data.message, "", "success");
          getClientDefinedData(props.activeCustomerId);
        } else {
          notify("Something went wrong", "", "error");
        }
      });
    } catch (error) {
      console.error(error);
      notify("Something went wrong", "", "error");
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return loading ? (
    <ContentLoader />
  ) : (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />

      <div className="flex_wraper">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Label size="medium" weight="semibold" className={classes.message}>
            {formMode !== "" && (
              <>
                <span>**</span> {formMode} <span>**</span>
              </>
            )}
          </Label>
        </div>
        <ASCButton
          shape="rounded"
          icon={<ArrowUndo16Regular />}
          onClick={undo}
          disabled={past !== undefined && past.length === 0 ? true : false}
        />
        <ASCButton
          shape="rounded"
          icon={<ArrowRedo16Regular />}
          onClick={redo}
          disabled={future.length === 0 ? true : false}
        />
        <ASCButton
          shape="rounded"
          appearance="transparent"
          onClick={() => {
            setPast([]);
            setFuture([]);
            setPresent(formik.initialValues);
            formik.resetForm();
            dispatch(resetFormData());
          }}
          disabled={!isFormDataUpdated}
          label="Reset All"
        />
      </div>
      <div className="user_defined_page">
        <div className="user_defined">
          {userDefinedData &&
            userDefinedData?.map((item: any) => {
              return (
                <div>
                  {ElementData({
                    element: item,
                    handleAdd,
                    formik,
                    selectable,
                    setSelectable,
                    options,
                    newOptionAdded,
                    optionSelected,
                    addNewOption,
                    selectionListFields,
                    getOptionsForField,
                    addNewFieldOption,
                    getFieldValue,
                    clientDefinedData,
                    selectFieldOptions,
                    selectionListNewItems,
                    handleOnChange,
                    newItemAdded,
                    setIsFormError,
                  })}
                </div>
              );
            })}
        </div>
        <div className="button_wrapper">
          <ASCButton
            shape="rounded"
            className="asc-button-primary"
            appearance="primary"
            onClick={() => {
              formik.handleSubmit();

              // onSubmitForm();
            }}
            icon={isLoading ? <Spinner size="tiny" /> : null}
            label={isLoading ? "Processing..." : "Save"}
          />

          <ASCButton
            onClick={() => {
              if (userDefinedData.length > 0) {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    content_line_1:
                      "We have detected an attempt to move away from the current page.",
                    content_line_2:
                      "This would cause all changes made to be lost",
                    cancel: {
                      onclick: () => {
                        dispatch(
                          setDialogModalOptions({
                            open: false,
                          })
                        );
                        dispatch(resetFormData());
                        props.closePage();
                      },
                      label: "Leave the Page",
                    },
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Stay on Page",
                      type: "primary",
                    },
                  })
                );
              } else {
                props.closePage();
              }
            }}
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default UserDefinedForm;
