import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  makeStyles,
  tokens,
  useToastController,
  useId,
  Toaster,
  Toast,
  ToastTitle,
  ToastTrigger,
  Link,
  ToastBody,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  CaseCategoryMap,
  caseContextList,
  checkIfKeyExist,
  formatedDate2,
  getCaseCategoryByCaseType,
} from "../../../../utils";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import CaseDetailDrawer from "../CaseDetailDrawer";
import StandardActionDialog from "../StandardActionDialog";
import apiServices from "../../../../service";
import {
  getLiabilityActionDetailById,
  getLiabilityActionsListItems,
  getLiabilityDetailById,
} from "../../../../redux/Case/liabilitySlice";
import { useToastMessage } from "../../../../components/ToastMessage/ToastMessage";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function LiabilityActions({ tab }: { tab: string }) {
  const navigate = useNavigate();
  const styles = useStyles();
  const { showToast, toasterId } = useToastMessage();
  const { clientId, action, caseId, subItemId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const liabilities = useSelector((state: RootState) => state.liabilities);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  const [caseType, setCaseType] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  const [loadStandardActionPopup, setLoadStandardActionPopup] = useState(false);
  const [loadStandardActionDate, setLoadStandardActionDate] = useState(
    new Date()
  );
  const [selectedLoadStandardActions, setSelectedLoadStandardActions] =
    useState<any>([]);
  const [actionLoading, setActionLoading] = useState(false);
  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getLiabilityDetailById({ id: caseId, customerId: clientId }));
      dispatch(
        getLiabilityActionsListItems({ id: caseId, customerId: clientId })
      );
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (liabilities?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(liabilities.activeItem.case_type));
    }
  }, [liabilities?.activeItem]);

  useEffect(() => {
    if (
      action &&
      action != "add" &&
      liabilities?.actions.listItems.length > 0
    ) {
      const actionId =
        subItemId || liabilities.actions.listItems[0]?.business_id;
      navigate(
        `/clients/${clientId}/liabilities/${caseId}/actions/${actionId}/${tab}/${
          action || "view"
        }`
      );
    }
  }, [
    clientId,
    caseId,
    subItemId,
    tab,
    action,
    liabilities.actions.listItems,
    navigate,
  ]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getLiabilityActionDetailById(subItemId));
    }
  }, [subItemId, dispatch]);
  const loadActionSelected = (action: any) => {
    let act: any = [...selectedLoadStandardActions];

    const index = act.indexOf(action);
    if (index !== -1) {
      act.splice(index, 1);
    } else {
      act.push(action);
    }

    // setSelectedActions(act);
    setSelectedLoadStandardActions(act);
  };
  const handleStandardActions = (action: boolean) => {
    setLoadStandardActionPopup(action);
  };

  const addLoadStandardActions = () => {
    if (selectedLoadStandardActions.length > 0) {
      let case_type_value = caseType;

      let contextVal =
        caseContextList["liabilities"][case_type_value]["action"];

      if (formSettingsObj && formSettingsObj.data) {
        let configObj: any = checkIfKeyExist(
          formSettingsObj.data.formConfig,
          contextVal
        );
        if (
          configObj &&
          configObj.renderForm &&
          configObj.renderForm?.reference_id
        ) {
          let actReq: any = {};
          actReq[configObj.renderForm?.reference_id] = {
            customer_id: liabilities?.activeItem.customer_id
              ? liabilities?.activeItem.customer_id
              : clientId,
            case_id: caseId,
            effective_date: formatedDate2(loadStandardActionDate),
            tracking_case_actions: [],
          };
          let selAct: any = [];
          selectedLoadStandardActions.map((v: any) => {
            selAct.push({
              tracking_id: v,
            });
          });
          actReq[configObj.renderForm?.reference_id]["tracking_case_actions"] =
            selAct;

          const businessCategory =
            CaseCategoryMap[configObj.renderForm?.reference_id] ?? 0;

          actReq[configObj.renderForm?.reference_id]["business_category"] =
            businessCategory;

          setActionLoading(true);
          apiServices.tackingActions
            .addStandardActions(actReq)
            .then((response) => {
              setActionLoading(false);

              if (!response.data.isError) {
                setTimeout(() => {
                  setSelectedLoadStandardActions([]);
                  handleStandardActions(false);
                  setLoadStandardActionDate(new Date());
                  showToast(response.data.message, "", "success");
                  if (clientId && caseId) {
                    dispatch(
                      getLiabilityActionsListItems({
                        id: caseId,
                        customerId: clientId,
                      })
                    );
                  }
                }, 1000);
              } else if (
                response.data.isError &&
                response.data.error &&
                Object.keys(response.data.error).length
              ) {
                const errorMessage = Array.isArray(response.data.error.message)
                  ? response.data.error.message.join(", ")
                  : response.data.error.message;
                showToast(errorMessage, "", "error");
              } else {
                showToast("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setActionLoading(false);
              showToast(err.message, "", "error");
              // console.log(err);
            });
        }
      }
    }
  };
  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <CaseNavList
        subItem="actions"
        section="liabilities"
        tab={tab}
        caseType={caseType}
        caseDetail={liabilities?.actions.activeItem}
        handleDetailPopup={handleDetailPopup}
        handleStandardActions={handleStandardActions}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="liabilities"
          caseType={caseType}
          caseDetail={liabilities?.actions.activeItem}
          caseItemList={liabilities?.actions.listItems}
          tab={tab}
          subItem="actions"
          notify={showToast}
        />
        <div>
          {liabilities?.actions.listLoading ? (
            <ContentLoader />
          ) : (
            <>
              {liabilities?.actions.listItems.length > 0 && (
                <CaseList
                  module="actions"
                  data={liabilities?.actions.listItems}
                  activeItem={liabilities?.actions.activeItem}
                  section="liabilities"
                  activeTab={tab}
                />
              )}
              {!action && liabilities?.actions.listItems.length === 0 && (
                <div className={styles.emptyState}>
                  <Label size="large" style={{ color: "red" }}>
                    There are no active records - click Add New to create a new
                    record
                  </Label>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={liabilities?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <StandardActionDialog
        isOpen={loadStandardActionPopup}
        onClose={() => setLoadStandardActionPopup(false)}
        loadStandardActionDate={loadStandardActionDate}
        setLoadStandardActionDate={setLoadStandardActionDate}
        selectedLoadStandardActions={selectedLoadStandardActions}
        setSelectedLoadStandardActions={setSelectedLoadStandardActions}
        loadActionSelected={loadActionSelected}
        addLoadStandardActions={addLoadStandardActions}
        actionLoading={actionLoading}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default LiabilityActions;
