import {
  Link,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";

export const useToastMessage = () => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const showToast = (
    title: string,
    message: string,
    type: "success" | "error" | "warning" = "success"
  ) => {
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title}
        </ToastTitle>
        <ToastBody>{message}</ToastBody>
      </Toast>,
      { intent: type }
    );
  };

  return { showToast, toasterId };
};
