import {
  Button,
  Input,
  Link,
  Select,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  useToastController,
} from "@fluentui/react-components";
import React, { ChangeEvent, useEffect, useId, useState } from "react";
import TextEditor from "../../../components/TextEditor";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import apiServices from "../../../service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import OverlayLoader from "../../../components/OverlayLoader";
import { setlistItems } from "../../../redux/Commission/commissionSlice";
import { getReceiptStatus } from "../../../utils";
import ASCButton from "../../../components/Button";

const CommissionForm = (props: any) => {
  const selectId = useId();
  const toasterId = useId();
  const data: any = useSelector(
    (state: RootState) => state.commission.filteredData
  );
  const [formField, setFormField] = useState(data);
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };
  const { dispatchToast } = useToastController(toasterId);
  const handleSubmit = (values: any) => {};
  const dispatch: AppDispatch = useDispatch();
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setFormField({ ...formField, [e.target.name]: e.target.value });
  }
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";

      fieldLbl =
        "commission_type:MasterDatawithoutNewEntry,payment_source:FixedList,frequency:FixedList,true_false:FixedList";

      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  useEffect(() => {
    getSelectionListOptions();
  }, []);
  const updateCommissionData = async () => {
    setLoading(true);

    const caseTypeMap: Record<string, string> = {
      Mortgages: "liabilities_mortgages_payment_commission",
      "Unit-linked": "asset_investment_payment_commission",
      "Loans/Lease/HP": "liabilities_loan_hire_purchase_payments_commission",
      General: "policies_general_payments_commission",
      "Life Assurance": "policies_life_assurance_payments_commission",
      "Pensions'": "policies_pensions_payments_commission",
      Investments: "policies_investments_payments_commission",
      "Savings Plans": "policies_savings_plans_payments_commission",
      "Income Protection": "policies_income_protection_payments_commission",
      "Health Assurance": "policies_health_assurance_commission",
    };

    const commissionKey = caseTypeMap[formField.case_type];

    if (!commissionKey) {
      setLoading(false);
      notify("Invalid case type", "error");
      return;
    }

    const newData = {
      [commissionKey]: {
        customer_id: formField.customer_id,
        case_id: formField.case_id,
        payment_id: formField.payment_id,
        amount_to_company: formField.amount_to_company,
        commission_type: formField.commission_type,
        end_date: formField.end_date,
        frequency: formField.frequency,
        nb_register: formField.nb_register,
        note_text: formField.note_text,
        payment_count: formField.payment_count,
        payment_source: formField.payment_source,
        start_date: formField.start_date,
        status: formField.status,
      },
    };

    try {
      const response = await apiServices.commission.updateCommission(
        formField.commission_id,
        newData
      );

      if (response?.data && response.data.isError === false) {
        props.getReceiptlistDta();
        notify(response.data.message, "success");

        setTimeout(() => {
          setLoading(false);
          props.setReceiptEditpopup(false);
        }, 3000);
      } else {
        notify("Something went wrong", "error");
        setLoading(false);
      }
    } catch (error) {
      notify("Something went wrong", "error");
      setLoading(false);
    }
  };

  return (
    <div>
      <OverlayLoader isLoading={loading} />
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />
      <div className="commission_action_main">
        <div className="settings-table-content-label">Status</div>
        <span>{getReceiptStatus(formField.commission_status)}</span>
        <div className="settings-table-content-label">Commission type</div>
        <Select
          id={selectId}
          name={"commission_type"}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              commission_type: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((option: any) => option.field === "commission_type")
            ?.options.map((opt: { value: string; label: string }) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Source of Payment</div>
        <Select
          id={selectId}
          name={"payment_source"}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              payment_source: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((option: any) => option.field === "payment_source")
            ?.options.map((opt: { value: string; label: string }) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Amount to Company</div>
        <Input
          onChange={onChange}
          name={"amount_to_company"}
          disabled={formField.commission_status !== 0 ? true : false}
          value={formField.amount_to_company}
        />
        <div className="settings-table-content-label">Frequency</div>
        <Select
          id={selectId}
          name="frequency"
          disabled={formField.commission_status !== 0 ? true : false}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              frequency: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((item: any) => item.field === "frequency")
            ?.options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">
          Commission Start Date
        </div>
        <DatePicker
          as="input"
          className=""
          placeholder=""
          allowTextInput
          disabled={formField.commission_status !== 0 ? true : false}
          formatDate={onFormatDate}
          onChange={onChange}
          name={"start_date"}
          value={formField.start_date ? new Date(formField.start_date) : null}
          onSelectDate={(date) => {
            setFormField((prevState: any) => ({
              ...prevState,
              start_date: date,
            }));
          }}
        />
        <div className="settings-table-content-label">End Date</div>
        <DatePicker
          as="input"
          className=""
          placeholder=""
          allowTextInput
          disabled={formField.commission_status !== 0 ? true : false}
          formatDate={onFormatDate}
          onChange={onChange}
          name={"end_date"}
          value={formField.end_date ? new Date(formField.end_date) : null}
          onSelectDate={(date) => {
            setFormField((prevState: any) => ({
              ...prevState,
              end_date: date,
            }));
          }}
        />
        <div className="settings-table-content-label">Number of Payment</div>
        <Input
          onChange={onChange}
          name={"payment_count"}
          disabled={formField.commission_status !== 0 ? true : false}
          value={formField.payment_count}
        />
        <div className="settings-table-content-label">
          Show AMT on NB Register
        </div>
        <Select
          id={selectId}
          name="nb_register"
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              nb_register: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((item: any) => item.field === "true_false")
            ?.options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Note</div>
        <TextEditor
          inputData={formField.note_text}
          onChange={(text: string) => {
            setFormField({ ...formField, note_text: text });
          }}
          name={"note_text"}
        />
      </div>
      <div className="button-end-popup">
        <Button
          shape="rounded"
          onClick={() => {
            props.setStopReceiptpopup(true);
          }}
          disabled={formField.commission_status !== 1 ? true : false}
        >
          Stop Receipts
        </Button>
        <Button
          shape="rounded"
          className="asc-button-primary"
          appearance="primary"
          type="submit"
          size="medium"
          style={{ marginRight: 6 }}
          onClick={() => {
            updateCommissionData();
          }}
        >
          Save
        </Button>
        <Button
          shape="rounded"
          appearance="secondary"
          type="submit"
          size="medium"
          onClick={() => props.setReceiptEditpopup(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CommissionForm;
