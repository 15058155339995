import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import ASCButton from "../../../components/Button";
import apiServices from "../../../service";

const AssignCase = (props: any) => {
  const[caselist,setCaseList]=useState([]);
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});



  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "CaseType",
      header: "Case Type",
    },
    {
      accessorKey: "Reference",
      header: "Reference",
    },
    {
      accessorKey: "Provider",
      header: "Provider",
    },
  ];

  const getCaseDetails = async () => {
    try {
      const response = await apiServices.commissionModule.fees_Changes.getCaseList(
        "16eb6267-c501-4ad6-99d4-807d35c17b48"
      );
  
      if (response.data&&response.data.data.length>0) {
        const resultData = response.data.data.map((object: any) => {
        })

        setCaseList([]);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching case details:", error);
    }
  };
  
  useEffect(() => {
    getCaseDetails();
  }, [])
  console.log(caselist)

  return (
    <Dialog open={props.iscase}>
      <div>
        <DialogSurface
          style={{
            height: "400px",
            width: "500px",
            maxHeight: "90vh",
            maxWidth: "90vw",
            overflow: "auto", // Ensure scrolling if content exceeds height/width
          }}
        >
          <DialogBody>
            <DialogTitle>Assign Case</DialogTitle>
            <DialogContent>
              <MaterialReactTable
                columns={columns}
                data={caselist}
                enablePagination={false}
                enableStickyHeader
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableDensityToggle={false}
                enableRowSelection={true}
                enableSelectAll={false}
                enableColumnActions={false}
                enableMultiRowSelection={false}
                enableRowActions={false}
                enableFullScreenToggle
                positionActionsColumn="last"
                positionToolbarAlertBanner="none"
                muiTableBodyRowProps={({ row }: any) => ({
                  onClick: () =>
                    setSelectedRow((prev: any) => ({
                      [row.id]: !prev[row.id],
                    })),
                  selected: selectedRow[row.id],
                  sx: {
                    cursor: "pointer",
                    backgroundColor: selectedRow[row.id]
                      ? "#f1ebea"
                      : "transparent",
                    "& > *": {
                      backgroundColor: selectedRow[row.id]
                        ? "#f1ebea"
                        : "transparent",
                    },
                  },
                })}
              />
            </DialogContent>
            <DialogActions>
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  props.setisCaseType(false);
                }}
                label="Clear"
              />
              <ASCButton
                shape="rounded"
                appearance="primary"
                className="asc-button-primary"
                label="Assign"
              />
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  props.setisCase(false);
                }}
                label="Close"
              />
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </div>
    </Dialog>
  );
};

export default AssignCase;
