import React from "react";
import "./style.css";

interface BreadCrumbProps {
  path: string[];
  navigateTo: (path: string[], children: [], isbreadcrumb: boolean) => void;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ path, navigateTo }) => {
  return (
    <div className="document_breadcrumb">
      {path.map((segment, index) => (
        <span
          key={index}
          onClick={
            () =>
              index < path.length - 1
                ? navigateTo(path.slice(0, index + 1), [], true)
                : null // Clicking the last item does nothing
          }
          className={index === path.length - 1 ? "breadcrumb_active" : ""}
        >
          {segment} {index < path.length - 1 && " / "}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumb;
