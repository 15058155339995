// src/redux/userPreferencesSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../service/apiService";
import { getUserId } from "../utils";
import { RootState } from "./store";
import { MRT_ColumnDef } from "material-react-table";
import defaultTableData from "../shared/tableConfig.json";

const API_BASE_URL = process.env.REACT_APP_BASE_URL + "user/profile";

// Fetch User Preferences
export const fetchUserPreferences = createAsyncThunk<any>(
  "userPreferences/fetchUserPreferences",
  async (_, { rejectWithValue }) => {
    const userId = getUserId();
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/${userId}`);
      return response.data.data.user_settings || defaultTableData;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Save User Preferences
export const savePreferences = createAsyncThunk<any, any>(
  "userPreferences/savePreferences",
  async (userSettings: any, { dispatch, rejectWithValue }) => {
    const userId = getUserId();
    try {
      const response = await axiosInstance.patch(`${API_BASE_URL}/${userId}`, {
        user_settings: userSettings,
      });

      return { message: response.data.message };
    } catch (error: any) {
      console.error("Error saving preferences:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

interface UserSettings {
  tables: {
    defaultConfig: any;
    contextSpecific: {
      [tableName: string]: {
        columns: MRT_ColumnDef<any>[];
        extends?: string;
        initialState?: {
          columnOrder?: string[];
          columnVisibility?: Record<string, boolean>;
          [key: string]: any;
        };
        enablePagination?: boolean;
      };
    };
  };
  rowActions?: any;
  columnEnhancements?: any;
}

interface UserPreferencesState {
  preferences: UserSettings;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UserPreferencesState = {
  preferences: {
    tables: {
      defaultConfig: {},
      contextSpecific: {},
    },
  },
  status: "idle",
  error: null,
};

const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState,
  reducers: {
    setTableColumnOrder: (state, action) => {
      const { tableName, columnOrder } = action.payload;
      if (!state.preferences.tables.contextSpecific[tableName]) {
        state.preferences.tables.contextSpecific[tableName] ??= {
          columns: [],
          initialState: {},
        };
      }
      state.preferences.tables.contextSpecific[tableName].initialState = {
        ...state.preferences.tables.contextSpecific[tableName].initialState,
        columnOrder,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.preferences = {
          ...initialState.preferences, // Preserve default structure
          ...action.payload,
          tables: {
            ...initialState.preferences.tables,
            ...(action.payload?.tables || {}),
            contextSpecific: {
              ...initialState.preferences.tables.contextSpecific,
              ...(action.payload?.tables?.contextSpecific || {}),
            },
          },
        };
      })
      .addCase(fetchUserPreferences.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(savePreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(savePreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export const { setTableColumnOrder } = userPreferencesSlice.actions;

// Selectors with safe defaults
export const selectTableColumns = (tableName: string) => (state: RootState) =>
  state.userPreferences?.preferences?.tables?.contextSpecific?.[tableName]
    ?.columns || [];

export const selectTableColumnOrder =
  (tableName: string) => (state: RootState) =>
    state.userPreferences?.preferences?.tables?.contextSpecific?.[tableName]
      ?.initialState?.columnOrder || [];

export const selectDefaultConfig = (state: RootState) =>
  state.userPreferences?.preferences?.tables?.defaultConfig || {};

export const selectPreferenceStatus = (state: RootState) =>
  state.userPreferences?.status || "idle";

export default userPreferencesSlice.reducer;
