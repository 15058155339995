import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ClientList from "../pages/client/List";
import ClientLayout from "../layout/index";
import ActionList from "../pages/Actionlist/List";
import Report from "../pages/report";
import SystemManagerLayout from "../pages/systemManager/SystemManagerLayout";
import MasterSettings from "../pages/systemManager/masterSettings";
import { useEffect, useState } from "react";
import ManageUser from "../pages/systemManager/manageUser";
import ProfileSettings from "../pages/ProfileSettings";
import { checkUserPermission } from "../utils";
import { UserPermissionsList } from "../config";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Commission from "../pages/systemManager/commission";
import Utilities from "../pages/utilities/utilities";
import Subscription from "../pages/systemManager/Subscription";
import MasterSttingsPage from "../pages/systemManager/masterSettings/MasterSttingsPage";
import Integrations from "../pages/systemManager/Integrations";
import ContractEnquiry from "../pages/systemManager/ContractEnquiry";
import Templates from "../pages/systemManager/templateEngine/LetterTemplates";
import Providers from "../pages/systemManager/Providers/Providers";
import CompanyDetails from "../pages/systemManager/CompanyDetails/CompanyDetails";
import ReportMain from "../pages/report/ReportMain";
import ReportDemo from "../pages/New_report/Reports";
import ProfileRouting from "../pages/ProfileSettings/profileRouting";
import {
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import ClientDetailPage from "../pages/client/ClientDetailPage";
import SectionHandler from "../pages/client/SectionHandler";

import CaseHomePage from "../pages/client/case/CaseHomePage";
import CaseForm from "../pages/client/case/CaseForm";
import CaseDetailPage from "../pages/client/case/CaseDetailPage";

import CaseSubItemDetailPage from "../pages/client/case/CaseSubItemDetailPage";
import PartnerSectionHandler from "../pages/client/PartnerSectionHandler";
import ClientSubMenuItem from "../pages/client/ClientSubMenuItem";

import Dependant from "../pages/client/Dependant";
import ClientAddForm from "../pages/client/ClientAddForm";
import { Controlled } from "../pages/SamplePage";
import CommissionLayout from "../pages/Commission/CommissionLayout/Layout";
import List from "../pages/Commission/CommissionReceipts/List";
import DistributionDatalist from "../pages/Commission/CommissionDistributon/DistributionDatalist";
import EmailTemplates from "../pages/systemManager/templateEngine/EmailTemplates";
import ListDetils from "../pages/Commission/OtherFeesCharges/ListDetils";
import UtilitiesLayout from "../pages/utilities/UtilitiesLayout";
import TemplateDropdown from "../pages/utilities/EmailNotification/TemplateDropdown";
import ReceiptList from "../pages/Commission/CommissionReceipts/ReceiptList";
import FeeschargesList from "../pages/Commission/OtherFeesCharges/FeeschargesList";
import LetterGeneration from "../pages/utilities/EmailNotification/LetterGeneration";
import PartnerSubMenuItem from "../pages/client/PartnerSubMenuItem";
import AssetHomePage from "../pages/client/case/Asset/AssetHomePage";
import AssetDetailPage from "../pages/client/case/Asset/AssetDetailPage";
import AssetFunds from "../pages/client/case/Asset/AssetFunds";
import AssetPayments from "../pages/client/case/Asset/AssetPayments";
import AssetPaymentCommissions from "../pages/client/case/Asset/AssetPaymentCommissions";
import AssetWithdrawals from "../pages/client/case/Asset/AssetWithdrawals";
import AssetActions from "../pages/client/case/Asset/AssetActions";
import AssetValuations from "../pages/client/case/Asset/AssetValuations";
import LiabilityHomePage from "../pages/client/case/Liability/LiabilityHomePage";
import LiabilityDetailPage from "../pages/client/case/Liability/LiabilityDetailPage";
import LiabilityPayments from "../pages/client/case/Liability/LiabilityPayments";
import LiabilityPaymentCommission from "../pages/client/case/Liability/LiabilityPaymentCommissions";
import LiabilityActions from "../pages/client/case/Liability/LiabilityActions";
import AssetCommissionDistribution from "../pages/client/case/Asset/AssetCommissionDistribution";
import LiabilityCommissionDistribution from "../pages/client/case/Liability/LiabilityCommissionDistribution";
import PolicyHomePage from "../pages/client/case/Policy/PolicyHomePage";
import PolicyDetailPage from "../pages/client/case/Policy/PolicyDetailPage";
import PolicyFunds from "../pages/client/case/Policy/PolicyFunds";
import PolicyPayments from "../pages/client/case/Policy/PolicyPayments";
import PolicyPaymentCommissions from "../pages/client/case/Policy/PolicyPaymentCommissions";
import PolicyWithdrawals from "../pages/client/case/Policy/PolicyWithdrawals";
import PolicyActions from "../pages/client/case/Policy/PolicyActions";
import PolicyValuations from "../pages/client/case/Policy/PolicyValuations";
import PolicyCommissionDistribution from "../pages/client/case/Policy/PolicyCommissionDistribution";

export const PrivateRoutes = () => {
  const [mode, setMode] = useState("view");

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const sideMenuGlobal = {
    actionList: [],
    clients: [],
    reports: [],
    utilities: [],
    systemManager: [
      { label: "Subscription" },
      { label: "Manage Users", link: "/system-manager/manage-user" },
      { label: "Commission", link: "/system-manager/Commission" },
    ],
  };

  if (
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
    )
  ) {
    const manageUsersIndex = sideMenuGlobal.systemManager.findIndex(
      (item) => item.label === "Manage Users"
    );
    sideMenuGlobal.systemManager.splice(manageUsersIndex + 1, 0, {
      label: "Master Settings",
      link: "/system-manager",
    });
  }

  const [tabs, setTabs] = useState("");
  const [activeMenu, setActiveMenu] = useState("");
  const [isMaster, setIsMaster] = useState(false);
  useEffect(() => {
    if (
      checkUserPermission(
        loggedUser,
        UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
      )
    ) {
      setIsMaster(true);
      setActiveMenu("Master Settings");
    } else {
      setActiveMenu("Manage Users");
      setIsMaster(false);
    }
  }, [loggedUser]);
  const sections = [
    "dependant",
    "income",
    "outgoings",
    "address-book",
    "fact-find-notes",
    "fees-charges",
    "documents",
    "vulnerability",
    "employment",
    "attitude-to-risk",
    "identity",
    "objectives",
    "service-type",
    "user-defined",
  ];
  const partnerSections = [
    "employment",
    "attitude-to-risk",
    "identity",
    "objectives",
    "service-type",
    "vulnerability",
  ];
  const contactsRoutes = [
    {
      path: "client-action",
      section: "client-action",
    },
    {
      path: "notes",
      section: "notes",
    },
    {
      path: "time-allocation",
      section: "time-allocation",
    },
  ];
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClientLayout
            tabs={tabs}
            sideMenuGlobal={sideMenuGlobal}
            activeMenu={activeMenu}
          />
        }
      >
        <Route index element={<ClientList setTabs={setTabs} />} />
        <Route path="/clients" element={<ClientList setTabs={setTabs} />} />
        <Route path="/clients/add" element={<ClientAddForm />} />
        <Route path="clients/:clientId" element={<ClientDetailPage />}>
          <Route index element={<SectionHandler />} />
          <Route path=":section" element={<SectionHandler />} />
          <Route path=":section/:action" element={<SectionHandler />} />

          {sections.map((section) => (
            <React.Fragment key={section}>
              <Route
                path={section}
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path={`${section}/:action`}
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path={`${section}/:selectedItemId`}
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path={`${section}/:selectedItemId/:action`}
                element={<ClientSubMenuItem section={section} />}
              />
            </React.Fragment>
          ))}
          <Route path="contacts" element={<ClientSubMenuItem />} />
          {contactsRoutes.map(({ path, section }) => (
            <Route
              key={path}
              path={`contacts/${path}`}
              element={<ClientSubMenuItem section={section} />}
            >
              <Route
                path=":action"
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path=":selectedItemId/:action"
                element={<ClientSubMenuItem section={section} />}
              />
            </Route>
          ))}
          <Route path="partner" element={<PartnerSectionHandler />} />
          <Route path="partner/:section" element={<PartnerSectionHandler />} />
          <Route
            path="partner/:section/:action"
            element={<PartnerSectionHandler />}
          />
          {partnerSections.map((section) => (
            <React.Fragment key={`partner/${section}`}>
              <Route
                path={`partner/${section}`}
                element={<PartnerSubMenuItem section={section} />}
              />
              <Route
                path={`partner/${section}/:action`}
                element={<PartnerSubMenuItem section={section} />}
              />
              <Route
                path={`partner/${section}/:selectedItemId`}
                element={<PartnerSubMenuItem section={section} />}
              />
              <Route
                path={`partner/${section}/:selectedItemId/:action`}
                element={<PartnerSubMenuItem section={section} />}
              />
            </React.Fragment>
          ))}
          {/* <Route path="assets" element={<CaseHomePage section="assets" />} /> */}
          <Route path="assets" element={<AssetHomePage />} />
          <Route
            path="assets/:action"
            element={<AssetDetailPage tab="asset" />}
          />

          <Route
            path="assets/:action"
            element={<AssetDetailPage tab="asset" />}
          />
          <Route
            path="assets/:caseId/asset"
            element={<AssetDetailPage tab="asset" />}
          />
          <Route
            path="assets/:caseId/asset/:action"
            element={<AssetDetailPage tab="asset" />}
          />
          <Route
            path="assets/:caseId/review"
            element={<AssetDetailPage tab="review" />}
          />
          <Route
            path="assets/:caseId/review/:action"
            element={<AssetDetailPage tab="review" />}
          />
          <Route
            path="assets/:caseId/funds"
            element={<AssetFunds tab="fund" />}
          />
          <Route
            path="assets/:caseId/funds/:action"
            element={<AssetFunds tab="fund" />}
          />
          <Route
            path="assets/:caseId/funds/:subItemId/fund/:action"
            element={<AssetFunds tab="fund" />}
          />

          <Route
            path="assets/:caseId/payments"
            element={<AssetPayments tab="payment" />}
          />
          <Route
            path="assets/:caseId/payments/:action"
            element={<AssetPayments tab="payment" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/payment/:action"
            element={<AssetPayments tab="payment" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/compliance/:action"
            element={<AssetPayments tab="compliance" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission"
            element={<AssetPaymentCommissions tab="commission" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:action"
            element={<AssetPaymentCommissions tab="commission" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/commission/:action"
            element={<AssetPaymentCommissions tab="commission" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/distribution"
            element={<AssetCommissionDistribution tab="distribution" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:action"
            element={<AssetCommissionDistribution tab="distribution" />}
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:distributionId/distribution/:action"
            element={<AssetCommissionDistribution tab="distribution" />}
          />
          <Route
            path="assets/:caseId/withdrawals"
            element={<AssetWithdrawals tab="withdrawal" />}
          />
          <Route
            path="assets/:caseId/withdrawals/:action"
            element={<AssetWithdrawals tab="withdrawal" />}
          />
          <Route
            path="assets/:caseId/withdrawals/:subItemId/withdrawal/:action"
            element={<AssetWithdrawals tab="withdrawal" />}
          />
          <Route
            path="assets/:caseId/actions"
            element={<AssetActions tab="action" />}
          />
          <Route
            path="assets/:caseId/actions/:action"
            element={<AssetActions tab="action" />}
          />
          <Route
            path="assets/:caseId/actions/:subItemId/action/:action"
            element={<AssetActions tab="action" />}
          />
          <Route
            path="assets/:caseId/valuations"
            element={<AssetValuations tab="valuation" />}
          />
          <Route
            path="assets/:caseId/valuations/:action"
            element={<AssetValuations tab="valuation" />}
          />
          <Route
            path="assets/:caseId/valuations/:subItemId/valuation/:action"
            element={<AssetValuations tab="valuation" />}
          />
          <Route path="liabilities" element={<LiabilityHomePage />} />

          <Route
            path="liabilities/:action"
            element={<LiabilityDetailPage tab="liability" />}
          />
          <Route
            path="liabilities/:caseId/liability/:action"
            element={<LiabilityDetailPage tab="liability" />}
          />
          <Route
            path="liabilities/:caseId/review/:action"
            element={<LiabilityDetailPage tab="review" />}
          />

          <Route
            path="liabilities/:caseId/payments"
            element={<LiabilityPayments tab="payment" />}
          />
          <Route
            path="liabilities/:caseId/payments/:action"
            element={<LiabilityPayments tab="payment" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/payment/:action"
            element={<LiabilityPayments tab="payment" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/compliance/:action"
            element={<LiabilityPayments tab="compliance" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission"
            element={<LiabilityPaymentCommission tab="commission" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:action"
            element={<LiabilityPaymentCommission tab="commission" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:subSubItemId/commission/:action"
            element={<LiabilityPaymentCommission tab="commission" />}
          />

          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:subSubItemId/distribution"
            element={<LiabilityCommissionDistribution tab="distribution" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:action"
            element={<LiabilityCommissionDistribution tab="distribution" />}
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:distributionId/distribution/:action"
            element={<LiabilityCommissionDistribution tab="distribution" />}
          />

          <Route
            path="liabilities/:caseId/actions"
            element={<LiabilityActions tab="action" />}
          />
          <Route
            path="liabilities/:caseId/actions/:action"
            element={<LiabilityActions tab="action" />}
          />
          <Route
            path="liabilities/:caseId/actions/:subItemId/action/:action"
            element={<LiabilityActions tab="action" />}
          />

          <Route path="policies" element={<PolicyHomePage />} />

          <Route
            path="policies/:action"
            element={<PolicyDetailPage tab="policy" />}
          />
          <Route
            path="policies/:caseId/policy/:action"
            element={<PolicyDetailPage tab="policy" />}
          />
          <Route
            path="policies/:caseId/review/:action"
            element={<PolicyDetailPage tab="review" />}
          />
          <Route
            path="policies/:caseId/funds"
            element={<PolicyFunds tab="fund" />}
          />
          <Route
            path="policies/:caseId/funds/:action"
            element={<PolicyFunds tab="fund" />}
          />
          <Route
            path="policies/:caseId/funds/:subItemId/fund/:action"
            element={<PolicyFunds tab="fund" />}
          />

          <Route
            path="policies/:caseId/payments"
            element={<PolicyPayments tab="payment" />}
          />
          <Route
            path="policies/:caseId/payments/:action"
            element={<PolicyPayments tab="payment" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/payment/:action"
            element={<PolicyPayments tab="payment" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/compliance/:action"
            element={<PolicyPayments tab="compliance" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission"
            element={<PolicyPaymentCommissions tab="commission" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:action"
            element={<PolicyPaymentCommissions tab="commission" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:subSubItemId/commission/:action"
            element={<PolicyPaymentCommissions tab="commission" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:subSubItemId/distribution"
            element={<PolicyCommissionDistribution tab="distribution" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:action"
            element={<PolicyCommissionDistribution tab="distribution" />}
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:distributionId/distribution/:action"
            element={<PolicyCommissionDistribution tab="distribution" />}
          />
          <Route
            path="policies/:caseId/withdrawals"
            element={<PolicyWithdrawals tab="withdrawal" />}
          />
          <Route
            path="policies/:caseId/withdrawals/:action"
            element={<PolicyWithdrawals tab="withdrawal" />}
          />
          <Route
            path="policies/:caseId/withdrawals/:subItemId/withdrawal/:action"
            element={<PolicyWithdrawals tab="withdrawal" />}
          />
          <Route
            path="policies/:caseId/actions"
            element={<PolicyActions tab="action" />}
          />
          <Route
            path="policies/:caseId/actions/:action"
            element={<PolicyActions tab="action" />}
          />
          <Route
            path="policies/:caseId/actions/:subItemId/action/:action"
            element={<PolicyActions tab="action" />}
          />
          <Route
            path="policies/:caseId/valuations"
            element={<PolicyValuations tab="valuation" />}
          />
          <Route
            path="policies/:caseId/valuations/:action"
            element={<PolicyValuations tab="valuation" />}
          />
          <Route
            path="policies/:caseId/valuations/:subItemId/valuation/:action"
            element={<PolicyValuations tab="valuation" />}
          />
        </Route>
        <Route path="/action-list" element={<ActionList setTabs={setTabs} />} />

        {/* <Route path="/report" element={<Report setTabs={setTabs} />} /> */}
        {/* <Route path="/reportdemo" element={<ReportDemo />} /> */}
        <Route path="/report" element={<ReportDemo />} />

        <Route
          path="/commission"
          element={<CommissionLayout setTabs={setTabs} tabs={tabs} />}
        >
          <Route path="" element={<ReceiptList />} />
          {/* <Route
            path="/commission"
            element={<ClientList module="commission" />}
          /> */}
          <Route path="/commission/receipting" element={<ReceiptList />} />
          <Route
            path="/commission/distribution"
            element={<DistributionDatalist />}
          />
          <Route
            path="/commission/other-fees-charges"
            element={<FeeschargesList />}
          />
        </Route>
        <Route
          path="/system-manager"
          element={<SystemManagerLayout setTabs={setTabs} />}
        >
          <Route path="" element={<CompanyDetails />} />
          <Route path="master-settings" element={<MasterSttingsPage />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="company-details" element={<CompanyDetails />} />
          <Route path="contract-enquiry" element={<ContractEnquiry />} />
          <Route path="providers" element={<Providers />} />
          <Route path="/system-manager/templates" element={<Templates />} />
          <Route path="templates/:section" element={<Templates />} />
          <Route path="template/:section" element={<EmailTemplates />} />
          <Route
            path="/system-manager/customisations"
            element={<MasterSttingsPage />}
          />
          <Route
            path="customisations/:section"
            element={<MasterSttingsPage />}
          />
          <Route
            path="manage-user"
            element={<ManageUser setActiveMenu={setActiveMenu} />}
          />
          <Route path="commission" element={<Commission />} />
          <Route path="commission/:section" element={<Commission />} />
        </Route>

        <Route
          path="/profile"
          element={
            <ProfileSettings
              setActiveMenu={setActiveMenu}
              mode={mode}
              setMode={setMode}
            />
          }
        >
          <Route
            path=":section"
            element={<ProfileRouting mode={mode} setMode={setMode} />}
          />
          <Route index element={<Navigate to="/profile/account" replace />} />
        </Route>
        <Route path="/utilities" element={<UtilitiesLayout />}>
          <Route index element={<TemplateDropdown />} />
          {/* <Route path="report" element={<ReportDemo />} /> */}
          <Route path="email" element={<TemplateDropdown />} />
          <Route path="letter" element={<LetterGeneration />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
