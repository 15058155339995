import {
  Button,
  Checkbox,
  Dropdown,
  Label,
  makeStyles,
  Option,
  OptionOnSelectData,
  SelectionEvents,
} from "@fluentui/react-components";
import React, { useId, useState } from "react";
import ASCButton from "../../components/Button";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    gap: "2px",
    maxWidth: "400px",
  },
  head: {
    fontWeight: "bold",
    fontSize: "15px",
  },
  checkboxLabel: {
    margin: "0px 30px 0px 5px",
  },
  innerWrapper: {
    columnGap: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});
interface SecondFileProps {
  nextToDetails: () => void;
  BackToIntro: () => void;
  CancelSelection: () => void;
  selectedReason: string | undefined;
  checkboxState: { client: boolean; partner: boolean };
  setSelectedReason: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCheckboxState: React.Dispatch<
    React.SetStateAction<{ client: boolean; partner: boolean }>
  >;
}

const SecondFile: React.FC<SecondFileProps> = ({
  nextToDetails,
  BackToIntro,
  CancelSelection,
  selectedReason,
  checkboxState,
  setSelectedReason,
  setCheckboxState,
}) => {
  const dropdownId = useId();
  const styles = useStyles();

  const handleDropdownChange = (
    event: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    setSelectedReason(data.optionValue);
    setCheckboxState({ client: false, partner: false });
  };

  const handleCheckboxChange = (type: "client" | "partner") => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  return (
    <div className={styles.section}>
      <span className={styles.head}>Reason for Splitting</span>
      <p>
        Select the principal reason for splitting these records and choose to
        create new records for the client/partner or both. NOTE: You must choose
        to create at least one new record to proceed
      </p>
      <div className={styles.root}>
        <Dropdown
          size="medium"
          aria-labelledby={dropdownId}
          placeholder="Reason for Splitting"
          onOptionSelect={handleDropdownChange}
        >
          <Option value="1">Separation/Others</Option>
          <Option value="2">Death of Client</Option>
          <Option value="3">Death of Partner</Option>
        </Dropdown>
      </div>
      <br />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {selectedReason === "1" && (
          <>
            <div>
              <Checkbox
                size="medium"
                className={styles.checkboxLabel}
                label="Create New Record for Client"
                checked={checkboxState.client}
                onChange={() => handleCheckboxChange("client")}
              />
            </div>
            <div>
              <Checkbox
                size="medium"
                className={styles.checkboxLabel}
                label="Create New Record for Partner"
                checked={checkboxState.partner}
                onChange={() => handleCheckboxChange("partner")}
              />
            </div>
          </>
        )}
        {selectedReason === "2" && (
          <div>
            <Checkbox
              size="medium"
              className={styles.checkboxLabel}
              label="Create New Record for Partner"
              checked={checkboxState.partner}
              onChange={() => handleCheckboxChange("partner")}
            />
          </div>
        )}
        {selectedReason === "3" && (
          <div>
            <Checkbox
              size="medium"
              className={styles.checkboxLabel}
              label="Create New Record for Client"
              checked={checkboxState.client}
              onChange={() => handleCheckboxChange("client")}
            />
          </div>
        )}
      </div>

      <div className={styles.innerWrapper}>
        <ASCButton
          shape="rounded"
          size="medium"
          onClick={BackToIntro}
          label="Back"
        />
        <ASCButton
          shape="rounded"
          disabled={
            !selectedReason || (!checkboxState.client && !checkboxState.partner)
          }
          onClick={nextToDetails}
          size="medium"
          appearance="primary"
          className="asc-button-primary"
          label="Next"
        />
        <ASCButton
          shape="rounded"
          size="medium"
          onClick={CancelSelection}
          label="Cancel"
        />
      </div>
    </div>
  );
};

export default SecondFile;
