import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  currentSelectedDates: string;
  appliedSelectedDates: string;
  selectedDateValue:any;
}

const initialState: FilterState = {
  currentSelectedDates: 'Custom',
  appliedSelectedDates: 'Custom',
  selectedDateValue:"includeAllDates"
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setCurrentSelectedDates(state, action: PayloadAction<string>) {
      state.currentSelectedDates = action.payload;
    },
    setAppliedSelectedDates(state, action: PayloadAction<string>) {
      state.appliedSelectedDates = action.payload;
    },
    setSelectedDateValue(state, action: PayloadAction<any>) {
      state.selectedDateValue = action.payload;
    },
  },
});

export const { setCurrentSelectedDates, setAppliedSelectedDates,setSelectedDateValue } =
  filterSlice.actions;

export default filterSlice.reducer;
