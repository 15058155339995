import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  Input,
  Label,
  Link,
  Select,
  Toast,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { Edit24Filled, DismissFilled } from "@fluentui/react-icons";
import TextEditor from "../../../components/TextEditor";
import LocateCommissionPopup from "./LocateCommission";
import StopDistribution from "./StopDistribution";
import apiServices from "../../../service";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import ASCButton from "../../../components/Button";

const DistibutionEditForm = (props: any) => {
  const [islocate, setislocate] = useState(false);
  const selectId = useId();
  const toasterId = useId();

  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const { dispatchToast } = useToastController(toasterId);
  const selectedRuleItem = useSelector(
    (state: RootState) => state.commission.selectedRuleItem
  );
  const data: any = useSelector(
    (state: RootState) => state.commission?.filteredData
  );
  const [formField, setFormField] = useState(data);

  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";

      fieldLbl =
        "commission_party_type:FixedList,party_name:MasterDatawithNewEntry";

      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  useEffect(() => {
    getSelectionListOptions();
  }, []);
  const updateDistribution = () => {
    let newData;
    if (formField.category === "liability") {
      newData = {
        customer_id: formField.customer_id,
        case_id: formField.case_id,
        payment_id: formField.payment_id,
      };
      apiServices.commission
        .updateCommission(formField.commission_id, newData)
        .then((response: any) => {
          if (!response.data.isError) {
            notify("Action Updated Successfully", "success");
          } else {
            notify("Something went wrong", "error");
          }
        })
        .catch((error: Error) => {
          notify("Something went wrong", "error");
        });
    }
  };
  return (
    <div>
      <Dialog open={props.editCommissionpopup}>
        <DialogSurface>
          <DialogBody className="dialog_content">
            <DialogTitle className="actionlist_title">
              Commission Distribution
              <DismissFilled
                style={{ fontSize: "20px" }}
                onClick={() => props.setEditCommissionpopup(false)}
              />
            </DialogTitle>
            {/* <DialogContent> */}
            <div className="commission_action_main">
              <div className="settings-table-content-label">Status</div>
              <span>Awaiting Payment 0</span>
              <div className="settings-table-content-label">Current Rule</div>
              <span>Custom</span>
              <div className="settings-table-content-label">Party type</div>
              <Select
                id={selectId}
                name={"commission_party_type"}
                onChange={(e) =>
                  setFormField((prevState: any) => ({
                    ...prevState,
                    commission_party_type: e.target.value,
                  }))
                }
              >
                {selectFieldOptions
                  .find(
                    (option: any) => option.field === "commission_party_type"
                  )
                  ?.options.map((opt: { value: string; label: string }) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
              </Select>
              <div className="settings-table-content-label">Case Type</div>
              <span>Asset(Investment)</span>
              <div className="settings-table-content-label">Party Name</div>
              <Select
                id={selectId}
                name={"party_name"}
                onChange={(e) =>
                  setFormField((prevState: any) => ({
                    ...prevState,
                    party_name: e.target.value,
                  }))
                }
              >
                {selectFieldOptions
                  .find((option: any) => option.field === "party_name")
                  ?.options.map((opt: { value: string; label: string }) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
              </Select>
              <div className="settings-table-content-label"></div>
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  setislocate(true);
                }}
                label="Choose Distribution Rule"
              />
              <div className="settings-table-content-label">Amount</div>
              <Input value={selectedRuleItem?.Amount || ""} disabled />
              <div className="settings-table-content-label">Rule Id</div>
              <Input value={selectedRuleItem?.id || ""} />

              <div className="settings-table-content-label">Note</div>
              <TextEditor
                inputData={formField.note_text}
                onChange={(text: string) => {
                  setFormField({ ...formField, note_text: text });
                }}
                name={"note_text"}
              />
            </div>
            {/* </DialogContent> */}
            <DialogActions>
              <div className="button-end-popup">
                <ASCButton
                  shape="rounded"
                  onClick={() => {
                    props.setStopdistributionpopup(true);
                  }}
                  label="Stop Distribution"
                />
                <Button
                  shape="rounded"
                  className="asc-button-primary"
                  appearance="primary"
                  type="submit"
                  size="medium"
                  style={{ marginRight: 6 }}
                  onClick={() => updateDistribution()}
                >
                  Save
                </Button>
                <Button
                  shape="rounded"
                  appearance="secondary"
                  type="submit"
                  size="medium"
                  onClick={() => props.setEditCommissionpopup(false)}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <StopDistribution stopDistributionpopup={props.stopDistributionpopup} />
      <LocateCommissionPopup islocate={islocate} setislocate={setislocate} />
    </div>
  );
};

export default DistibutionEditForm;
