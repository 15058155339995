import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumbs from "../../layout/breadcrumb/Breadcrumbs";
import Sidebar from "../../layout/sidebar/Sidebar";
function UtilitiesLayout() {
  return (
    <div style={{ display: "flex", height: "calc(100vh - 150px)", gap: 10 }}>
      <aside
        style={{
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Sidebar />
      </aside>
      <main
        style={{ flex: 1, padding: "0 0.6rem", width: "calc(100vw - 230px)" }}
      >
        <Breadcrumbs />
        <Outlet />
      </main>
    </div>
  );
}

export default UtilitiesLayout;
