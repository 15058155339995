import { Button, Label } from "@fluentui/react-components";
import React from "react";
import { ArrowDownloadRegular } from "@fluentui/react-icons";
import OverlayLoader from "../../components/OverlayLoader";
import ASCButton from "../../components/Button";

const ExportSpecification = ({
  MoveToExport,
  BackToConfirmation,
  CancelExport,
  exportToExcel,
  isLoading,
  setLoading,
}: {
  MoveToExport: () => void;
  BackToConfirmation: () => void;
  CancelExport: () => void;
  exportToExcel: () => void;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div>
      {" "}
      <OverlayLoader isLoading={isLoading} />
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ justifyContent: "center" }}>
          <Label>
            Client-Assyst is now ready to export the data. Click the Export
            button to start.
          </Label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ASCButton
            shape="rounded"
            appearance="secondary"
            size="medium"
            icon={<ArrowDownloadRegular />}
            onClick={exportToExcel}
            label="Export"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
          <ASCButton
            shape="rounded"
            appearance="secondary"
            size="medium"
            onClick={BackToConfirmation}
            label="Back"
          />
          <ASCButton
            shape="rounded"
            appearance="secondary"
            size="medium"
            onClick={CancelExport}
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default ExportSpecification;
