import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import React from "react";
import ASCButton from "../Button";

function DeletePopup(props: any) {
  return (
    <Dialog open={props.deletePopup}>
      <DialogSurface>
        <DialogBody>
          <h3>Do you want to delete?</h3>
          <DialogActions>
            <ASCButton
              shape="rounded"
              className="asc-button-primary"
              appearance="primary"
              onClick={() => props.setDeletePopup(false)}
              label="No"
            />
            <ASCButton
              shape="rounded"
              appearance="secondary"
              onClick={() => {
                props.deleteData();
              }}
              icon={props.deleteApiProcessing ? <Spinner size="tiny" /> : null}
              label={props.deleteApiProcessing ? "Processing..." : "Yes"}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default DeletePopup;
