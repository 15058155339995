import {
  Button,
  Input,
  Link,
  Select,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";
import React, { ChangeEvent, useEffect, useState } from "react";
import TextEditor from "../../../components/TextEditor";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import apiServices from "../../../service";
import LocateCommissionPopup from "../CommissionDistributon/LocateCommission";
import StopDistribution from "../CommissionDistributon/StopDistribution";
import ASCButton from "../../../components/Button";
enum TabValues {
  commission = "tab1",
  distribution = "tab2",
}
const DistibutionEditForm = (props: any) => {
  const selectId = useId();
  const toasterId = useId();
  const [islocate, setislocate] = useState(false);
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const { dispatchToast } = useToastController(toasterId);
  const selectedRuleItem = useSelector(
    (state: RootState) => state.commission.selectedRuleItem
  );

  const data: any = useSelector(
    (state: RootState) => state.commission.filteredData
  );
  const [formField, setFormField] = useState(data);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setFormField({ ...formField, [e.target.name]: e.target.value });
  }
  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";

      fieldLbl =
        "commission_party_type:FixedList,party_name:MasterDatawithNewEntry";

      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  useEffect(() => {
    getSelectionListOptions();
  }, []);
  const updateDistribution = () => {
    let newData;
    if (
      formField.case_type === "Mortgages" ||
      formField.case_type === "Unit-linked" ||
      formField.case_type === "Loans/Lease/HP" ||
      formField.case_type === "General" ||
      formField.case_type === "Life Assurance" ||
      formField.case_type === "Pensions'" ||
      formField.case_type === "Investments" ||
      formField.case_type === "Savings Plans" ||
      formField.case_type === "Income Protection" ||
      formField.case_type === "Health Assurance"
    ) {
      newData = {
        customer_id: formField.customer_id,
        case_id: formField.case_id,
        payment_id: formField.payment_id,
        status: formField.status,
        current_rule: formField.current_rule,
        case_type: formField.case_type,
        rule_id: formField.rule_id,
      };
      apiServices.distribution
        .add(newData)
        .then((response: any) => {
          if (!response.data.isError) {
            notify("Action Updated Successfully", "success");
          } else {
            notify("Something went wrong", "error");
          }
        })
        .catch((error: Error) => {
          notify("Something went wrong", "error");
        });
    }
  };
  return (
    <div>
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />
      <Button>Add New</Button>
      <div className="commission_action_main">
        <div className="settings-table-content-label">Status</div>
        <span>Awaiting Payment 0</span>
        <div className="settings-table-content-label">Current Rule</div>
        <span>Custom</span>
        <div className="settings-table-content-label">Party type</div>
        <Select
          id={selectId}
          name={"commission_party_type"}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              commission_party_type: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((option: any) => option.field === "commission_party_type")
            ?.options.map((opt: { value: string; label: string }) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Case Type</div>
        <span>Asset(Investment)</span>
        <div className="settings-table-content-label">Party Name</div>
        <Select
          id={selectId}
          name={"party_name"}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              party_name: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((option: any) => option.field === "party_name")
            ?.options.map((opt: { value: string; label: string }) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label"></div>
        <ASCButton
          shape="rounded"
          appearance="secondary"
          onClick={() => {
            setislocate(true);
          }}
          label="Choose Distribution Rule"
        />
        <div className="settings-table-content-label">Amount</div>
        <Input type="number" value="50.00%" disabled />
        <div className="settings-table-content-label">Rule Id</div>
        <Input value="8fc7fcic-b198-1e44-6496-6579:451d765" />
        <div className="settings-table-content-label">Note</div>
        <TextEditor
          inputData={formField.note_text}
          onChange={(text: string) => {
            setFormField({ ...formField, note_text: text });
          }}
          name={"note_text"}
        />
      </div>
      {/* </DialogContent> */}

      <div className="button-end-popup">
        <ASCButton
          shape="rounded"
          onClick={() => {
            props.setStopdistributionpopup(true);
          }}
          label="Stop Distribution"
        />
        <Button
          shape="rounded"
          className="asc-button-primary"
          appearance="primary"
          type="submit"
          size="medium"
          style={{ marginRight: 6 }}
          onClick={() => {
            updateDistribution();
          }}
        >
          Save
        </Button>
        <Button
          shape="rounded"
          appearance="secondary"
          type="submit"
          size="medium"
          onClick={() => {
            props.setReceiptEditpopup(false);
            props.setSelectedTab(TabValues.commission);
          }}
        >
          Cancel
        </Button>
      </div>

      <StopDistribution stopDistributionpopup={props.stopDistributionpopup} />
      <LocateCommissionPopup islocate={islocate} setislocate={setislocate} />
    </div>
  );
};

export default DistibutionEditForm;
