import {
  Dialog,
  DialogBody,
  DialogSurface,
  Tab,
  TabList,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  DismissFilled,
  FlashSettings20Filled,
} from "@fluentui/react-icons";
import React, { useState } from "react";
import CommissionForm from "./Commission";
import DistibutionEditForm from "../DistributionForm/DistributionForm";
enum TabValues {
  commission = "tab1",
  distribution = "tab2",
}
const RecieptForm = (props: any) => {
  const [selectedTab, setSelectedTab] = useState<TabValues>(
    TabValues.commission
  );

  return (

    <div>
      <Dialog open={props.receiptEditpopup}>
        <DialogSurface style={{ height: "500px" }}>
          <DialogBody
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              padding: "10px",
              height: "450px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TabList
                defaultSelectedValue={TabValues.commission}
                onTabSelect={(event, data) =>
                  setSelectedTab(data.value as TabValues)
                }
              >
                <Tab value={TabValues.commission}>Commission</Tab>
                <Tab value={TabValues.distribution}>Distribution</Tab>
              </TabList>
              <DismissFilled
                style={{ fontSize: "20px" }}
                onClick={() => props.setReceiptEditpopup(false)}
              />
            </div>
            <div>
              {selectedTab === TabValues.commission && (
                <div style={{ paddingLeft: "10px" }}>
                  <CommissionForm
                    setReceiptEditpopup={props.setReceiptEditpopup}
                    getReceiptlistDta={props.getReceiptlistDta}
                    setStopReceiptpopup={props.setStopReceiptpopup}
                  />
                </div>
              )}
              {selectedTab === TabValues.distribution && (
                <div style={{ paddingLeft: "10px" }}>
                  <DistibutionEditForm
                    setSelectedTab={setSelectedTab}
                    setReceiptEditpopup={props.setReceiptEditpopup}
                  />
                </div>
              )}
            </div>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default RecieptForm;
