import { Badge, Button, Checkbox, Tooltip } from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_RowSelectionState,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";

import FilterTab from "../CommissionReceipts/FilterTab";
import FeesChargesEditForm from "./FeesChargesEditForm";
import TableComponent from "../CommissionDistributon/DistributionTable";
import apiServices from "../../../service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { setFeesChargesList } from "../../../redux/Commission/commissionSlice";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { formatedDate, getReceiptStatus } from "../../../utils";
import ASCButton from "../../../components/Button";
interface DataRow {
  commission_type: string;
  amount_to_company: string;
  receipt_start: string;
  end_date: string;
  payment_source: string;
  status: string;
  status_date: string;
  provider: string;
  client: boolean;
  client_name: string;
  case: boolean;
  case_reference: string;
  note_text: string;
  introduced_by: string;
  consultant: string;
  start_date: string;
}
const FeeschargesList = (props: any) => {
  const [editpopup, setEditpopup] = useState(false);
  const [distributioneditpopup, setDistributionEditpopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [commissionType, setCommissionType] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);

  const handleRowClick = (rowId: string, commissionType: string) => {
    if (
      feesChargesList.length === 0 ||
      commissionType !== "Client management fee"
    ) {
      return; // Prevent expanding if there is no data or the commission type is not applicable
    }
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };
  const dispatch: AppDispatch = useDispatch();

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const feesChargesList: any = useSelector(
    (state: RootState) => state.commission?.feesChargesList
  );

  const case_relatedcolumns = [
    { columnKey: "party_type", label: "Party Type" },
    { columnKey: "party_name", label: "Party Name" },
    { columnKey: "status", label: "Status" },
    { columnKey: "status_date", label: "Status Date" },
  ];
  const case_relateditems = [
    {
      party_type: { label: "Consultant" },
      party_name: { label: "Jone Done" },
      status: { label: "In Payment" },
      status_date: { label: "17/10/2024" },
    },
  ];
  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      {
        accessorKey: "commission_type",
        header: "Commission Type",
      },
      {
        accessorKey: "amount_to_company",
        header: "Amount to Company",
      },
      {
        accessorKey: "receipt_start",
        header: " Receipt Start",
        Cell: ({ row }: any) => <>{formatedDate(row.original.receipt_start)}</>,

      },
      {
        accessorKey: "end_date",
        header: "End Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,

      },
      {
        accessorKey: "payment_source",
        header: "Payment Source",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }: any) => <>{getReceiptStatus(row.original.status)}</>,

        
      },
      {
        accessorKey: "status_date",
        header: "Status Date",
         Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
      },
      {
        accessorKey: "provider",
        header: "Provider",
      },
      {
        accessorKey: "client",
        header: "Client",
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.client === true}
            // onChange={(e, checked) =>
            //   handleCheckboxChange(row.index, "client", checked)
            // }
          />
        ),
      },
      {
        accessorKey: "client_name",
        header: " Client Name",
      },
      {
        accessorKey: "case",
        header: "Case",
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.case === true}
            // onChange={(e, checked) =>
            //   handleCheckboxChange(row.index, "client", checked)
            // }
          />
        ),
      },
      {
        accessorKey: "case_reference",
        header: "Case Reference ",
      },
      {
        accessorKey: "consultant",
        header: "Consultant",
      },
      {
        accessorKey: "introduced_by",
        header: "Introducer",
      },
      {
        accessorKey: "start_date",
        header: "Start Date ",
         Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
      },
      {
        accessorKey: "end_date",
        header: "End Date",
         Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
      },
      {
        accessorKey: "note_text",
        header: "Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.note_text} />
          </>
        ),
      },
    ],
    []
  );
  const CommissionTypeOptions = [
    { label: "A J Bell", checked: true, id: "12254232" },
    { label: "Bob", checked: false, id: "12224532" },
    { label: "Tom", checked: false, id: "12432232" },
    { label: "Anderson", checked: true, id: "12232232" },
  ];

  const getDataList = async () => {
    try {
      const res = await apiServices.systemManager.commissionType.getList();
      if (res.data !== undefined) {
        if (res.data && res.data.data) {
          const commissionTypeList = res.data.data.map((item: any) => ({
            label: item.commission_type,
            checked: true,
            id: item.id,
          }));
          setCommissionType(commissionTypeList);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // API Call
  useEffect(() => {
    getReceiptlistDta();
    getDataList();
  }, []);
  const getReceiptlistDta = async () => {
    try {
      const response =
        await apiServices.commissionModule.fees_Changes.getList();
      if (response.data && response.data.data.length > 0) {
        const resultData = response.data.data.map((object: any) => {
          if (object.commission_id) {
            return {
              ...object,
              commission_type: object.commission_type || "",
              amount_to_company: object.amount_to_company || "0.0000",
              receipt_start: object.start_date || "",
              end_date: object.end_date || "",
              client_name: `${object.first_names || ""} ${
                object.last_name || ""
              }`.trim(),
              introduced_by: object.introduced_by || "",
              case_reference: object.case_reference || "",
              provider: object.provider || "",
              note_text: object.note_text || "",
              case_owner: object.liability_owner || "",
              start_date: object.start_date || "",
            };
          }
          return null;
        });

        const filteredData = resultData.filter((item: any) => item !== null);
        dispatch(setFeesChargesList(filteredData));
      } else {
        dispatch(setFeesChargesList(""));
      }
    } catch (error) {
      console.error("Error fetching receipt list data:", error);
    }
  };
  return (
    <div className="client-list-wrapper">
      <MaterialReactTable
        renderTopToolbarCustomActions={({ table }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              gap: "10px",
            }}
          >
            <ASCButton
              shape="rounded"
              className="asc-button-primary"
              appearance="primary"
              onClick={() => {
                setEditpopup(true);
              }}
              label="Add New"
            />
            <FilterTab
              filterPopup={filterPopup}
              setFilterPopup={setFilterPopup}
              selectedMenuItem="Select Parties"
              title="Select Commission Type"
              providerOptions={CommissionTypeOptions}
            />
            {/* <FilterTab
              setFilterPopup={props.setFilterPopup}
              filterPopup={props.filterPopup}
              selectedMenuItem={props.selectedMenuItem}
              selectedProviders={props.selectedProviders}
              titile="Select Commission Type"
            /> */}
            {/* <div className="filter_style">
              <span className="customTriggerText">Define Dates ,</span>
              Management Fee
              <>
                <Badge className="badge">+2</Badge>
              </>
            </div> */}
          </div>
        )}
        columns={columns}
        data={feesChargesList}
        enableColumnFilters={true}
        enableGlobalFilter={false}
        enableDensityToggle={true}
        enableExpandAll={false}
        enableRowSelection={false} // Disable row selection
        enableSelectAll={false} // Disable "Select All" option
        enableRowActions={false} // Disable row actions
        enableMultiRowSelection={false} // Disable multi-row selection
        enableColumnActions={false}
        enableStickyHeader
        enablePinning
        manualPagination
        initialState={{
          columnPinning: {
            left: ["mrt-row-expand", "commission_type"],
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowClick(row.id, row.original.commission_type),
          onDoubleClick: () => setEditpopup(true),
          selected: selectedRow[row.id],
          sx: {
            cursor: "pointer",
            backgroundColor: selectedRow[row.id] ? "#f1ebea" : "transparent",
            "& > *": {
              backgroundColor: selectedRow[row.id] ? "#f1ebea" : "transparent",
            },
          },
          title: "Double click for edit details", // Add tooltip text here
        })}
        muiTableContainerProps={{
          style: {
            minHeight: "200px",
            maxHeight: "100vh",
            overflow: "auto",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: "14px",
            lineHeight: "0.9",
          },
        }}
        renderDetailPanel={({ row }) =>
          feesChargesList.length > 0 &&
          row.original.commission_type === "Client management fee" &&
          expandedRow === row.id ? (
            <TableComponent
              columns={case_relatedcolumns}
              items={case_relateditems}
              level={4}
            />
          ) : null
        }
        renderRowActions={({ row, table }) => (
          <Tooltip content="Double click for edit details" relationship="label">
            <div>{/* Content of the row */}</div>
          </Tooltip>
        )}
        // renderRowActions={({ row, table }) => (
        //   <Tooltip content="Edit" relationship="label" positioning="below">
        //     <Button
        //       appearance="subtle"
        //       onClick={() => {
        //         setConfirmationPopupPopup(true);
        //       }}
        //       icon={<Edit16Filled primaryFill="var(--button-color)" />}
        //     />
        //   </Tooltip>
        // )}
      />
      <FeesChargesEditForm
        setEditpopup={setEditpopup}
        editpopup={editpopup}
        setDistributionEditpopup={setDistributionEditpopup}
        distributioneditpopup={distributioneditpopup}
      />
    </div>
  );
};

export default FeeschargesList;
