import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { useNavigate } from "react-router-dom";
import {
  FullScreenMaximize16Regular,
  FullScreenMinimize16Regular,
  Dismiss16Regular,
} from "@fluentui/react-icons";
import ClientDataList from "./ClientDataList";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  Checkbox,
  Toolbar,
  ToolbarGroup,
  ToolbarButton,
  makeStyles,
} from "@fluentui/react-components";
import ClientActionPage from "./ClientActionPage";
import ModalDialog from "../../components/ModalDialog";
import { fetchSettings } from "../../redux/formSlice";
import { checkUserPermission, convertStringToArray } from "../../utils";
import { UserPermissionsList } from "../../config";
import apiServices from "../../service";
import { ExtractMain } from "../utilities/ExtractLocate/extractMain";
import { setReloadClientList } from "../../redux/formUpdateReducer";
import { resetActiveClientState } from "../../redux/activeClientSlice";
import { setActiveCustmer } from "../../redux/timerSlice";
import { ValuePrediction } from "./SavedSearch/ValuePrediction";
import { setString } from "../../redux/SavedSearch/searchId";
import { setSelectionType } from "../../redux/SavedSearch/extractTypes";

interface PropsType {
  setTabs?: Dispatch<SetStateAction<string>>;
  [x: string]: any;
}
interface OptionType {
  label: string;
  value: string;
}
const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
  fullWidthItem: {
    width: "100%",
    justifyContent: "flex-start",
    backgroundColor: "#FAFAFF",
    borderRadius: "3px",
    height: "25px",
    alignItems: "center",
    display: "flex",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  fullWidthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "160px",
    overflowX: "auto",
    padding: "2px",
    gap: "3px",
  },
});
const scopeData: {
  value: any;
  label: string;
}[] = [
  {
    value: "client",
    label: "Client",
  },
  {
    value: "partner",
    label: "Partner",
  },
];
enum TabValues {
  ExportByCondition = "tab1",
  ExportBySelection = "tab2",
}

const List = ({ setTabs, module, formAction }: PropsType) => {
  const [selectedTab, setSelectedTab] = useState<TabValues>(
    TabValues.ExportByCondition
  );
  const [extract, setExtract] = useState<{ [key: string]: any }[]>([]);
  const [encodedCondition, setEncodedCondition] = useState<string | null>(null);
  const naviagate = useNavigate();
  const [selectedSearches, setSelectedSearches] = useState<string[]>([]);
  const styles = useStyles();
  const [typeOfExtract, setTypeOfExtract] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [page, setPage] = useState("listing");
  const [leftTabMenu, setLeftTabmenu] = useState("Client");
  const [clientFormSubmited, setClientFormSubmitted] = useState(false);
  const [drawerSize, setDrawerSize] = useState("small");
  const [activeClient, setActiveClient] = useState<{ [key: string]: any }>({});
  const [activeCustomerId, setActiveCustomerId] = useState<string | null>(null);
  const [activePartnerId, setActivePartnerId] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [selectedScope, setSelectedScope] = useState<any>([]);
  const [clientTypeList, setClientTypeList] = useState<any>([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedClientType, setSelectedClientType] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedClientTypeMenu, setSelectedClientTypeMenu] =
    useState("ClientType");
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [handleCondition, setHandleCondition] = useState([]);
  const [searchIds, setSearchIds] = useState<any>("");
  const [tabOpen, setTabOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemTwo, setSelectedItemTwo] = useState("");
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [selectedFieldNameTableTwo, setSelectedFieldNameTableTwo] =
    useState("");
  const [selectedTableName, setSelectedTableName] = useState("");
  const [selectedTableNameTwo, setSelectedTableNameTwo] = useState("");
  const [selectedColumnName, setSelectedColumnName] = useState("");
  const [selectedColumnNameTwo, setSelectedColumnNameTwo] = useState("");
  const [hasTableOne, setHasTableOne] = useState(false);
  const [hasTableTwo, setHasTableTwo] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState("");
  const [selectedFiledTypeTableTwo, setSelectedFieldTypeTableTwo] =
    useState("");

  const [isDialogOpens, setIsDialogOpens] = useState(false);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: any;
  }>({});
  const [breadcrumbItem, setBreadcrumbItem] = useState([
    {
      key: 0,
      value: "Home",
      link: "/",
    },
    {
      key: 1,
      value: "Clients",
    },
  ]);

  const handleAddSearchClick = () => {
    setIsDialogOpen(true);
  };

  const handleAddDialogBox = () => {
    setIsDialogOpens(true);
  };
  const handleMenuItemClick = (
    title: string,
    searchId: string,
    type: string
  ) => {
    dispatch(setSelectionType(type));

    setSelectedMenuItem((prev) =>
      prev.includes(title)
        ? prev.filter((item) => item !== title)
        : [...prev, title]
    );

    setSelectedSearches((prev) =>
      prev.includes(searchId)
        ? prev.filter((id) => id !== searchId)
        : [...prev, searchId]
    );

    setTypeOfExtract((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const handleCheckboxChange = (checked: boolean, item: any) => {
    if (!checked) {
      const updatedSelectedSearches = selectedSearches.filter(
        (id) => id !== item.SearchId
      );

      if (updatedSelectedSearches.length === 0) {
        // No items selected, clear everything
        setHasTableTwo(false);
        setHasTableOne(false);
        setSelectedItem("");
        setSelectedItemTwo("");
        setSelectedFieldName("");
        setSelectedFieldNameTableTwo("");
        setSelectedTableName("");
        setSelectedTableNameTwo("");
        setSelectedColumnName("");
        setSelectedColumnNameTwo("");
        setSelectedFieldType("");
        setSelectedFieldTypeTableTwo("");
        setIsPartner(false);
      } else {
        // Set the last selected item as active
        const lastSelectedId =
          updatedSelectedSearches[updatedSelectedSearches.length - 1];
        const lastSelectedItem = extract.find(
          (el: any) => el.SearchId === lastSelectedId
        );

        if (lastSelectedItem) {
          const condition = lastSelectedItem.Condition
            ? JSON.parse(lastSelectedItem.Condition)
            : {};
          const tableOne = condition?.tableOne ?? {};
          const tableTwo = condition?.tableTwo ?? {};

          setHasTableTwo(!!tableTwo.table);
          setHasTableOne(!!tableOne.table);
          setSelectedItem(tableOne.fieldValue ?? "");
          setSelectedItemTwo(tableTwo.fieldValue ?? "");
          setSelectedFieldName(tableOne.label ?? "");
          setSelectedFieldNameTableTwo(tableTwo.label ?? "");
          setSelectedTableName(tableOne.table ?? "");
          setSelectedTableNameTwo(tableTwo.table ?? "");
          setSelectedColumnName(tableOne.fieldName ?? "");
          setSelectedColumnNameTwo(tableTwo.fieldName ?? "");
          setSelectedFieldType(tableOne.fieldType ?? "");
          setSelectedFieldTypeTableTwo(tableTwo.fieldType ?? "");
          setSelectedItems(lastSelectedItem);
          setIsPartner(tableOne.partner ?? false);
        }
      }

      setSelectedSearches(updatedSelectedSearches);
      return;
    }

    // If checked, add item details
    const condition = item.Condition ? JSON.parse(item.Condition) : {};
    const tableOne = condition?.tableOne ?? {};
    const tableTwo = condition?.tableTwo ?? {};

    setHasTableTwo(!!tableTwo.table);
    setHasTableOne(!!tableOne.table);
    setSelectedItem(tableOne.fieldValue ?? "");
    setSelectedItemTwo(tableTwo.fieldValue ?? "");
    setSelectedFieldName(tableOne.label ?? "");
    setSelectedFieldNameTableTwo(tableTwo.label ?? "");
    setSelectedTableName(tableOne.table ?? "");
    setSelectedTableNameTwo(tableTwo.table ?? "");
    setSelectedColumnName(tableOne.fieldName ?? "");
    setSelectedColumnNameTwo(tableTwo.fieldName ?? "");
    setSelectedFieldType(tableOne.fieldType ?? "");
    setSelectedFieldTypeTableTwo(tableTwo.fieldType ?? "");
    setSelectedItems(item);
    setIsPartner(tableOne.partner ?? false);

    setSelectedSearches([...selectedSearches, item.SearchId]);
  };

  const renderedMenuItems = extract.map((item: any) => {
    const isSelected = selectedSearches.includes(item.SearchId);

    return (
      <div className={styles.fullWidthContainer} key={item.SearchId}>
        <Checkbox
          size="medium"
          checked={isSelected}
          onChange={(e) => {
            handleMenuItemClick(item.Title, item.SearchId, item.Type);
            handleCheckboxChange(e.target.checked, item);
          }}
          label={item.Title}
        />
      </div>
    );
  });

  const isFormDataUpdated: boolean = useSelector(
    (state: RootState) => state.someReducer?.isFormDataUpdated
  );
  const isFormupdated = useSelector(
    (state: RootState) => state.formUpdated?.isFormUpdated
  );
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(resetActiveClientState());
  }, [dispatch]);

  useEffect(() => {
    setSelectedScope([]);
  }, [search]);
  const changePageMode = (mode: string) => {
    setPage(mode);
    setIsOpen(true);
  };
  const setActiveItem = (item: any) => {
    setActiveClient(item);
    dispatch(setActiveCustmer(item.customer_id));
    setActiveCustomerId(item.customer_id);
    setActivePartnerId(item?.associated_with);
  };
  const closePage = () => {
    setIsOpen(false);
    setActiveCustomerId(null);
    dispatch(setReloadClientList());
    setPage("listing");
  };
  const setFormUpdated = (val: boolean) => {
    setFormDataUpdated(val);
  };
  const getList = () => {
    setReloadList(true);
  };
  const setDialogModal = (options: any) => {
    setDialogModalOptions(options);
  };
  const resetClientFormSubmited = () => {
    setClientFormSubmitted(false);
  };

  const getClientTypeListOptions = async () => {
    setIsLoadingSkeleton(true);
    const fieldLbl = "client_type:ModifiableList";
    try {
      const res = await apiServices.selectionList.getOptions(fieldLbl);
      if (res.data && res.data.data && res.data.data.length > 0) {
        const emptyOption = { value: "", label: "" };
        setClientTypeList([emptyOption, ...res.data.data[0].options]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingSkeleton(false);
    }
  };

  const renderPage = (pageView: string) => {
    return (
      <ClientActionPage
        mode={pageView}
        changeMode={changePageMode}
        activeClient={activeClient}
        closePage={closePage}
        activeCustomerId={activeCustomerId}
        activePartnerId={activePartnerId}
        setActivePartnerId={setActivePartnerId}
        setActiveItem={setActiveItem}
        getList={getList}
        dialogModalOptions={dialogModalOptions}
        setDialogModal={setDialogModal}
        clientFormSubmited={clientFormSubmited}
        resetClientFormSubmited={resetClientFormSubmited}
        setBreadcrumbItem={setBreadcrumbItem}
        leftTabMenu={leftTabMenu}
        module={module}
      />
    );
  };
  const handleCloseAction = () => {
    if (isFormDataUpdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
              setPage("listing");
              setActiveCustomerId(null);
              setIsOpen(false);
              setClientFormSubmitted(false);
              dispatch(resetFormData());
              naviagate(`/`);
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else if (isFormupdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
              setPage("listing");
              setActiveCustomerId(null);
              setIsOpen(false);
              setClientFormSubmitted(false);
              dispatch(resetFormData());
              naviagate(`/`);
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else {
      setPage("listing");
      setActiveCustomerId(null);
      setIsOpen(false);
      naviagate(`/`);
    }
  };
  const getExtracts = async () => {
    setInitialLoader(true);
    try {
      const response = await apiServices.extract.getExtract();
      const result: any = response.data?.data.filter(
        (item: any) => item.Type === "Condition"
      );

      if (!result.isError) {
        setExtract(result);
      }
    } catch (error) {
      console.error("Failed to fetch extracts:", error);
    } finally {
      setInitialLoader(false);
    }
  };
  // useEffect(() => {
  //   if (selectedTab === TabValues.ExportByCondition) {
  //     getExtracts();
  //   }
  // }, [selectedTab]);
  // useEffect(() => {
  //   getExtracts();
  // }, [selectedTab]);

  useEffect(() => {
    if (
      formSettingsObj &&
      formSettingsObj.data &&
      Object.keys(formSettingsObj.data).length === 0
    ) {
      dispatch(fetchSettings());
    }
  }, [formSettingsObj]);

  useEffect(() => {
    if (formAction != undefined) {
      changePageMode("add");
    }
  }, [formAction]);

  const handleSearchId = (id: any) => {
    setSelectedSearch(id);
    setApplyFilter(true);
  };

  async function fetchClientTypeOptions(): Promise<OptionType[]> {
    const fieldLbl = "client_type:ModifiableList";

    try {
      const res = await apiServices.selectionList.getOptions(fieldLbl);

      if (res.data && res.data.data && res.data.data.length > 0) {
        const options = res.data.data[0].options;

        return options;
      }

      return [];
    } catch (error) {
      console.error("Error fetching client type options:", error);

      return [];
    }
  }

  return (
    <>
      <div className="page-content-area" data-testid="page-content">
        {checkUserPermission(
          loggedUser,
          UserPermissionsList.ALLOW_VIEW_CLIENT
        ) && (
          <>
            <ClientDataList
              setEncodedCondition={setEncodedCondition}
              encodedCondition={encodedCondition}
              page={page}
              handleAddDialogBox={handleAddDialogBox}
              setSelectedItem={setSelectedItem}
              setSelectedItemTwo={setSelectedItemTwo}
              hasTableTwo={hasTableTwo}
              selectedItem={selectedItem}
              isPartner={isPartner}
              changeMode={changePageMode}
              setActiveItem={setActiveItem}
              search={search}
              reloadList={reloadList}
              setReloadList={setReloadList}
              dialogModalOptions={dialogModalOptions}
              setDialogModal={setDialogModal}
              setIsLoading={setIsLoading}
              selectedScope={selectedScope}
              applyFilter={applyFilter}
              setApplyFilter={setApplyFilter}
              selectedClientType={selectedClientType}
              searchId={
                selectedSearches.length > 0 ? selectedSearches : selectedSearch
              }
              extract={extract}
              setSearchIds={setSearchIds}
              handleCondition={handleCondition}
              searchIds={searchIds}
              selectedTab={selectedTab}
              typeOfExtract={typeOfExtract}
              activeClient={activeClient}
              setSearch={setSearch}
              isLoading={isLoading}
              scopeData={scopeData}
              setSelectedScope={setSelectedScope}
              fetchClientTypeOptions={fetchClientTypeOptions}
              setSelectedClientType={setSelectedClientType}
              setSelectedSearches={setSelectedSearches}
              getExtracts={getExtracts}
              setSelectedTab={setSelectedTab}
              setSelectedSearch={setSelectedSearch}
              setTypeOfExtract={setTypeOfExtract}
              renderedMenuItems={renderedMenuItems}
              selectedSearches={selectedSearches}
              handleAddSearchClick={handleAddSearchClick}
              setTabOpen={setTabOpen}
              tabOpen={tabOpen}
              initialLoader={initialLoader}
            />
          </>
        )}
        <Drawer
          modalType="non-modal"
          type="overlay"
          separator
          open={isOpen}
          size={drawerSize === "small" ? "large" : "full"}
          position="end"
          style={{
            width: "calc(100vw)",
            height: drawerSize === "small" ? "calc(100vh - 182px)" : "100vh",
            margin: 0,
            padding: 4,
          }}
          onOpenChange={(_, { open }) => setIsOpen(open)}
          className={drawerSize === "small" ? "common-drawer" : ""}
        >
          <DrawerHeader className="drawerHead">
            <DrawerHeaderTitle
              action={
                <Toolbar className={styles.toolbar}>
                  <ToolbarGroup>
                    {drawerSize === "large" && (
                      <ToolbarButton
                        aria-label="Close panel"
                        appearance="subtle"
                        icon={<FullScreenMinimize16Regular />}
                        onClick={() => {
                          setDrawerSize("small");
                        }}
                      />
                    )}
                    {drawerSize === "small" && (
                      <ToolbarButton
                        aria-label="Close panel"
                        appearance="subtle"
                        icon={<FullScreenMaximize16Regular />}
                        onClick={() => {
                          setDrawerSize("large");
                        }}
                      />
                    )}

                    <ToolbarButton
                      aria-label="Close panel"
                      appearance="subtle"
                      icon={<Dismiss16Regular />}
                      onClick={handleCloseAction}
                    />
                  </ToolbarGroup>
                </Toolbar>
              }
            >
              {page && page === "add"
                ? "Add Client"
                : activeClient?.NameAndAddress?.first_names !== "" &&
                  activeClient?.NameAndAddress?.first_names
                ? activeClient?.NameAndAddress?.last_name +
                  ", " +
                  activeClient?.NameAndAddress?.first_names
                : activeClient?.NameAndAddress?.last_name
                ? activeClient?.NameAndAddress?.last_name
                : ""}
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody className="drawerBody">{renderPage(page)}</DrawerBody>
        </Drawer>
        <ModalDialog options={dialogModalOptions} />
      </div>
      {isDialogOpen && (
        <Dialog
          modalType="non-modal"
          open={isDialogOpen}
          onOpenChange={(_, data) => setIsDialogOpen(data.open)}
        >
          <DialogSurface style={{ maxWidth: "75vw" }}>
            <DialogBody>
              <DialogTitle>Extract by Condition</DialogTitle>
              <DialogContent>
                <ExtractMain
                  setIsDialogOpen={setIsDialogOpen}
                  searchId={handleSearchId}
                  extract={extract}
                  setExtract={setExtract}
                  getExtracts={getExtracts}
                  setTabOpen={setTabOpen}
                />
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}

      <Dialog
        modalType="non-modal"
        open={isDialogOpens}
        onOpenChange={(_, data) => setIsDialogOpens(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <ValuePrediction
                selectedItem={selectedItem}
                isPartner={isPartner}
                hasTableTwo={hasTableTwo}
                selectedItemTwo={selectedItemTwo}
                setEncodedCondition={setEncodedCondition}
                encodedCondition={encodedCondition}
                setIsDialogOpens={setIsDialogOpens}
                selectedFieldName={selectedFieldName}
                selectedFieldNameTableTwo={selectedFieldNameTableTwo}
                selectedTableName={selectedTableName}
                selectedTableNameTwo={selectedTableNameTwo}
                selectedColumnName={selectedColumnName}
                selectedColumnNameTwo={selectedColumnNameTwo}
                selectedItems={selectedItems}
                setIsDialogOpen={setIsDialogOpen}
                searchId={handleSearchId}
                extract={extract}
                setExtract={setExtract}
                getExtracts={getExtracts}
                setTabOpen={setTabOpen}
                setApplyFilter={setApplyFilter}
                tableName={selectedTableName}
                columnName={selectedColumnName}
                selectedFieldType={selectedFieldType}
                selectedFiledTypeTableTwo={selectedFiledTypeTableTwo}
              />
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default List;
