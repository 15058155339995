import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button, makeStyles, Spinner } from "@fluentui/react-components";
import { SplitVerticalRegular } from "@fluentui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import apiServices from "../../service";
import { setHeaderValue } from "../../redux/headerValue";
import { updateTableDataList } from "../../redux/tableDataSlice";
import { setTableData } from "../../redux/clientSlice";
import { setDynamicResponseData } from "../../redux/dynamicResponse";
import { ResponseItem, TableNameCounts } from "./DataSelection";
import ASCButton from "../../components/Button";

interface RecordToCopy {
  tableName: string;
  recordId: string;
}

interface Payload {
  recordsToCopy: RecordToCopy[];
}

const useStyles = makeStyles({
  innerWrapper: {
    columnGap: "15px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

const FinalSection = ({
  checkboxState,
  BackToDataSelection,
  cancelSplitData,
  onClose,
  setSelectedValues,
  setPartnerData,
  tableNameCounts,
  setTableNameCounts,
  setClientData,
  setCheckboxState,
  setSelectedReason,
}: {
  checkboxState: { client: boolean; partner: boolean };
  BackToDataSelection: () => void;
  cancelSplitData: () => void;
  onClose: () => void;
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  setPartnerData: React.Dispatch<React.SetStateAction<ResponseItem[]>>;
  tableNameCounts: TableNameCounts;
  setTableNameCounts: React.Dispatch<React.SetStateAction<TableNameCounts>>;
  setClientData: React.Dispatch<React.SetStateAction<ResponseItem[]>>;
  setCheckboxState: React.Dispatch<
    React.SetStateAction<{ client: boolean; partner: boolean }>
  >;
  setSelectedReason: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const dynamicResponseData = useSelector(
    (state: any) => state.splitData.dynamicResponseData
  );
  const clientValue = useSelector(
    (state: RootState) => state.clientid.storedValue
  );
  const partnerId = useSelector((state: any) => state.partnerId.storedValue);
  useEffect(() => {
    const values: string[] = [];
    if (checkboxState.client) values.push(clientValue);
    if (checkboxState.partner) values.push(partnerId);
    setSelectedValues(values);
  }, [checkboxState, clientValue, partnerId]);

  const createPayload = (filterKey: "Client" | "Partner"): Payload => {
    const payload: Payload = { recordsToCopy: [] };
    Object.keys(dynamicResponseData).forEach((key) => {
      dynamicResponseData[key].forEach((record: any) => {
        if (record.assignedTo === filterKey) {
          payload.recordsToCopy.push({
            tableName: record.tableName,
            recordId: record.recordId,
          });
        }
      });
    });

    return payload;
  };

  const splitData = () => {
    setLoading(true);

    if (checkboxState.client) {
      const clientPayload = createPayload("Client");
      apiServices.ClientPartnerSplit.splitCP(clientValue, clientPayload)
        .then(() => {})
        .catch((error) => {
          console.error("Error during client split:", error);
        })
        .finally(() => {
          setLoading(false);
          onClose();
          dispatch(setHeaderValue(""));
          dispatch(
            updateTableDataList({
              tableName: "",
              data: [],
            })
          );
          dispatch(setTableData([]));
          dispatch(
            setDynamicResponseData({
              asset: [],
              liability: [],
              policy: [],
              income: [],
              expense: [],
              dependant: [],
              address: [],
            })
          );
          setClientData([]);
          setPartnerData([]);
          setTableNameCounts({
            Client: {},
            Partner: {},
          });
        });
      setCheckboxState({ client: false, partner: false });
      setSelectedReason("");
    }

    if (checkboxState.partner) {
      const partnerPayload = createPayload("Partner");
      apiServices.ClientPartnerSplit.splitCP(partnerId, partnerPayload)
        .then(() => {})
        .catch((error) => {
          console.error("Error during partner split:", error);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  return (
    <div>
      <div className="split-btn-section">
        <div>
          <span className="title-sections"> Make the Changes</span>
        </div>
        <div>
          <span>
            Click the split button to implement the changes described on the
            previous pages.
          </span>
        </div>
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner size="medium" />
        </div>
      ) : (
        <div className="box">
          <div className="btn-section">
            <ASCButton
              shape="rounded"
              appearance="secondary"
              icon={<SplitVerticalRegular />}
              onClick={splitData}
              label="Split"
            />
          </div>
        </div>
      )}
      <div className={styles.innerWrapper}>
        <ASCButton
          shape="rounded"
          size="medium"
          appearance="primary"
          className="asc-button-primary"
          onClick={BackToDataSelection}
          label="Back"
        />
        <ASCButton
          shape="rounded"
          size="medium"
          onClick={cancelSplitData}
          label="Cancel"
        />
      </div>
    </div>
  );
};

export default FinalSection;
