import React, { useEffect, useState } from "react";
import FormContent from "../../../components/Form/FormContent";
import { formBuilder } from "../../../utils/formGenerator";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  Label,
  Spinner,
} from "@fluentui/react-components";
import {
  Edit16Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
} from "@fluentui/react-icons";
import {
  CaseCategoryMap,
  formatedDate2,
  getAssetTypeByCaseTypeId2,
} from "../../../utils";
import { setFormData, resetFormData } from "../../../redux/reducer";
import apiServices from "../../../service";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import FundCalculation from "../../../components/CalculationTable/fundCalculation";
import WithdrawalCalculation from "../../../components/CalculationTable/withdrawalCalculation";
import PaymentCalculation from "../../../components/CalculationTable/paymentCalculation";
import {
  getAssetActionDetailById,
  getAssetActionsListItems,
  getAssetCommissionsDistributionDetailById,
  getAssetCommissionsDistributionListItems,
  getAssetDetailById,
  getAssetFundDetailById,
  getAssetFundsListItems,
  getAssetListItems,
  getAssetPaymentDetailById,
  getAssetPaymentsCommissionDetailById,
  getAssetPaymentsCommissionsListItems,
  getAssetPaymentsListItems,
  getAssetValuationDetailById,
  getAssetValuationsListItems,
  getAssetWithdrawalDetailById,
  getAssetWithdrawalsListItems,
} from "../../../redux/Case/assetSlice";
import ASCButton from "../../../components/Button";
import { reloadData } from "../../../redux/appSettingsSlice";
import {
  getLiabilityActionDetailById,
  getLiabilityActionsListItems,
  getLiabilityCommissionDistributionDetailById,
  getLiabilityCommissionDistributionListItems,
  getLiabilityDetailById,
  getLiabilityListItems,
  getLiabilityPaymentDetailById,
  getLiabilityPaymentsCommissionDetailById,
  getLiabilityPaymentsCommissionsListItems,
  getLiabilityPaymentsListItems,
} from "../../../redux/Case/liabilitySlice";
import {
  getPolicyActionDetailById,
  getPolicyActionsListItems,
  getPolicyCommissionDistributionDetailById,
  getPolicyCommissionDistributionListItems,
  getPolicyDetailById,
  getPolicyFundDetailById,
  getPolicyFundsListItems,
  getPolicyListItems,
  getPolicyPaymentDetailById,
  getPolicyPaymentsCommissionDetailById,
  getPolicyPaymentsCommissionsListItems,
  getPolicyPaymentsListItems,
  getPolicyValuationDetailById,
  getPolicyValuationsListItems,
  getPolicyWithdrawalDetailById,
  getPolicyWithdrawalsListItems,
} from "../../../redux/Case/policySlice";
import DeletePopup from "../../../components/DeletePopup/Deletepopup";
import DeleteDependencyPopup from "../../../components/DeletePopup/DeleteDepedencty";
import PartnerDetailPopup from "../../../components/PartnerDetailPopup/PartnerDetailPopup";
import ModalDialog from "../../../components/ModalDialog";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};

function CaseFormContainer(props: any) {
  let { context } = props;
  const classes = useStyles();
  const [configForm, setConfigForm] = useState<configFm>();
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const { clientId, action, caseId, subItemId, subSubItemId, distributionId } =
    useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [formError, setFormError] = useState<any[]>([]);
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [partnerDialogPopup, setPartnerDialogPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteApiProcessing, setDeleteApiProcessing] = useState(false);
  const [dependencyDeletePopup, setDependencyDeletePopup] = useState(false);
  const [dependencymessage, setDependencyMessage] = useState("");
  const [caseDetail, setCaseDetail] = useState<any>({});
  const dispatch: AppDispatch = useDispatch();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const activeClient = useSelector((state: RootState) => state.activeClient);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [consultantName, setConsultantName] = useState("");
  const [getCommissionType, setgetCommissionType] = useState("");
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [apiServiceLoading, setApiServiceLoading] = useState(false);
  const [getRuleId, setGetRuleId] = useState<{ [key: string]: any }>({});
  function ruleId(id: { [key: string]: any }) {
    setGetRuleId(id);

    if (props?.item?.field) {
      formik.setFieldValue(props?.item?.field, id.id);
    }
  }
  useEffect(() => {
    if (context !== "") {
      generateDynamicForm(context);
    }
  }, [context, formSettingsObj]);
  useEffect(() => {
    if (caseId != undefined && clientId != undefined) {
      fetchCaseDetail(caseId);
    }
  }, [caseId, clientId]);
  const formik = useFormik({
    initialValues:
      props.mode && ["edit", "view"].includes(props.mode)
        ? props.initialFormData || {}
        : formFieldData?.initialValues || {},

    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  useEffect(() => {
    if (formik.initialValues) {
      setPresent(formik.initialValues);
    }
  }, [formik.initialValues]);
  useEffect(() => {
    if (formik.values.provider_correspondence == null) {
      formik.setFieldValue("provider_correspondence", "");
    }
  }, [formik.values]);
  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      setLoading(true);
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const updateConsultantName = (name: any) => {
    setConsultantName(name);
  };

  const updateCommissionType = (name: any) => {
    setgetCommissionType(name);
  };
  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
  };
  const fetchCaseDetail = (id: string) => {
    if (props.section == "assets") {
      apiServices.assets
        .getDetail(id, clientId)
        .then((response) => {
          setLoading(false);

          if (response.data) {
            if (response.data.data) {
              setCaseDetail(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    } else if (props.section == "liabilities") {
      apiServices.liability
        .getDetail(id, clientId)
        .then((response: any) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setCaseDetail(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    } else if (props.section == "policies") {
      apiServices.policy
        .getDetail(id, clientId)
        .then((response: any) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setCaseDetail(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    }
  };
  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });
        setLoading(true);
        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            setLoading(false);
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;

    updatePresent(data);

    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      dispatch(resetFormData());
      updated = false;
    } else {
      dispatch(setFormData());
      updated = true;
    }

    if (props.mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(props.mode === "add" ? "New" : "");
    }
  };

  const updatePresent = (newState: any) => {
    setPast((prev) => [...prev, present]);

    setPresent(newState);
    setFuture([]);
  };

  const undo = () => {
    if (past.length === 0) {
      return;
    }

    const newPast = [...past];
    const newPresent = newPast.pop(); // Get last state from history

    if (!newPresent) return; // Ensure we have a valid state

    setPast(newPast);
    setFuture([present, ...future]); // Move current state to future
    setPresent(newPresent);

    // Set form values only if valid
    formik.setValues(newPresent);

    // Check if new state matches initial values
    if (JSON.stringify(formik.initialValues) === JSON.stringify(newPresent)) {
      dispatch(resetFormData());
      setFormMode("");
    } else {
      dispatch(setFormData());
    }
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);

    formik.setValues(newPresent);
  };

  const generateDynamicForm = (formContext: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let { initialValues, inputs, validationSchema } = formBuilder({
        context: context,
        formSettings: formSettingsObj.data,
      });

      let filteredSelectionOptions = inputs
        .filter(
          (opt: any) =>
            opt.listType !== undefined &&
            [
              "ModifiableList",
              "FixedList",
              "MasterDatawithNewEntry",
              "MasterDatawithoutNewEntry",
            ].includes(opt.listType) &&
            opt.fieldOptionLabel !== undefined
        )
        .map((opt) => ({
          label: opt.fieldOptionLabel,
          listType: opt.listType,
          formField: opt.field,
        }));
      setSelectFieldOptionLabels(filteredSelectionOptions);
      if (filteredSelectionOptions.length > 0) {
        getSelectionListOptions(filteredSelectionOptions);
      }
      setFormFieldData({ initialValues, inputs, validationSchema });
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === context
      );

      if (configObj) {
        setConfigForm(configObj);
      }
    }
  };
  const customHandleChange = (event: any) => {
    handleOnChange(event);
  };
  const deleteData = () => {
    setDeleteApiProcessing(true);
    if (
      [
        "ASSET_INVESTMENT_ASSET",
        "ASSET_SHAREHOLDINGS_ASSET",
        "ASSET_HOME_AND_PERSONAL_ASSET",
        "ASSET_BANK_OR_BUILDING_SOCIETIES_ASSET",
      ].includes(context)
    ) {
      apiServices.assets
        .delete(caseId)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          if (response.data.isdependancy) {
            setDependencyDeletePopup(true);
            setDependencyMessage(response.data.message);
          } else {
            props.notify(response.data.message, "", "success");

            if (clientId && caseId) {
              dispatch(getAssetListItems(clientId)).then(() => {
                setDeletePopup(false);
                navigate(`/clients/${clientId}/${props.section}`);
              });
            } else {
              setDeletePopup(false);
              navigate(`/clients/${clientId}/${props.section}`);
            }
          }
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      [
        "LIABILITIES_MORTGAGES_LIABILITY",
        "LIABILITIES_LOAN_HIRE_PURCHASE_LIABILITY",
        "LIABILITIES_CREDIT_CARDS_LIABILITY",
      ].includes(context)
    ) {
      apiServices.liability
        .delete(caseId)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          if (response.data.isdependancy) {
            setDependencyDeletePopup(true);
            setDependencyMessage(response.data.message);
          } else {
            props.notify(response.data.message, "", "success");

            if (clientId && caseId) {
              dispatch(getLiabilityListItems(clientId));
            }
            setDeletePopup(false);
            navigate(`/clients/${clientId}/${props.section}`);
          }
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      [
        "POLICIES_LIFE_ASSURANCE_POLICIES",
        "POLICIES_PENSIONS_POLICY",
        "POLICIES_INVESTMENTS_POLICY",
        "POLICIES_SAVINGS_PLANS_POLICY",
        "POLICIES_INCOME_PROTECTION_POLICY",
        "POLICIES_HEALTH_ASSURANCE_POLICY",
        "POLICIES_GENERAL_POLICY",
      ].includes(context)
    ) {
      apiServices.policy
        .delete(caseId)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          if (response.data.isdependancy) {
            setDependencyDeletePopup(true);
            setDependencyMessage(response.data.message);
          } else {
            props.notify(response.data.message, "", "success");

            if (clientId && caseId) {
              dispatch(getPolicyListItems(clientId));
            }
            setDeletePopup(false);
            navigate(`/clients/${clientId}/${props.section}`);
          }
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      props.subItem === "funds" &&
      [
        "ASSET_INVESTMENT_FUND",
        "ASSET_SHARE_HOLDINGS_FUND",
        "ASSET_BANK_OR_BUILDING_SOCIETIES_FUND",
        "POLICIES_LIFE_ASSURANCE_FUNDS",
        "POLICIES_PENSIONS_FUNDS",
        "POLICIES_INVESTMENTS_FUNDS",
        "POLICIES_SAVINGS_PLANS_FUNDS",
        "POLICIES_INCOME_PROTECTION_FUNDS",
        "POLICIES_HEALTH_ASSURANCE_FUNDS",
        "POLICIES_GENERAL_FUNDS",
      ].includes(context)
    ) {
      apiServices.fund
        .delete(props.activeItem.fund_id)
        .then((response: any) => {
          props.notify(response.data.message, "", "success");
          setDeleteApiProcessing(false);
          const timeout = setTimeout(() => {
            if (clientId && caseId && props.section) {
              if (props.section == "assets") {
                dispatch(
                  getAssetFundsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                )
                  .then(() => {
                    return dispatch(
                      getAssetFundDetailById(props.activeItem.fund_id)
                    );
                  })
                  .catch((error) => {
                    console.error("Error in dispatch chain:", error);
                  });
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              } else {
                dispatch(
                  getPolicyFundsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                )
                  .then(() => {
                    return dispatch(
                      getPolicyFundDetailById(props.activeItem.fund_id)
                    );
                  })
                  .catch((error) => {
                    console.error("Error in dispatch chain:", error);
                  });
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              }
            }
          }, 1000);
          return () => clearTimeout(timeout);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      props.subItem === "payments" &&
      [
        "ASSET_INVESTMENT_PAYMENTS",
        "ASSET_SHARE_HOLDINGS_PAYMENT",
        "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT",
        "LIABILITIES_CREDIT_CARDS_PAYMENTS",
        "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENT",
        "LIABILITIES_MORTGAGES_PAYMENT",
        "POLICIES_LIFE_ASSURANCE_PAYMENT",
        "POLICIES_PENSIONS_PAYMENT",
        "POLICIES_INVESTMENTS_PAYMENT",
        "POLICIES_SAVINGS_PLANS_PAYMENT",
        "POLICIES_INCOME_PROTECTION_PAYMENT",
        "POLICIES_HEALTH_ASSURANCE_PAYMENT",
        "POLICIES_GENERAL_PAYMENT",
      ].includes(context)
    ) {
      apiServices.Payments.delete(props.activeItem?.payment?.payment_id)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          if (response.data.isdependancy) {
            setDependencyDeletePopup(true);
            setDependencyMessage(response.data.message);
          } else {
            props.notify(response.data.message, "", "success");
            const timeout = setTimeout(() => {
              if (clientId && caseId) {
                if (props.section == "assets") {
                  dispatch(
                    getAssetPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  )
                    .then(() => {
                      return dispatch(
                        getAssetPaymentDetailById(props.activeItem.payment_id)
                      );
                    })
                    .catch((error) => {
                      console.error("Error in dispatch chain:", error);
                    });
                  setDeletePopup(false);
                  navigate(
                    `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                  );
                } else if (props.section == "liabilities") {
                  dispatch(
                    getLiabilityPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  )
                    .then(() => {
                      return dispatch(
                        getLiabilityPaymentDetailById(
                          props.activeItem.payment_id
                        )
                      );
                    })
                    .catch((error) => {
                      console.error("Error in dispatch chain:", error);
                    });
                  setDeletePopup(false);
                  navigate(
                    `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                  );
                } else {
                  dispatch(
                    getPolicyPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  )
                    .then(() => {
                      return dispatch(
                        getPolicyPaymentDetailById(props.activeItem.payment_id)
                      );
                    })
                    .catch((error) => {
                      console.error("Error in dispatch chain:", error);
                    });
                  setDeletePopup(false);
                  navigate(
                    `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                  );
                }
              }
            }, 1000);
            return () => clearTimeout(timeout);
          }
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      props.tab === "commission" &&
      [
        "ASSET_INVESTMENT_PAYMENTS_COMMISSION",
        "ASSET_SHAREHOLDINGS_PAYMENT_COMMISSION",
        "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT_COMMISSION",
        "LIABILITIES_MORTGAGES_PAYMENT_COMMISSION",
        "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENTS_COMMISSION",
        "LIABILITIES_CREDIT_CARDS_PAYMENTS_COMMISSION",
        "POLICIES_LIFE_ASSURANCE_PAYMENTS_COMMISSION",
        "POLICIES_PENSIONS_PAYMENTS_COMMISSION",
        "POLICIES_INVESTMENTS_PAYMENTS_COMMISSION",
        "POLICIES_SAVINGS_PLANS_COMMISSION",
        "POLICIES_INCOME_PROTECTION_COMMISSION",
        "POLICIES_HEALTH_ASSURANCE_COMMISSION",
        "POLICIES_GENERAL_PAYMENTS_COMMISSION",
      ].includes(context)
    ) {
      apiServices.commission
        .delete(props.activeItem.commission_id)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          props.notify(response.data.message, "", "success");
          const timeout = setTimeout(() => {
            if (clientId && caseId && subItemId) {
              if (props.section == "assets") {
                dispatch(
                  getLiabilityPaymentsCommissionsListItems({
                    paymentId: subItemId,
                    caseId: caseId,
                    customerId: clientId,
                  })
                )
                  .then(() => {
                    return dispatch(
                      getAssetPaymentsCommissionDetailById(
                        props.activeItem.commission_id
                      )
                    );
                  })
                  .catch((error) => {
                    console.error("Error in dispatch chain:", error);
                  });
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${props.tab}`
                );
              } else if (props.section == "liabilities") {
                dispatch(
                  getLiabilityPaymentsCommissionsListItems({
                    paymentId: subItemId,
                    caseId: caseId,
                    customerId: clientId,
                  })
                )
                  .then(() => {
                    return dispatch(
                      getLiabilityPaymentsCommissionDetailById(
                        props.activeItem.payment_id
                      )
                    );
                  })
                  .catch((error) => {
                    console.error("Error in dispatch chain:", error);
                  });
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${props.tab}`
                );
              } else {
                dispatch(
                  getPolicyPaymentsCommissionsListItems({
                    paymentId: subItemId,
                    caseId: caseId,
                    customerId: clientId,
                  })
                )
                  .then(() => {
                    return dispatch(
                      getPolicyPaymentsCommissionDetailById(
                        props.activeItem.commission_id
                      )
                    );
                  })
                  .catch((error) => {
                    console.error("Error in dispatch chain:", error);
                  });
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${props.tab}`
                );
              }
            }
          }, 1000);
          return () => clearTimeout(timeout);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      props.subItem === "withdrawals" &&
      [
        "ASSET_INVESTMENT_WITHDRAWALS",
        "ASSET_SHARE_HOLDING_WITHDRAWALS",
        "ASSET_BANK_OR_BUILDING_SOCIETIES_WITHDRAWALS",
        "POLICIES_LIFE_ASSURANCE_WITHDRAWALS",
        "POLICIES_SAVINGS_PLANS_WITHDRAWALS",
        "POLICIES_PENSIONS_WITHDRAWALS",
        "POLICIES_INVESTMENTS_WITHDRAWALS",
        "POLICIES_INCOME_PROTECTION_WITHDRAWALS",
        "POLICIES_HEALTH_ASSURANCE_WITHDRAWALS",
        "POLICIES_GENERAL_WITHDRAWALS",
      ].includes(context)
    ) {
      apiServices.Withdrawals.delete(props.activeItem.withdrawal_id)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          props.notify(response.data.message, "", "success");
          const timeout = setTimeout(() => {
            if (clientId && caseId && props.section) {
              if (props.section == "assets") {
                dispatch(
                  getAssetWithdrawalsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              } else {
                dispatch(
                  getPolicyWithdrawalsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              }
            }
          }, 1000);
          return () => clearTimeout(timeout);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      props.subItem === "actions" &&
      [
        "ASSET_INVESTMENT_ACTIONS",
        "LIABILITIES_MORTGAGES_ACTIONS",
        "LIABILITIES_LOAN_HIRE_PURCHASE_ACTIONS",
        "POLICIES_LIFE_ASSURANCE_ACTIONS",
        "POLICIES_PENSIONS_ACTIONS",
        "POLICIES_INVESTMENTS_ACTIONS",
        "POLICIES_SAVINGS_PLANS_ACTIONS",
        "POLICIES_INCOME_PROTECTION_ACTIONS",
        "POLICIES_HEALTH_ASSURANCE_ACTIONS",
        "POLICIES_GENERAL_ACTIONS",
      ].includes(context)
    ) {
      apiServices.Actions.delete(props.activeItem.business_id)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          props.notify(response.data.message, "", "success");
          const timeout = setTimeout(() => {
            if (clientId && caseId && props.section) {
              if (props.section == "assets") {
                dispatch(
                  getAssetActionsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              } else if (props.section == "liabilities") {
                dispatch(
                  getLiabilityActionsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              } else {
                dispatch(
                  getPolicyActionsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              }
            }
          }, 1000);
          return () => clearTimeout(timeout);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    } else if (
      props.subItem === "valuations" &&
      [
        "ASSET_INVESTMENT_VALUATION",
        "ASSET_SHAREHOLDING_VALUATION",
        "ASSET_HOME_AND_PERSONAL_VALUATION",
        "ASSET_BANK_OR_BUILDING_SOCIETIES_VALUATION",
        "POLICIES_LIFE_ASSURANCE_VALUATION",
        "POLICIES_PENSIONS_VALUATION",
        "POLICIES_INVESTMENTS_VALUATION",
        "POLICIES_SAVINGS_PLANS_VALUATION",
        "POLICIES_INCOME_PROTECTION_VALUATION",
        "POLICIES_HEALTH_ASSURANCE_VALUATION",
        "POLICIES_GENERAL_VALUATION",
      ].includes(context)
    ) {
      apiServices.valuation
        .delete(props.activeItem.valuation_id)
        .then((response: any) => {
          setDeleteApiProcessing(false);
          props.notify(response.data.message, "", "success");
          const timeout = setTimeout(() => {
            if (clientId && caseId && props.section) {
              if (props.section == "assets") {
                dispatch(
                  getAssetValuationsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
                );
              } else {
                dispatch(
                  getPolicyValuationsListItems({
                    id: caseId,
                    customerId: clientId,
                  })
                );
                setDeletePopup(false);
                navigate(
                  `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${props.tab}`
                );
              }
            }
          }, 1000);
          return () => clearTimeout(timeout);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setDeleteApiProcessing(false);
        });
    }
  };
  const formSubmitted = async (values: any) => {
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    let reqBody: { [key: string]: any } = {};
    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
    }
    let reqParam: any = {};
    if (
      configForm &&
      configForm.renderForm &&
      configForm.renderForm.reference_id
    ) {
      reqParam[configForm.renderForm.reference_id] = reqBody;

      if (props.section == "assets") {
        if (
          [
            "asset_investment_review",
            "asset_share_holdings_review",
            "asset_home_personal_review",
            "asset_banks_building_societies_review",
          ].includes(configForm.renderForm.reference_id)
        ) {
          reqParam[configForm.renderForm.reference_id]["case_type"] =
            getAssetTypeByCaseTypeId2(props.caseType);

          reqParam[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.joint_indicator == 1
              ? activeClient?.basicDetails?.associated_with
              : props.activeCustomerId;
          reqParam[configForm.renderForm.reference_id]["joint_indicator"] =
            props.activeItem.joint_indicator === ""
              ? 0
              : parseInt(props.activeItem.joint_indicator);
          reqParam[configForm.renderForm.reference_id]["review_completed"] =
            formatedDate2(reqBody.review_completed);
          reqParam[configForm.renderForm.reference_id]["review_date"] =
            formatedDate2(reqBody.review_date);
          if (
            reqBody.review_interval !== undefined &&
            reqBody.review_interval !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["review_interval"] =
              parseInt(reqBody.review_interval);
          } else {
            delete reqParam[configForm.renderForm.reference_id][
              "review_interval"
            ];
          }
        } else {
          if (configForm) {
            reqParam[configForm.renderForm.reference_id]["case_type"] =
              getAssetTypeByCaseTypeId2(props.caseType);
            reqParam[configForm.renderForm.reference_id]["customer_id"] =
              reqBody.joint_indicator == 1
                ? activeClient?.basicDetails?.associated_with
                : props.activeCustomerId;
            reqParam[configForm.renderForm.reference_id]["joint_indicator"] =
              reqBody.joint_indicator === ""
                ? 0
                : parseInt(reqBody.joint_indicator);
            reqParam[configForm.renderForm.reference_id]["review_completed"] =
              formatedDate2(reqBody.review_completed);
            reqParam[configForm.renderForm.reference_id]["review_date"] =
              formatedDate2(reqBody.review_date);
            reqParam[configForm.renderForm.reference_id]["current_value"] =
              reqBody.current_value === ""
                ? 0
                : parseFloat(reqBody.current_value);
            reqParam[configForm.renderForm.reference_id][
              "share_current_price"
            ] =
              reqBody.share_current_price === ""
                ? 0
                : parseFloat(reqBody.share_current_price);
            reqParam[configForm.renderForm.reference_id][
              "share_original_value"
            ] =
              reqBody.share_original_value === ""
                ? 0
                : parseFloat(reqBody.share_original_value);
            reqParam[configForm.renderForm.reference_id][
              "share_current_number"
            ] =
              reqBody.share_current_number === ""
                ? 0
                : parseFloat(reqBody.share_current_number);
            reqParam[configForm.renderForm.reference_id][
              "home_original_value"
            ] =
              reqBody.home_original_value === ""
                ? 0
                : parseFloat(reqBody.home_original_value);
            reqParam[configForm.renderForm.reference_id]["bank_interest_rate"] =
              reqBody.bank_interest_rate === ""
                ? 0
                : parseFloat(reqBody.bank_interest_rate);
            if (
              reqBody.review_interval !== undefined &&
              reqBody.review_interval !== ""
            ) {
              reqParam[configForm.renderForm.reference_id]["review_interval"] =
                parseInt(reqBody.review_interval);
            } else {
              delete reqParam[configForm.renderForm.reference_id][
                "review_interval"
              ];
            }
            if (
              reqBody.provider_correspondence !== undefined &&
              reqBody.provider_correspondence !== ""
            ) {
              reqParam[configForm.renderForm.reference_id][
                "provider_correspondence"
              ] = reqBody.provider_correspondence;
            } else {
              delete reqParam[configForm.renderForm.reference_id][
                "provider_correspondence"
              ];
            }
          }
        }
      }
      if (props.section == "liabilities") {
        if (
          [
            "liabilities_mortgages_review",
            "liabilities_loan_hire_purchase_review",
            "liabilities_credit_cards_review",
          ].includes(configForm.renderForm.reference_id)
        ) {
          reqParam[configForm.renderForm.reference_id]["case_type"] =
            getAssetTypeByCaseTypeId2(props.caseType);
          reqParam[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.joint_indicator == 1
              ? activeClient?.basicDetails?.associated_with
              : props.activeCustomerId;
          reqParam[configForm.renderForm.reference_id]["joint_indicator"] =
            props.activeItem.joint_indicator === ""
              ? 0
              : parseInt(props.activeItem.joint_indicator);
          reqParam[configForm.renderForm.reference_id]["review_completed"] =
            formatedDate2(reqBody.review_completed);
          reqParam[configForm.renderForm.reference_id]["review_date"] =
            formatedDate2(reqBody.review_date);
          if (
            reqBody.review_interval !== undefined &&
            reqBody.review_interval !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["review_interval"] =
              parseInt(reqBody.review_interval);
          } else {
            delete reqParam[configForm.renderForm.reference_id][
              "review_interval"
            ];
          }
        } else {
          reqParam[configForm.renderForm.reference_id]["case_type"] =
            getAssetTypeByCaseTypeId2(props.caseType);
          reqParam[configForm.renderForm.reference_id]["customer_id"] =
            reqBody.joint_indicator == 1
              ? activeClient?.basicDetails?.associated_with
              : props.activeCustomerId;
          reqParam[configForm.renderForm.reference_id]["joint_indicator"] =
            reqBody.joint_indicator === ""
              ? 0
              : parseInt(reqBody.joint_indicator);
          reqParam[configForm.renderForm.reference_id][
            "loan_valuation_percentage"
          ] =
            reqBody.loan_valuation_percentage === ""
              ? 0
              : parseFloat(reqBody.loan_valuation_percentage);
          reqParam[configForm.renderForm.reference_id]["interest_rate"] =
            reqBody.interest_rate === ""
              ? 0
              : parseFloat(reqBody.interest_rate);
          reqParam[configForm.renderForm.reference_id]["term"] =
            reqBody.term === "" ? 0 : parseInt(reqBody.term);
          reqParam[configForm.renderForm.reference_id]["amount_borrowed"] =
            reqBody.amount_borrowed === ""
              ? 0
              : parseFloat(reqBody.amount_borrowed);
          reqParam[configForm.renderForm.reference_id]["amount_outstanding"] =
            reqBody.amount_outstanding === ""
              ? 0
              : parseFloat(reqBody.amount_outstanding);
          if (
            reqBody.provider_correspondence !== undefined &&
            reqBody.provider_correspondence !== ""
          ) {
            reqParam[configForm.renderForm.reference_id][
              "provider_correspondence"
            ] = reqBody.provider_correspondence;
          } else {
            delete reqParam[configForm.renderForm.reference_id][
              "provider_correspondence"
            ];
          }
          reqParam[configForm.renderForm.reference_id]["property_value"] =
            reqBody.property_value === ""
              ? 0
              : parseFloat(reqBody.property_value);
          if (
            reqBody.review_interval !== undefined &&
            reqBody.review_interval !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["review_interval"] =
              parseInt(reqBody.review_interval);
          } else {
            delete reqParam[configForm.renderForm.reference_id][
              "review_interval"
            ];
          }
        }
      }

      if (props.section == "policies") {
        if (
          [
            "policies_life_assurance_review",
            "policies_pensions_review",
            "policies_investments_review",
            "policies_savings_plans_review",
            "policies_income_protection_review",
            "policies_health_assurance_review",
            "policies_general_review",
          ].includes(configForm.renderForm.reference_id)
        ) {
          reqParam[configForm.renderForm.reference_id]["case_type"] =
            getAssetTypeByCaseTypeId2(props.caseType);
          reqParam[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.policy_holder == 1
              ? activeClient?.basicDetails?.associated_with
              : props.activeCustomerId;
          reqParam[configForm.renderForm.reference_id]["policy_holder"] =
            props.activeItem.policy_holder === ""
              ? 0
              : parseInt(props.activeItem.policy_holder);
          reqParam[configForm.renderForm.reference_id]["review_completed"] =
            formatedDate2(reqBody.review_completed);
          reqParam[configForm.renderForm.reference_id]["review_date"] =
            formatedDate2(reqBody.review_date);
          if (
            reqBody.review_interval !== undefined &&
            reqBody.review_interval !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["review_interval"] =
              parseInt(reqBody.review_interval);
          } else {
            delete reqParam[configForm.renderForm.reference_id][
              "review_interval"
            ];
          }
        } else {
          reqParam[configForm.renderForm.reference_id]["case_type"] =
            getAssetTypeByCaseTypeId2(props.caseType);
          reqParam[configForm.renderForm.reference_id]["customer_id"] =
            reqBody.policy_holder == 1
              ? activeClient?.basicDetails?.associated_with
              : props.activeCustomerId;
          reqParam[configForm.renderForm.reference_id]["policy_holder"] =
            reqBody.policy_holder === "" ? 0 : parseInt(reqBody.policy_holder);
          reqParam[configForm.renderForm.reference_id]["review_interval"] =
            reqBody.review_interval === ""
              ? 0
              : parseInt(reqBody.review_interval);
          reqParam[configForm.renderForm.reference_id]["review_completed"] =
            formatedDate2(reqBody.review_completed);
          reqParam[configForm.renderForm.reference_id]["review_date"] =
            formatedDate2(reqBody.review_date);
          if (
            reqBody.provider_correspondence !== undefined &&
            reqBody.provider_correspondence !== ""
          ) {
            reqParam[configForm.renderForm.reference_id][
              "provider_correspondence"
            ] = reqBody.provider_correspondence;
          } else {
            delete reqParam[configForm.renderForm.reference_id][
              "provider_correspondence"
            ];
          }
          reqParam[configForm.renderForm.reference_id]["term"] =
            reqBody.term === "" ? 0 : parseInt(reqBody.term);
          if (
            reqBody.periodic_benefit !== undefined &&
            reqBody.periodic_benefit !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["periodic_benefit"] =
              parseInt(reqBody.periodic_benefit);
          } else {
            reqParam[configForm.renderForm.reference_id][
              "periodic_benefit"
            ] = 0;
          }
          if (
            reqBody.total_fund_value !== undefined &&
            reqBody.total_fund_value !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["total_fund_value"] =
              parseFloat(reqBody.total_fund_value);
          } else {
            reqParam[configForm.renderForm.reference_id][
              "total_fund_value"
            ] = 0;
          }
          if (
            reqBody.future_value !== undefined &&
            reqBody.future_value !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["future_value"] =
              parseInt(reqBody.future_value);
          } else {
            reqParam[configForm.renderForm.reference_id]["future_value"] = 0;
          }
          if (
            reqBody.link_to_mortgage !== undefined &&
            reqBody.link_to_mortgage !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["link_to_mortgage"] =
              parseInt(reqBody.link_to_mortgage);
          } else {
            reqParam[configForm.renderForm.reference_id][
              "link_to_mortgage"
            ] = 0;
          }
          if (
            reqBody.surrender_value !== undefined &&
            reqBody.surrender_value !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["surrender_value"] =
              parseInt(reqBody.surrender_value);
          } else {
            reqParam[configForm.renderForm.reference_id]["surrender_value"] = 0;
          }
          if (reqBody.sum_assured !== undefined && reqBody.sum_assured !== "") {
            reqParam[configForm.renderForm.reference_id]["sum_assured"] =
              parseInt(reqBody.sum_assured);
          } else {
            reqParam[configForm.renderForm.reference_id]["sum_assured"] = 0;
          }
          if (
            reqBody.lump_sum_benefit !== undefined &&
            reqBody.lump_sum_benefit !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["lump_sum_benefit"] =
              parseInt(reqBody.lump_sum_benefit);
          } else {
            reqParam[configForm.renderForm.reference_id][
              "lump_sum_benefit"
            ] = 0;
          }
          if (
            reqBody.transfer_value !== undefined &&
            reqBody.transfer_value !== ""
          ) {
            reqParam[configForm.renderForm.reference_id]["transfer_value"] =
              parseInt(reqBody.transfer_value);
          } else {
            reqParam[configForm.renderForm.reference_id]["transfer_value"] = 0;
          }
        }
      }

      if (props.tab === "fund") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["fund_code"] =
          reqBody.fund_code;
        reqParam[configForm.renderForm.reference_id]["fund_code_source"] =
          parseInt(reqBody.fund_code_source);
        reqParam[configForm.renderForm.reference_id]["fund_name"] =
          reqBody.fund_name;
        reqParam[configForm.renderForm.reference_id]["fund_units"] =
          reqBody.fund_units != null && reqBody.fund_units != ""
            ? parseFloat(reqBody.fund_units)
            : 0;
        reqParam[configForm.renderForm.reference_id]["unit_price"] =
          reqBody.unit_price != null && reqBody.unit_price != ""
            ? parseFloat(reqBody.unit_price)
            : 0;
        reqParam[configForm.renderForm.reference_id]["currency_code"] =
          reqBody.currency_code;
        reqParam[configForm.renderForm.reference_id]["price_date"] =
          formatedDate2(reqBody.price_date);
        if (parseFloat(reqBody.fund_units) && parseFloat(reqBody.unit_price)) {
          reqParam[configForm.renderForm.reference_id]["fund_value"] =
            parseFloat(reqBody.fund_units) * parseFloat(reqBody.unit_price);
        } else {
          reqParam[configForm.renderForm.reference_id]["fund_value"] =
            reqBody.fund_value != null && reqBody.fund_value != ""
              ? parseFloat(reqBody.fund_value)
              : 0;
        }

        reqParam[configForm.renderForm.reference_id]["sector"] = reqBody.sector;
        reqParam[configForm.renderForm.reference_id]["total_expense_ratio"] =
          reqBody.total_expense_ratio
            ? reqBody.total_expense_ratio.toString()
            : "0";

        if (reqBody.risk_rating !== undefined && reqBody.risk_rating !== "") {
          reqParam[configForm.renderForm.reference_id]["risk_rating"] =
            reqBody.risk_rating.toString();
        } else {
          reqParam[configForm.renderForm.reference_id]["risk_rating"] = "0";
        }
      } else if (props.tab === "withdrawal") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["start_date"] =
          formatedDate2(reqBody.start_date);
        reqParam[configForm.renderForm.reference_id]["end_date"] =
          formatedDate2(reqBody.end_date);
        reqParam[configForm.renderForm.reference_id]["business_category"] = 2;
        reqParam[configForm.renderForm.reference_id]["amount"] =
          reqBody.amount === "" ? 0 : parseFloat(reqBody.amount);
        reqParam[configForm.renderForm.reference_id]["frequency"] =
          reqBody.frequency;
        reqParam[configForm.renderForm.reference_id]["high_risk"] =
          reqBody.high_risk;
        reqParam[configForm.renderForm.reference_id]["high_risk_note"] =
          reqBody.high_risk_note;
        reqParam[configForm.renderForm.reference_id]["reinvestment"] =
          reqBody.reinvestment;
        reqParam[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
      } else if (props.tab === "action") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["business_date"] =
          formatedDate2(reqBody.business_date);
        reqParam[configForm.renderForm.reference_id]["business_action"] =
          reqBody.business_action;
        reqParam[configForm.renderForm.reference_id]["business_assigned"] =
          reqBody.business_assigned;
        reqParam[configForm.renderForm.reference_id]["business_status"] =
          reqBody.business_status;
        const referenceId = configForm.renderForm.reference_id;
        const businessCategory =
          props.section === "assets"
            ? CaseCategoryMap.assets
            : props.section === "liabilities"
            ? CaseCategoryMap[referenceId] ?? 0
            : 1;

        reqParam[referenceId]["business_category"] = businessCategory;
      } else if (props.tab === "valuation") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["effective_date"] =
          reqBody.effective_date;
        reqParam[configForm.renderForm.reference_id]["valuation_amount"] =
          parseFloat(reqBody.valuation_amount);
        reqParam[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
      } else if (props.tab === "commission") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["payment_id"] = subItemId;
        reqParam[configForm.renderForm.reference_id]["commission_type"] =
          reqBody.commission_type;
        reqParam[configForm.renderForm.reference_id]["payment_source"] =
          reqBody.payment_source;
        reqParam[configForm.renderForm.reference_id]["amount_to_company"] =
          parseFloat(reqBody.amount_to_company);
        reqParam[configForm.renderForm.reference_id]["frequency"] =
          reqBody.frequency;
        reqParam[configForm.renderForm.reference_id]["start_date"] =
          formatedDate2(reqBody.start_date);
        reqParam[configForm.renderForm.reference_id]["end_date"] =
          formatedDate2(reqBody.end_date);
        reqParam[configForm.renderForm.reference_id]["nb_register"] = parseInt(
          reqBody.nb_register
        );
        reqParam[configForm.renderForm.reference_id]["payment_count"] =
          reqBody.payment_count;
        reqParam[configForm.renderForm.reference_id]["status"] =
          parseInt(reqBody.status) || 0;
        reqParam[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
      } else if (props.tab === "compliance") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["consultant_id"] =
          reqBody.consultant_id;

        reqParam[configForm.renderForm.reference_id]["consultant"] =
          reqBody.consultant_id === "" ? "" : consultantName;
        reqParam[configForm.renderForm.reference_id]["client_agreement"] =
          formatedDate2(reqBody.client_agreement);
        reqParam[configForm.renderForm.reference_id]["fact_find_completed"] =
          formatedDate2(reqBody.fact_find_completed);
        reqParam[configForm.renderForm.reference_id]["high_risk"] =
          reqBody.high_risk;
        reqParam[configForm.renderForm.reference_id]["high_risk_note"] =
          reqBody.high_risk_note;
        reqParam[configForm.renderForm.reference_id]["type_of_sale"] =
          reqBody.type_of_sale;
        reqParam[configForm.renderForm.reference_id]["business_card"] =
          formatedDate2(reqBody.business_card);
        reqParam[configForm.renderForm.reference_id]["submitted"] =
          formatedDate2(reqBody.submitted);
        reqParam[configForm.renderForm.reference_id]["replacement"] =
          reqBody.replacement;
        reqParam[configForm.renderForm.reference_id]["replacement_reason"] =
          reqBody.replacement_reason;
        reqParam[configForm.renderForm.reference_id]["sanctions_check"] =
          formatedDate2(reqBody.sanctions_check);
        reqParam[configForm.renderForm.reference_id]["terms_of_business_sent"] =
          formatedDate2(reqBody.terms_of_business_sent);
        reqParam[configForm.renderForm.reference_id]["idd"] = formatedDate2(
          reqBody.idd
        );
        reqParam[configForm.renderForm.reference_id]["menu"] = formatedDate2(
          reqBody.menu
        );
        reqParam[configForm.renderForm.reference_id][
          "data_protection_form_sent"
        ] = formatedDate2(reqBody.data_protection_form_sent);
        reqParam[configForm.renderForm.reference_id]["risk_profile"] =
          formatedDate2(reqBody.risk_profile);
        reqParam[configForm.renderForm.reference_id][
          "verification_of_identity"
        ] = formatedDate2(reqBody.verification_of_identity);
        reqParam[configForm.renderForm.reference_id]["research_note"] =
          reqBody.research_note;
        reqParam[configForm.renderForm.reference_id]["illustration"] =
          formatedDate2(reqBody.illustration);
        reqParam[configForm.renderForm.reference_id]["research_completed"] =
          formatedDate2(reqBody.research_completed);
        reqParam[configForm.renderForm.reference_id]["key_features_sent"] =
          formatedDate2(reqBody.key_features_sent);
        reqParam[configForm.renderForm.reference_id]["key_features_received"] =
          formatedDate2(reqBody.key_features_received);
        reqParam[configForm.renderForm.reference_id][
          "suitability_letter_sent"
        ] = formatedDate2(reqBody.suitability_letter_sent);
        reqParam[configForm.renderForm.reference_id]["docs_received"] =
          formatedDate2(reqBody.docs_received);
        reqParam[configForm.renderForm.reference_id]["docs_sent"] =
          formatedDate2(reqBody.docs_sent);
      } else if (props.tab == "payment") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["consultant"] =
          reqBody.consultant_id === "" ? "" : consultantName;
        reqParam[configForm.renderForm.reference_id]["start_date"] =
          formatedDate2(reqBody.start_date);
        reqParam[configForm.renderForm.reference_id]["end_date"] =
          formatedDate2(reqBody.end_date);
        reqParam[configForm.renderForm.reference_id]["amount"] = parseFloat(
          reqBody.amount
        );
        reqParam[configForm.renderForm.reference_id]["frequency"] =
          reqBody.frequency;
        reqParam[configForm.renderForm.reference_id]["reinvestment"] =
          reqBody.reinvestment;
        reqParam[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
        reqParam[configForm.renderForm.reference_id]["payment_type"] =
          reqBody.payment_type;
        if (reqBody.new_business_register) {
          reqParam[configForm.renderForm.reference_id][
            "new_business_register"
          ] = reqBody.new_business_register;
        }
      } else if (props.tab === "distribution") {
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          caseDetail.customer_id ? caseDetail.customer_id : clientId;
        reqParam[configForm.renderForm.reference_id]["case_id"] = caseId;
        reqParam[configForm.renderForm.reference_id]["commission_id"] =
          subSubItemId;
        reqParam[configForm.renderForm.reference_id]["status"] = reqBody.status
          ? reqBody.status
          : 0;
        reqParam[configForm.renderForm.reference_id]["party_type"] =
          reqBody.party_type;
        reqParam[configForm.renderForm.reference_id]["party_name"] =
          reqBody.party_name;

        reqParam[configForm.renderForm.reference_id]["case_type"] =
          reqBody.case_type;
        reqParam[configForm.renderForm.reference_id]["commission_rule_id"] =
          reqBody.commission_rule_id;
        reqParam[configForm.renderForm.reference_id]["current_rule"] =
          reqBody.current_rule;
        reqParam[configForm.renderForm.reference_id]["amount"] = reqBody.amount;
        reqParam[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
      }
    }

    if (props.mode === "add") {
      setFormError([]);
      setLoading(true);
      setApiServiceLoading(true);
      if (props.tab === "fund") {
        try {
          const response = await apiServices.fund.add(reqParam);
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetFundsListItems({ id: caseId, customerId: clientId })
                  );
                } else {
                  await dispatch(
                    getPolicyFundsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "withdrawal") {
        try {
          const response = await apiServices.Withdrawals.add(reqParam);
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetWithdrawalsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                } else {
                  await dispatch(
                    getPolicyWithdrawalsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "valuation") {
        try {
          const response = await apiServices.valuation.addvaluation(reqParam);
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section == "assets") {
                  await dispatch(
                    getAssetValuationsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                } else {
                  await dispatch(
                    getPolicyValuationsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "action") {
        try {
          const response = await apiServices.Actions.addActions(reqParam);
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section == "assets") {
                  await dispatch(
                    getAssetActionsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                } else if (props.section == "liabilities") {
                  await dispatch(
                    getLiabilityActionsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                } else {
                  await dispatch(
                    getPolicyActionsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "payment") {
        try {
          const response = await apiServices.Payments.addPayment(reqParam);
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                } else if (props.section === "liabilities") {
                  await dispatch(
                    getLiabilityPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                } else {
                  await dispatch(
                    getPolicyPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "commission") {
        try {
          const response = await apiServices.commission.addCommission(reqParam);
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section && subItemId) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetPaymentsCommissionsListItems({
                      paymentId: subItemId,
                      caseId: caseId,
                      customerId: clientId,
                    })
                  );
                } else if (props.section === "liabilities") {
                  await dispatch(
                    getLiabilityPaymentsCommissionsListItems({
                      paymentId: subItemId,
                      caseId: caseId,
                      customerId: clientId,
                    })
                  );
                } else {
                  await dispatch(
                    getPolicyPaymentsCommissionsListItems({
                      paymentId: subItemId,
                      caseId: caseId,
                      customerId: clientId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "distribution") {
        const addValue = Object.values(reqParam)[0];
        try {
          const response = await apiServices.distribution.add(addValue);
          setLoading(false);
          setApiServiceLoading(false);
          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (
              clientId &&
              caseId &&
              props.section &&
              subItemId &&
              subSubItemId
            ) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetCommissionsDistributionListItems({
                      commissionId: subSubItemId,
                    })
                  );
                } else if (props.section === "liabilities") {
                  await dispatch(
                    getLiabilityCommissionDistributionListItems({
                      commissionId: subSubItemId,
                    })
                  );
                } else {
                  await dispatch(
                    getPolicyCommissionDistributionListItems({
                      commissionId: subSubItemId,
                    })
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission/${subSubItemId}/distribution`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else {
        if (props.section == "assets") {
          apiServices.assets
            .createNew(reqParam)
            .then((response) => {
              setLoading(false);
              setApiServiceLoading(false);
              if (!response.data.isError) {
                setTimeout(() => {
                  props.notify(response.data.message, "", "success");
                  AddSelectOptions();
                  dispatch(resetFormData());
                  if (clientId) {
                    dispatch(getAssetListItems(clientId));
                  }

                  dispatch(reloadData(true));
                  navigate(-1);
                }, 1000);
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              setApiServiceLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else if (props.section == "liabilities") {
          apiServices.liability
            .createNew(reqParam)
            .then((response) => {
              setLoading(false);
              setApiServiceLoading(false);
              if (!response.data.isError) {
                setTimeout(() => {
                  props.notify(response.data.message, "", "success");
                  AddSelectOptions();
                  dispatch(resetFormData());
                  if (clientId) {
                    dispatch(getLiabilityListItems(clientId));
                  }
                  navigate(-1);
                }, 1000);
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              setApiServiceLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else if (props.section == "policies") {
          apiServices.policy
            .createNew(reqParam)
            .then((response) => {
              setLoading(false);
              setApiServiceLoading(false);
              if (!response.data.isError) {
                setTimeout(() => {
                  props.notify(response.data.message, "", "success");
                  AddSelectOptions();
                  dispatch(resetFormData());
                  if (clientId) {
                    dispatch(getPolicyListItems(clientId));
                  }
                  navigate(-1);
                }, 1000);
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              setApiServiceLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        }
      }
    } else if (props.mode === "edit") {
      setFormError([]);
      setLoading(true);
      setApiServiceLoading(true);
      if (props.tab == "fund") {
        try {
          const response = await apiServices.fund.update(
            props.activeItem.fund_id,
            reqParam
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetFundsListItems({ id: caseId, customerId: clientId })
                  );
                  await dispatch(
                    getAssetFundDetailById(props.activeItem.fund_id)
                  );
                } else {
                  await dispatch(
                    getPolicyFundsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getPolicyFundDetailById(props.activeItem.fund_id)
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${props.activeItem.fund_id}/${props.tab}/view`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "withdrawal") {
        try {
          const response = await apiServices.Withdrawals.update(
            props.activeItem.withdrawal_id,
            reqParam
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetWithdrawalsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getAssetWithdrawalDetailById(props.activeItem.withdrawal_id)
                  );
                } else {
                  await dispatch(
                    getPolicyWithdrawalsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getPolicyWithdrawalDetailById(
                      props.activeItem.withdrawal_id
                    )
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "valuation") {
        try {
          const response = await apiServices.valuation.updatevaluation(
            props.activeItem.valuation_id,
            reqParam
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section == "assets") {
                  await dispatch(
                    getAssetValuationsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getAssetValuationDetailById(props.activeItem.valuation_id)
                  );
                } else {
                  await dispatch(
                    getPolicyValuationsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getPolicyValuationDetailById(props.activeItem.valuation_id)
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "action") {
        try {
          const response = await apiServices.Actions.updateActions(
            props.activeItem.business_id,
            reqParam
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section == "assets") {
                  await dispatch(
                    getAssetActionsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getAssetActionDetailById(props.activeItem.business_id)
                  );
                } else if (props.section == "liabilities") {
                  await dispatch(
                    getLiabilityActionsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getLiabilityActionDetailById(props.activeItem.business_id)
                  );
                } else {
                  await dispatch(
                    getPolicyActionsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getPolicyActionDetailById(props.activeItem.business_id)
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "payment" || props.tab == "compliance") {
        try {
          const response = await apiServices.Payments.updatePayment(
            props.activeItem?.payment?.payment_id,

            reqParam
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (clientId && caseId && props.section) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getAssetPaymentDetailById(
                      props.activeItem?.payment?.payment_id
                    )
                  );
                } else if (props.section === "liabilities") {
                  await dispatch(
                    getLiabilityPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getLiabilityPaymentDetailById(
                      props.activeItem?.payment?.payment_id
                    )
                  );
                } else {
                  await dispatch(
                    getPolicyPaymentsListItems({
                      id: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getPolicyPaymentDetailById(
                      props.activeItem?.payment?.payment_id
                    )
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${props.activeItem?.payment?.payment_id}/${props.tab}/view`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "commission") {
        try {
          const response = await apiServices.commission.updateCommission(
            subSubItemId,

            reqParam
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (
              clientId &&
              caseId &&
              props.section &&
              subItemId &&
              subSubItemId
            ) {
              try {
                if (props.section === "assets") {
                  await getAssetPaymentsCommissionsListItems({
                    paymentId: subItemId,
                    caseId: caseId,
                    customerId: clientId,
                  });
                  await dispatch(
                    getAssetPaymentsCommissionDetailById(subSubItemId)
                  );
                } else if (props.section === "liabilities") {
                  await dispatch(
                    getLiabilityPaymentsCommissionsListItems({
                      paymentId: subItemId,
                      caseId: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getLiabilityPaymentsCommissionDetailById(subSubItemId)
                  );
                } else {
                  await dispatch(
                    getPolicyPaymentsCommissionsListItems({
                      paymentId: subItemId,
                      caseId: caseId,
                      customerId: clientId,
                    })
                  );
                  await dispatch(
                    getPolicyPaymentsCommissionDetailById(subSubItemId)
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else if (props.tab == "distribution") {
        const addValue = Object.values(reqParam)[0];
        try {
          const response = await apiServices.distribution.update(
            distributionId,
            addValue
          );
          setLoading(false);
          setApiServiceLoading(false);

          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            AddSelectOptions();
            dispatch(resetFormData());

            if (
              clientId &&
              caseId &&
              props.section &&
              subItemId &&
              subSubItemId &&
              distributionId
            ) {
              try {
                if (props.section === "assets") {
                  await dispatch(
                    getAssetCommissionsDistributionListItems({
                      commissionId: subSubItemId,
                    })
                  );
                  await dispatch(
                    getAssetCommissionsDistributionDetailById(distributionId)
                  );
                } else if (props.section === "liabilities") {
                  await dispatch(
                    getLiabilityCommissionDistributionListItems({
                      commissionId: subSubItemId,
                    })
                  );
                  await dispatch(
                    getLiabilityCommissionDistributionDetailById(distributionId)
                  );
                } else {
                  await dispatch(
                    getPolicyCommissionDistributionListItems({
                      commissionId: subSubItemId,
                    })
                  );
                  await dispatch(
                    getPolicyCommissionDistributionDetailById(subSubItemId)
                  );
                }
              } catch (error) {
                console.error("Error in dispatch chain:", error);
              }
            }

            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission/${subSubItemId}/distribution`
            );
          } else {
            if (
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              setFormError(
                Array.isArray(response.data.error.message)
                  ? response.data.error.message
                  : [response.data.error.message]
              );
            }
          }
        } catch (err: any) {
          setLoading(false);
          setApiServiceLoading(false);
          console.error(err);
          props.notify(err.message, "", "error");
        }
      } else {
        if (props.section == "assets") {
          apiServices.assets
            .updateAsset(props.activeItem.asset_id, reqParam)
            .then((response: any) => {
              setLoading(false);
              setApiServiceLoading(false);
              if (!response.data.isError) {
                setTimeout(() => {
                  props.notify(response.data.message, "", "success");
                  AddSelectOptions();
                  dispatch(resetFormData());

                  if (clientId && caseId) {
                    dispatch(getAssetListItems(clientId))
                      .then(() => {
                        return dispatch(
                          getAssetDetailById({
                            id: caseId,
                            customerId: clientId,
                          })
                        );
                      })
                      .catch((error) => {
                        console.error("Error in dispatch chain:", error);
                      });
                  }
                  navigate(-1);
                }, 1000);
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((e: Error) => {
              setLoading(false);
              setApiServiceLoading(false);
              console.log(e);
              props.notify(e.message, "", "error");
            });
        } else if (props.section == "liabilities") {
          apiServices.liability
            .update(props.activeItem.liability_id, reqParam)
            .then((response: any) => {
              setLoading(false);
              setApiServiceLoading(false);
              if (!response.data.isError) {
                setTimeout(() => {
                  props.notify(response.data.message, "", "success");
                  AddSelectOptions();
                  dispatch(resetFormData());
                  if (clientId && caseId) {
                    dispatch(getLiabilityListItems(clientId))
                      .then(() => {
                        return dispatch(
                          getLiabilityDetailById({
                            id: caseId,
                            customerId: clientId,
                          })
                        );
                      })
                      .catch((error) => {
                        console.error("Error in dispatch chain:", error);
                      });
                  }
                  navigate(-1);
                }, 1000);
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((e: Error) => {
              setLoading(false);
              setApiServiceLoading(false);
              console.log(e);
              props.notify(e.message, "", "error");
            });
        } else if (props.section == "policies") {
          apiServices.policy
            .update(props.activeItem.policy_id, reqParam)
            .then((response: any) => {
              setLoading(false);
              setApiServiceLoading(false);
              if (!response.data.isError) {
                setTimeout(() => {
                  props.notify(response.data.message, "", "success");
                  AddSelectOptions();
                  dispatch(resetFormData());
                  if (clientId && caseId) {
                    dispatch(getPolicyListItems(clientId))
                      .then(() => {
                        return dispatch(
                          getPolicyDetailById({
                            id: caseId,
                            customerId: clientId,
                          })
                        );
                      })
                      .catch((error) => {
                        console.error("Error in dispatch chain:", error);
                      });
                  }
                  navigate(-1);
                }, 1000);
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((e: Error) => {
              setLoading(false);
              setApiServiceLoading(false);
              console.log(e);
              props.notify(e.message, "", "error");
            });
        }
      }
    }
  };

  return (
    <div>
      <div>
        {props.mode && props.mode === "view" && (
          <>
            {props.subItem === "funds" ? (
              <FundCalculation calculatedData={props.fundValue} />
            ) : null}
            {props.subItem === "withdrawals" ? (
              <WithdrawalCalculation calculatedData={props.withdrawalValue} />
            ) : null}
            {props.subItem === "payments" && props.tab !== "commission" ? (
              <PaymentCalculation calculatedData={props.paymentValue} />
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              <div>
                Record {props?.currentRecord} of {props.caseList.length}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div>
                  <ASCButton
                    shape="rounded"
                    style={{ marginRight: 2, marginBottom: 6 }}
                    icon={<Edit16Filled />}
                    size="medium"
                    onClick={() => {
                      if (
                        props?.subItem != undefined &&
                        subItemId != undefined
                      ) {
                        if (
                          props.tab == "commission" &&
                          subSubItemId != undefined
                        ) {
                          navigate(
                            `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${props.tab}/${subSubItemId}/commission/edit`
                          );
                        }
                        if (
                          props.tab == "distribution" &&
                          distributionId != undefined
                        ) {
                          navigate(
                            `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission/${subSubItemId}/distribution/${distributionId}/distribution/edit`
                          );
                        } else {
                          navigate(
                            `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${props.tab}/edit`
                          );
                        }
                      } else {
                        navigate(
                          `/clients/${clientId}/${props.section}/${caseId}/${props.tab}/edit`
                        );
                      }
                    }}
                    label="Edit"
                  />
                  {[
                    "ASSET_INVESTMENT_ASSET",
                    "ASSET_SHAREHOLDINGS_ASSET",
                    "ASSET_HOME_AND_PERSONAL_ASSET",
                    "ASSET_BANK_OR_BUILDING_SOCIETIES_ASSET",
                    "LIABILITIES_MORTGAGES_LIABILITY",
                    "LIABILITIES_LOAN_HIRE_PURCHASE_LIABILITY",
                    "LIABILITIES_CREDIT_CARDS_LIABILITY",
                    "POLICIES_LIFE_ASSURANCE_POLICIES",
                    "POLICIES_PENSIONS_POLICY",
                    "POLICIES_INVESTMENTS_POLICY",
                    "POLICIES_SAVINGS_PLANS_POLICY",
                    "POLICIES_INCOME_PROTECTION_POLICY",
                    "POLICIES_HEALTH_ASSURANCE_POLICY",
                    "POLICIES_GENERAL_POLICY",
                  ].includes(context) && (
                    <ASCButton
                      shape="rounded"
                      icon={<Delete16Regular />}
                      style={{
                        marginRight: 2,
                        marginBottom: 6,
                        color: "red",
                      }}
                      size="medium"
                      label="Delete"
                      onClick={() => {
                        setDeletePopup(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {formError && formError.length > 0 && (
        <FormErrorMessage
          errorMessages={formError}
          onDismiss={() => setFormError([])}
        />
      )}
      {props.mode && ["edit", "add"].includes(props.mode) && (
        <div className={classes.btnwrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label size="medium" weight="semibold" className={classes.message}>
              {formMode !== "" && (
                <>
                  <span>**</span> {formMode} <span>**</span>
                </>
              )}
            </Label>
          </div>
          {props.tab == "distribution" && (
            <div>
              <ASCButton
                shape="rounded"
                appearance="secondary"
                size="medium"
                style={{ marginRight: "10px" }}
                label="Choose Distribution Rule"
                onClick={() => {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      rule: true,
                      ruleId: ruleId,
                      getRuleId: getRuleId,
                      cancel: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Cancel",
                      },
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Select",
                        type: "primary",
                      },
                    })
                  );
                }}
              />

              {/* <ASCButton
                shape="rounded"
                appearance="secondary"
                size="medium"
                style={{ marginRight: "10px" }}
                label="Stop Distribution"
              /> */}
            </div>
          )}

          <div>
            <ASCButton
              shape="rounded"
              icon={<ArrowUndo16Regular />}
              onClick={undo}
              disabled={past !== undefined && past.length === 0 ? true : false}
            />
            <ASCButton
              shape="rounded"
              icon={<ArrowRedo16Regular />}
              onClick={redo}
              disabled={future.length === 0 ? true : false}
            />
          </div>
          <ASCButton
            shape="rounded"
            disabled={!isFormDataUpdated}
            appearance="transparent"
            onClick={() => {
              setPast([]);
              setFuture([]);
              setPresent(formik.initialValues);
              formik.resetForm();
              dispatch(resetFormData());
            }}
            label="Reset All"
          />
          {props.mode &&
            props.mode === "edit" &&
            props.showListItems === false && (
              <ASCButton
                shape="rounded"
                appearance="transparent"
                icon={<Delete16Regular />}
                onClick={() => {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1: "Do you want to delete?",
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "No",
                        type: "primary",
                      },
                      cancel: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Yes",
                      },
                    })
                  );
                }}
              />
            )}
        </div>
      )}
      <div>
        {context !== undefined && context !== "" && (
          <>
            <FormContent
              {...{
                formik,
                formFieldData,
                title: "",
                mode: props.mode,
                optionList: [],
                handleOnChange,
                customHandleChange,
                context,
                updateConsultantName,
                updateCommissionType,
                selectFieldOptions,
                newSelectOptionAdded,
                caseDetails: props.activeItem,
              }}
            />
            {props.mode !== "view" && (
              <div className={classes.buttonWrapper}>
                <ASCButton
                  shape="rounded"
                  className="asc-button-primary"
                  appearance="primary"
                  size="medium"
                  disabled={props.defaultAction === true ? true : false}
                  icon={apiServiceLoading ? <Spinner size="tiny" /> : null}
                  onClick={() => {
                    if (isFormDataUpdated) {
                      if (
                        activeClient?.basicDetails?.associated_with === null &&
                        formik.values.joint_indicator == 1
                      ) {
                        setPartnerDialogPopup(true);
                      } else {
                        formik.handleSubmit();
                      }
                    }
                  }}
                  label={apiServiceLoading ? "Processing" : "Save"}
                />

                <ASCButton
                  shape="rounded"
                  size="medium"
                  onClick={() => {
                    if (isFormDataUpdated) {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1:
                            "We have detected an attempt to move away from the current page.",
                          content_line_2:
                            "This would cause all changes made to be lost",
                          cancel: {
                            onclick: () => {
                              setPast([]);
                              setFuture([]);
                              formik.resetForm();
                              dispatch(setDialogModalOptions({ open: false }));
                              dispatch(resetFormData());

                              navigate(-1);
                            },
                            label: "Leave the Page",
                          },

                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Stay on Page",
                            type: "primary",
                          },
                        })
                      );
                    } else {
                      setPast([]);
                      setFuture([]);
                      formik.resetForm();
                      navigate(-1);
                    }
                  }}
                  label="Cancel"
                />
              </div>
            )}
          </>
        )}
      </div>
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        deleteData={deleteData}
        deleteApiProcessing={deleteApiProcessing}
      />
      <DeleteDependencyPopup
        dependencyDeletePopup={dependencyDeletePopup}
        dependencymessage={dependencymessage}
        setDependencyDeletePopup={setDependencyDeletePopup}
        deleteData={deleteData}
        deleteApiProcessing={deleteApiProcessing}
      />
      <PartnerDetailPopup
        notify={props.notify}
        mode={props.mode}
        partnerDialogPopup={partnerDialogPopup}
        setPartnerDialogPopup={setPartnerDialogPopup}
        isFormDataUpdated={isFormDataUpdated}
        formError={formError}
        setFormError={setFormError}
        newSelectOptionAdded={newSelectOptionAdded}
        activeCustomerId={props.activeCustomerId}
        activePartnerId={props.activePartnerId}
        setActivePartnerId={props.setActivePartnerId}
        customHandleChange={customHandleChange}
        section={props.section}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default CaseFormContainer;
