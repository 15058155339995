import React, { useEffect, useState } from "react";
import ButtonListComponent from "./ButtonList";
import FolderView from "./FolderView";
import apiServices from "../../../service";
import BreadCrumb from "./Breadcrumb";
import Search from "../../../components/Search";
import "./style.css";
import { useLocation } from "react-router-dom";
import { DocumentLoader } from "./docLoader";

interface FileItem {
  type: "file";
  name: string;
  path: string;
  lastModified: string;
  metadata: {
    customerid: string;
    userid: string;
    description: string;
    visible: string;
    documentid: string;
  };
}

interface FolderItem {
  type: "folder";
  name: string;
  path: string;
  lastModified: string;
  children: (FolderItem | FileItem)[];
}

type Item = FolderItem | FileItem;

function buildFileStructure(
  paths: any[],
  lastModifiedMap: Map<string, string>,
  searchQuery: string
) {
  let root: any[] = [];
  let searchResults: any[] = [];
  const pathMap = new Map();

  // ✅ First pass: Create all nodes (folders & files)
  paths.forEach(({ name, path, metadata, type }) => {
    const parts = path.split("/").filter(Boolean); // Remove empty segments
    let currentPath = "";
    let parent: any = null;

    parts.forEach((part: any, index: number) => {
      currentPath = parts.slice(0, index + 1).join("/");
      const isFolder = index < parts.length - 1 || type === "folder";

      if (!pathMap.has(currentPath)) {
        const node = {
          name: part,
          type: isFolder ? "folder" : "file",
          path: currentPath,
          lastModified: lastModifiedMap.get(path) || "",
          children: [],
          metadata: isFolder ? {} : metadata,
        };
        pathMap.set(currentPath, node);

        if (parent) parent.children.push(node);
        else root.push(node);
      }
      parent = pathMap.get(currentPath);
    });
  });

  // ✅ Search Filtering Logic (Files only)
  const filterItems = (items: any[], query: string): any[] => {
    return items.flatMap((item) => {
      if (
        item.type === "file" &&
        item.name.toLowerCase().includes(query.toLowerCase())
      ) {
        return item;
      }
      if (item.children) {
        const matchedChildren = filterItems(item.children, query);
        return matchedChildren.length > 0 ? [item, ...matchedChildren] : [];
      }
      return [];
    });
  };

  searchResults = searchQuery ? filterItems(root, searchQuery) : [];

  // ✅ Sorting Logic
  const sortStructure = (items: any[]) => {
    items.sort((a, b) =>
      a.type === b.type
        ? a.name.localeCompare(b.name)
        : a.type === "folder"
        ? -1
        : 1
    );
    items.forEach((item) => {
      if (item.type === "folder") sortStructure(item.children);
    });
  };

  sortStructure(root);
  return { root, searchResults };
}

export default function ClientDocument(props: any) {
  const [refresh, setRefresh] = useState(true);
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [fileStructure, setFileStructure] = useState<Item[]>([]);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [lastModifiedMap, setLastModifiedMap] = useState<Map<string, string>>(
    new Map()
  );
  const [basePath, setBasePath] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);

  useEffect(() => {
    GetAllFiles(props.activeCustomerId);
  }, [props.activeCustomerId]);

  const navigateTo = (
    path: string[],
    children: [] = [],
    isbreadcrumb = false
  ) => {
    setCurrentPath(path);
    if (search) setSearchResults(children);
    if (isbreadcrumb) setSearch("");
  };

  const findBasePath = (fileName: any): void => {
    setBasePath(`${fileName.split("/")[1]}`);
  };

  const GetAllFiles = (id: any) => {
    setLoading(true);
    apiServices.clientDocument
      .getAllList(id)
      .then((res) => {
        const lastModifiedMap = new Map();
        const files = res.data.filteredData.map((file: any) => {
          const isFolder = file.Key.endsWith("/");
          const parts = file.Key.split("/").filter(Boolean);
          const rawPath = parts.slice(2).join("/");

          // Preserve trailing slash for folders
          const relativePath = isFolder ? `${rawPath}/` : rawPath;

          lastModifiedMap.set(relativePath, file.LastModified);

          return {
            name: parts[parts.length - 1],
            path: relativePath,
            lastModified: file.LastModified,
            metadata: file.Metadata,
            type: isFolder ? "folder" : "file",
          };
        });
        setLoading(false);
        const [fileItem] = res.data.filteredData;
        findBasePath(fileItem.Key);
        setLastModifiedMap(lastModifiedMap);
        setFileList(files);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (search) {
      const searchLower = search.toLowerCase();
      const filteredItems = fileList.filter((item) => {
        const nameMatch = item.name.toLowerCase().includes(searchLower);
        const descMatch =
          item.type === "file" &&
          item.metadata.description?.toLowerCase().includes(searchLower);
        return nameMatch || descMatch;
      });

      setSearchResults(filteredItems);
      setFileStructure([]);
    } else {
      const { root } = buildFileStructure(fileList, lastModifiedMap, search);
      setFileStructure(root);
      setSearchResults([]);
    }
  }, [fileList, lastModifiedMap, search]);

  const getCurrentFolder = (path: string[], structure: Item[]): Item[] => {
    let current: Item[] = structure;
    for (const segment of path) {
      const found = (current as FolderItem[]).find(
        (item) => item.name === segment && item.type === "folder"
      );
      if (found && found.type === "folder") {
        current = found.children;
      } else {
        break;
      }
    }
    return current;
  };

  const currentFolder = getCurrentFolder(currentPath, fileStructure);
  return (
    <div className="client-document-container">
      <div className="button-container">
        <div className="top_header">
          <BreadCrumb path={currentPath} navigateTo={navigateTo} />
          <div className="flex-end">
            <Search setSearch={setSearch} search={search} />
            <ButtonListComponent
              setRefresh={() => setRefresh(!refresh)}
              currentPath={currentPath}
              GetAllFiles={GetAllFiles}
              notify={props.notify}
              activeCustomerId={props.activeCustomerId}
              basePath={basePath}
            />
          </div>
        </div>

        {loading ? (
          <DocumentLoader />
        ) : fileList.length > 0 ? (
          <FolderView
            structure={currentFolder}
            navigateTo={navigateTo}
            currentPath={currentPath}
            searchResults={searchResults}
            activeCustomerId={props.activeCustomerId}
            search={search}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "55vh",
              color: "#f00",
              fontWeight: 600,
              fontSize: 17,
            }}
          >
            There are no active records - click Add Folder or Add Document to
            create a new file
          </div>
        )}
      </div>
    </div>
  );
}
