import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import VulnerabilityForm from "./Vulnerability/VulnerabilityForm";
import ModalDialog from "../../components/ModalDialog";
import EmploymentForm from "./Employment/EmploymentForm";
import AttitudeToRiskForm from "./AttitudeToRisk/AttitudeToRiskForm";
import IdentityForm from "./Identity/IdentityForm";
import ObjectiveForm from "./objectives/ObjectiveForm";
import ServiceTypeForm from "./ServiceType/ServiceTypeForm";
function PartnerSubMenuItem(props: any) {
  const { clientId, section, action, assetId, partnerId, sectionId } =
    useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split("/");

  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const activeClient: any = useSelector(
    (state: RootState) => state.activeClient
  );
  const { clientInfo, notify } = useOutletContext<any>();
  const renderSectionComponent = () => {
    if (
      pathSegments.includes("partner") &&
      activeClient.basicDetails &&
      Object.keys(activeClient.basicDetails).length !== 0 &&
      activeClient.basicDetails.associated_with !== null
    ) {
      let partnerId = activeClient.basicDetails.associated_with;

      switch (props.section) {
        case "employment":
          return (
            <EmploymentForm
              activeCustomerId={partnerId}
              module="partner"
              notify={notify}
            />
          );
        case "attitude-to-risk":
          return (
            <AttitudeToRiskForm
              activeCustomerId={partnerId}
              module="partner"
              notify={notify}
            />
          );
        case "identity":
          return (
            <IdentityForm
              activeCustomerId={partnerId}
              module="partner"
              notify={notify}
            />
          );
        case "objectives":
          return (
            <ObjectiveForm
              activeCustomerId={partnerId}
              module="partner"
              notify={notify}
            />
          );
        case "service-type":
          return (
            <ServiceTypeForm
              activeCustomerId={partnerId}
              module="partner"
              notify={notify}
            />
          );
        case "vulnerability":
          return (
            <VulnerabilityForm
              activeCustomerId={partnerId}
              action={action}
              notify={notify}
              sectionId={sectionId}
              section={props.section}
              module="partner"
              partnerId={partnerId}
            />
          );
        default:
          return "";
      }
    }
  };
  return (
    <>
      <div>{renderSectionComponent()}</div>{" "}
      <ModalDialog options={dialogModalOptions} />
    </>
  );
}

export default PartnerSubMenuItem;
