import { Dropdown, Field, Option } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useState, useEffect } from "react";
import moment from "moment";
import { Value } from "react-quill";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { setSelectedDateValue } from "../../../redux/Commission/FilterSlice";

interface PropsType {
  optionDateValue?: string;
  selectedDateValue?:any;
  setSelectedDates: (value: string | null) => void;
}

const DateFilter: React.FC<PropsType> = ({
  optionDateValue,
  setSelectedDates,
}) => {
  const options = [
    { label: "Custom", value: "custom" },
    { label: "This Week", value: "thisWeek" },
    { label: "This Month", value: "thisMonth" },
    { label: "Last 12 Months", value: "last12Months" },
    { label: "Last Calendar Year", value: "lastCalendarYear" },
    { label: "Include All Dates", value: "includeAllDates" },
  ];

  const [context, setContext] = useState<string>("custom");
  const [contextLabel, setContextLabel] = useState<string>(
    optionDateValue || "Custom"
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date("1990-01-01"));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
    const dispatch: AppDispatch = useDispatch();
  
    const [optionDate, setOptionDate] = useState("");

  useEffect(() => {
    if (context === "custom" && startDate && endDate) {
      setSelectedDates(
        `${moment(startDate).format("DD/MM/YYYY")} - ${moment(endDate).format(
          "DD/MM/YYYY"
        )}`
      );
      dispatch(setSelectedDateValue(optionDate));
      setContextLabel("Custom");
    } else {
      const selectedOption = options.find((option) => option.value === context);
      setSelectedDates(selectedOption?.label || null);
      dispatch(setSelectedDateValue(selectedOption?.value || null))
    }
  }, [startDate, endDate, context, options, setSelectedDates]);

  useEffect(() => {
    if (startDate && endDate) {
      const newOption = `custom&start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}`;
      setOptionDate(newOption);
    }
  }, [startDate, endDate]);
  const onDropDownList = (e: any, data: any) => {
    setContext(data.optionValue);
    const selectedOption = options.find(
      (option) => option.value === data.optionValue
    );
    setContextLabel(selectedOption?.label || "Custom");
    if (data.optionValue !== "custom") {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(new Date("1990-01-01"));
      setEndDate(new Date());
    }
  };

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };
  return (
    <>
      <div className="date-filter-body">
        <Dropdown
          onOptionSelect={onDropDownList}
          value={contextLabel}
          placeholder="Select a date filter"
        >
          {options.map((option) => (
            <Option value={option.value} key={option.value}>
              {option.label}
            </Option>
          ))}
        </Dropdown>
        <div>
          <label style={{ color: "gray" }}>Choose a date range</label>
          <div style={{ display: "flex", gap: "10px" }}>
            <Field label="Start Date" style={{ marginBottom: "10px" }}>
              <DatePicker
                placeholder="mm/dd/yyyy"
                formatDate={onFormatDate}
                allowTextInput
                value={startDate || undefined}
                onSelectDate={(date: any) => setStartDate(date)}
                disabled={context !== "custom"}
              />
            </Field>
            <Field label="End Date">
              <DatePicker
                placeholder="mm/dd/yyyy"
                formatDate={onFormatDate}
                allowTextInput
                value={endDate || undefined}
                onSelectDate={(date: any) => setEndDate(date)}
                disabled={context !== "custom"}
              />
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateFilter;
