// store.ts
import { configureStore } from "@reduxjs/toolkit";
import someReducer from "./reducer";
import formSlice from "./formSlice";
import authSlice from "./authSlice";
import appSettingsSlice from "./appSettingsSlice";
import modelDialogReducer from "./modelDialogReducer";
import formUpdateReducer from "./formUpdateReducer";
import value from "./valueSlice";
import headerValue from "./headerValue";
import {
  clientpartner,
  clientReducer,
  clientsArray,
  conditionalclientpartner,
  filter,
  list,
  partnerIdsReducer,
  partnersArray,
  selectionFilter,
  splits,
  tableReducer,
} from "./clientSlice";
import tableDatas from "./tableDataSlice";
import clientPartnerSplit from "./splitSlice";
import splitData from "./dynamicResponse";
import loading from "./loader";
import activeClientSlice from "./activeClientSlice";
import commission from "./Commission/commissionSlice";
import assetsSlice from "./Case/assetSlice";
import liabilitiesSlice from "./Case/liabilitySlice";
import policiesSlice from "./Case/policySlice";
import opentimer from "./timerSlice";
import filterDate from "./Commission/FilterSlice";
import userPreferences from "./userPreferenceSlice";
import timeAllocation from "./timeAllocationSlice";
import providersSlice from "./providersSlice";
import string from "./SavedSearch/searchId";
import updatestring from "./SavedSearch/updateSearchId";
import selectionType from "./SavedSearch/extractTypes";
import ruleid from "./distributionRuleSlice";
const store = configureStore({
  reducer: {
    someReducer: someReducer,
    formSettings: formSlice,
    authUser: authSlice,
    appSettings: appSettingsSlice,
    dialog: modelDialogReducer,
    formUpdated: formUpdateReducer,
    value: value,
    clientid: clientReducer,
    headerValue: headerValue,
    tableData: tableReducer,
    partnerId: partnerIdsReducer,
    clients: clientsArray,
    partner: partnersArray,
    client: splits,
    tableDatas: tableDatas,
    clientPartnerSplit: clientPartnerSplit,
    splitData: splitData,
    loading: loading,
    list: list,
    clientpartner: clientpartner,
    conditionalclientpartner: conditionalclientpartner,
    selectionFilter: selectionFilter,
    filter: filter,
    activeClient: activeClientSlice,
    commission: commission,
    opentimer: opentimer,
    filterDate: filterDate,
    assets: assetsSlice,
    liabilities: liabilitiesSlice,
    policies: policiesSlice,
    userPreferences,
    providers: providersSlice,
    string: string,
    updatestring: updatestring,
    selectionType: selectionType,
    timeAllocation,
    ruleid: ruleid,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
