import { Badge, Button, Label, Tooltip } from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_RowSelectionState,
  MRT_PaginationState,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Edit16Filled } from "@fluentui/react-icons";
import ConfirmationPopup from "./ConfirmationPopup";
import StopcommissionPopup from "./StopcommissionPopup";
import { Input } from "@fluentui/react-components";
import FilterTab from "./FilterTab";
import apiServices from "../../../service";
import { DataRow } from "../CommissionTypes/ReceiptlistType";
import RecieptForm from "./RecieptForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setFilteredData,
  setlistItems,
} from "../../../redux/Commission/commissionSlice";
import SavePopup from "./savePopup";
import { formatedDate, getClientType } from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import ASCButton from "../../../components/Button";

const ReceiptList = (props: any) => {
  const [confirmationPopup, setConfirmationPopupPopup] = useState(false);
  const [editpopup, setEditpopup] = useState(false);
  const [stopReceiptpopup, setStopReceiptpopup] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [amountData, setAmountData] = useState("");
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [receiptEditpopup, setReceiptEditpopup] = useState(false);
  const [provider, setProvider] = useState([]);
  const [activeRowItem, setActiveRowItem] = useState<any | {}>({});
  const [selectedRowData, setSelectedRowData] = useState<string[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const recieptList: any = useSelector(
    (state: RootState) => state.commission?.listItems
  );
  const [filterPopup, setFilterPopup] = useState(false);

  const [totalExpected, setTotalExpected] = useState(0);

  const [receiptedAmount, setReceiptedAmount] = useState(0);
  const [unusedAmount, setUnusedAmount] = useState(0);
  const [confirmsavepopup, setConfirmsavepopup] = useState(false);
  const [IsContentloading, setIsContentLoading] = useState(true);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [actualValue, setActualValue] = useState("");
  const selectedDateValue = useSelector(
    (state: RootState) => state.filterDate?.selectedDateValue
  );
  const selectedProviders = useSelector(
    (state: RootState) => state.commission.selectedProviders
  );
  const getProviderList = async () => {
    let fieldLbl = "";
    try {
      fieldLbl = "provider:ModifiableList";
      const response = await apiServices.selectionList.getOptions(fieldLbl);
      if (response.data && response.data.data) {
        const providerNames = response.data.data[0].options.map(
          (item: any) => ({
            label: item.value,
            checked: true,
            id: item.id,
          })
        );
        setProvider(providerNames);
      }
    } catch (err) {
      console.error("Error fetching provider list:", err);
    }
  };
  // API Call
  useEffect(() => {
    getProviderList();
    getReceiptlistData();
  }, [loggedUser?.userId, pagination.pageIndex, pagination.pageSize]);

  const getReceiptlistData = async () => {
    try {
      const response = await apiServices.commissionModule.receipting.getList(
        loggedUser.userId,
        selectedDateValue,
        pagination.pageIndex + 1,
        pagination.pageSize,
        selectedProviders
      );
      setIsContentLoading(true);
      if (response.data && response.data.data.length > 0) {
        const resultData = response.data.data.map((object: any) => {
          setIsContentLoading(false);
          if (
            (object.category && object.case_type === "Mortgages") ||
            object.case_type === "Loans/Lease/HP"
          ) {
            return {
              ...object,
              expected: object.expected_amount || "0.0000",
              actual: object.amount_to_company || "0.0000",
              provider: object.liability_provider || "",
              category: object.category || "",
              client_name: `${object.first_names || ""} ${
                object.last_name || ""
              }`.trim(),
              reference: object.liability_reference || "",
              due_date: object.due_date || "",
              commission_type: object.commission_type || "",
              type: object.liability_type || "",
              subtype: object.liability_subtype || "",
              commission_note: object.note_text || "",
              case_owner: object.liability_owner || "",
              case_holder: object.liability_joint_indicator,
              periodic_benefit: object.periodic_benefit || "",
              ben_freq: object.ben_freq || "",
              payment: object.payment_amount || "",
              frequency: object.payment_frequency || "",
              start_date: object.payment_start_date || "",
            };
          } else if (
            (object.category && object.case_type === "Unit-linked") ||
            object.case_type === "Shares"
          ) {
            return {
              ...object,
              expected: object.expected_amount || "0.0000",
              actual: object.amount_to_company || "0.0000",
              provider: object.asset_provider || "",
              category: object.category || "",
              client_name: `${object.first_names || ""} ${
                object.last_name || ""
              }`.trim(),
              reference: object.asset_reference || "",
              due_date: object.due_date || "",
              commission_type: object.commission_type || "",
              commission_note: object.note_text || "",
              case_owner: object.asset_owner || "",
              type: object.asset_type || "",
              subtype: object.asset_subtype || "",
              case_holder: object.asset_joint_indicator,
              periodic_benefit: object.periodic_benefit || "",
              ben_freq: object.ben_freq || "",
              payment: object.payment_amount || "",
              frequency: object.payment_frequency || "",
              start_date: object.payment_start_date || "",
            };
          } else if (
            (object.category && object.case_type === "General") ||
            object.case_type === "Life Assurance" ||
            object.case_type === "Pensions" ||
            object.case_type === "Investments" ||
            object.case_type === "Savings Plans" ||
            object.case_type === "Income Protection" ||
            object.case_type === "Health Assurance" 
          ) {
            return {
              ...object,
              expected: object.expected_amount || "0.0000",
              actual: object.amount_to_company || "0.0000",
              provider: object.asset_provider || "",
              category: object.category || "",
              client_name: `${object.first_names || ""} ${
                object.last_name || ""
              }`.trim(),
              reference: object.policy_reference || "",
              due_date: object.due_date || "",
              commission_type: object.commission_type || "",
              commission_note: object.note_text || "",
              case_owner: object.policy_owner || "",
              type: object.policy_type || "",
              subtype: object.policy_subtype || "",
              case_holder: object.policy_joint_indicator,
              periodic_benefit: object.periodic_benefit || "",
              ben_freq: object.ben_freq || "",
              payment: object.payment_amount || "",
              frequency: object.payment_frequency || "",
              start_date: object.payment_start_date || "",
            };
          }
          else{
            return {
              ...object,
              expected: object.expected_amount || "0.0000",
              actual: object.amount_to_company || "0.0000",
              provider: object.provider || "",
              category: object.category || "",
              client_name: `${object.first_names || ""} ${
                object.last_name || ""
              }`.trim(),
              reference: object.reference || "",
              due_date: object.due_date || "",
              commission_type: object.commission_type || "",
              commission_note: object.note_text || "",
              case_owner: object.asset_owner || "",
              type: object.asset_type || "",
              subtype: object.asset_subtype || "",
              case_holder: object.asset_joint_indicator,
              periodic_benefit: object.periodic_benefit || "",
              ben_freq: object.ben_freq || "",
              payment: object.payment_amount || "",
              frequency: object.payment_frequency || "",
              start_date: object.payment_start_date || "",
            };
          } 

          return null;
        });

        const filteredData = resultData.filter((item: any) => item !== null);
        dispatch(setlistItems(filteredData));
      } else {
        setIsContentLoading(true);
        dispatch(setlistItems(""));
      }
    } catch (error) {
      setIsContentLoading(true);
      console.error("Error fetching receipt list data:", error);
    }
  };
  useEffect(() => {
    getProviderList();
  }, []);

  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      { accessorKey: "expected", header: "Expected" },
      {
        accessorKey: "actual",
        header: "Actual",
        Cell: ({ cell, row }: { cell: MRT_Cell<DataRow>; row: any }) => (
          <Input
            style={{ border: "none" }}
            className="hover-border"
            value={cell.getValue<string>()}
            onChange={(e) => setActualValue(e.target.value)}
          />
        ),
      },
      {
        accessorKey: "due_date",
        header: "Due Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.due_date)}</>,
      },
      { accessorKey: "provider", header: "Provider" },
      { accessorKey: "category", header: "Category" },
      { accessorKey: "client_name", header: "Client Name" },

      { accessorKey: "reference", header: "Reference" },

      { accessorKey: "commission_type", header: "Commission Type" },
      {
        accessorKey: "commission_note",
        header: "Commission Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.commission_note} />
          </>
        ),
      },
      { accessorKey: "case_owner", header: "Case Owner" },
      {
        accessorKey: "case_holder",
        header: "Case Holder",
        Cell: ({ renderedCellValue, row }) => (
          <>{getClientType(row.original.case_holder)}</>
        ),
      },
      { accessorKey: "type", header: "Type" },
      { accessorKey: "subtype", header: "Subtype" },
      { accessorKey: "consultant", header: "Consultant" },
      { accessorKey: "fund_value", header: "Fund Value" },
      {
        accessorKey: "value_date",
        header: "Value Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
      },
      { accessorKey: "benifit", header: "Benefit" },
      { accessorKey: "periodic_benefit", header: "Periodic Benefit" },
      { accessorKey: "ben_freq", header: "Ben Freq" },
      { accessorKey: "payment", header: "Payment" },
      { accessorKey: "frequency", header: "Frequency" },
      {
        accessorKey: "start_date",
        header: "Start Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
      },
    ],
    []
  );
  useEffect(() => {
    if (selectedRowData.length === 1 && Array.isArray(recieptList)) {
      const result = recieptList.find(
        (item: any) => item.commission_id === selectedRowData[0]
      );
      if (result) {
        dispatch(setFilteredData(result));
      }
    }
  }, [selectedRowData, recieptList]);

  useEffect(() => {
    if (recieptList.length > 0 && Object.keys(rowSelection).length > 0) {
      const selectedRowIndex = Object.keys(rowSelection).find(
        (key) => rowSelection[key]
      );
      if (selectedRowIndex !== undefined) {
        const selectedRow = recieptList[parseInt(selectedRowIndex)];
        setActiveRowItem(selectedRow);
      }
    }
  }, [rowSelection, recieptList]);
  useEffect(() => {
    if (amountData) {
      const total = Object.keys(selectedRow)
        .filter((key) => selectedRow[key]) // Filter selected rows
        .reduce((sum, rowIndex) => {
          const row = recieptList[parseInt(rowIndex)];
          return sum + (row?.actual ? parseFloat(row.actual) : 0);
        }, 0);
      setReceiptedAmount(total);
    } else {
      setReceiptedAmount(0);
    }
  }, [selectedRow, recieptList, amountData]);
  useEffect(() => {
    const unused = parseFloat(amountData) - receiptedAmount;
    setUnusedAmount(isNaN(unused) ? 0 : unused);
  }, [amountData, receiptedAmount]);

  return (
    <div>
      <div className="commission_options">
        <div className="commission_filter">
          <Label>Amount Received</Label>
          <Input
            value={amountData}
            onChange={(e) => setAmountData(e.target.value)}
            disabled={
              !amountData &&
              !Object.values(selectedRow).some((isSelected) => isSelected)
            }
            type="number"
          />
          <ASCButton
            shape="rounded"
            className="asc-button-primary"
            appearance="primary"
            disabled={!amountData}
            onClick={() => {
              const allSelectedRows = recieptList.reduce(
                (acc: any, row: any, index: any) => {
                  acc[row.commission_id] = true;
                  return acc;
                },
                {} as MRT_RowSelectionState
              );

              setRowSelection(allSelectedRows);
              setSelectedRow(allSelectedRows);
              setSelectedRowData(
                recieptList.map((row: any) => row.commission_id)
              ); // Update selectedRowData with all commission_ids
            }}
            label="Auto Apply"
          />
          <p>Receipted</p>
          <Label>{receiptedAmount.toFixed(2)}</Label> {/* Display sum */}
          <p>Unused</p>
          <Label>{unusedAmount.toFixed(2)}</Label> {/* Display difference */}
        </div>
        <ASCButton
          shape="rounded"
          className="asc-button-primary"
          appearance="primary"
          disabled={!amountData}
          onClick={() => {
            setConfirmsavepopup(true);
          }}
          label="Save"
        />
      </div>
      <div className="client-list-wrapper">
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gap: "10px",
              }}
            >
              <div>
                <ASCButton
                  shape="rounded"
                  onClick={() => {
                    setConfirmationPopupPopup(true);
                  }}
                  disabled={
                    Object.values(selectedRow).filter(
                      (isSelected) => isSelected
                    ).length === 1
                      ? false
                      : true
                  }
                  label="Adjust Commission"
                />
              </div>
              <FilterTab
                filterPopup={filterPopup}
                setFilterPopup={setFilterPopup}
                selectedMenuItem="Select Provider"
                title="Select Providers"
                providerOptions={provider}
                getReceiptlistData={getReceiptlistData}
                // getReceiptlistDta={getReceiptlistDta()}
              />
            </div>
          )}
          columns={columns}
          data={recieptList ? recieptList : []}
          enableColumnFilters={false}
          enableGlobalFilter={false}
          enableDensityToggle={false}
          enableRowSelection={true}
          enableSelectAll={false}
          enableColumnActions={false}
          enableMultiRowSelection={true}
          enableRowActions={false}
          enableHiding={false}
          enableFullScreenToggle={true}
          onPaginationChange={setPagination}
          state={{ pagination }}
          // muiTablePaginationProps={{
          //   rowsPerPageOptions: [5, 10, 50, 100],
          //   showFirstButton: false,
          //   showLastButton: false,
          // }}
          manualPagination
          enableStickyHeader
          enablePinning
          initialState={{
            columnPinning: {
              left: ["mrt-row-select", "expected", "actual"],
            },
            density: "compact",
          }}
          displayColumnDefOptions={{
            "mrt-row-select": {
              header: "Received",
              size: 60,
              enablePinning: false,
            },
          }}
          muiSelectCheckboxProps={({ row }: any) => ({
            onClick: () => {
              setRowSelection({ [row.index]: true });
              const rowId = row.id;
              const isSelected = !selectedRow[rowId];

              setSelectedRow((prev) => ({
                ...prev,
                [rowId]: isSelected,
              }));

              setSelectedRowData(
                (prevData: any) =>
                  isSelected
                    ? [...prevData, row.original.commission_id] // Add commission_id
                    : prevData.filter(
                        (id: any) => id !== row.original.commission_id
                      ) // Remove commission_id
              );
            },
            selected: selectedRow[row.id],
            color: "primary",
          })}
          positionActionsColumn="last"
          positionToolbarAlertBanner="none"
          muiTableContainerProps={{
            style: {
              minHeight: "200px",
              maxHeight: "100vh",
              overflow: "auto",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: "14px",
              lineHeight: "0.9",
            },
          }}
          renderRowActions={({ row, table }) => (
            <Tooltip content="Edit" relationship="label" positioning="below">
              <ASCButton
                shape="rounded"
                appearance="subtle"
                onClick={() => {
                  setConfirmationPopupPopup(true);
                }}
                icon={<Edit16Filled primaryFill="var(--button-color)" />}
              />
            </Tooltip>
          )}
        />
      </div>

      <ConfirmationPopup
        setConfirmationPopupPopup={setConfirmationPopupPopup}
        confirmationPopup={confirmationPopup}
        editpopup={editpopup}
        setReceiptEditpopup={setReceiptEditpopup}
        setEditpopup={setEditpopup}
      />
      <RecieptForm
        setReceiptEditpopup={setReceiptEditpopup}
        setStopReceiptpopup={setStopReceiptpopup}
        receiptEditpopup={receiptEditpopup}
        getReceiptlistData={getReceiptlistData}
      />
      <StopcommissionPopup
        setStopReceiptpopup={setStopReceiptpopup}
        stopReceiptpopup={stopReceiptpopup}
      />
      <SavePopup
        confirmsavepopup={confirmsavepopup}
        setConfirmsavepopup={setConfirmsavepopup}
      />
    </div>
  );
};

export default ReceiptList;
