import React, { useState, Dispatch, SetStateAction, FC } from "react";
import {
  useId,
  Field,
  Toaster,
  Button,
  Input,
  Dropdown,
} from "@fluentui/react-components";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import CreatableSelect from "react-select/creatable";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  booleanOperators,
  dateOperators,
  filterConditionalTypeData,
  filterTypeData,
  stringOperators,
} from "../../../shared/extractTypes";
import { useSelector } from "react-redux";
import ASCButton from "../../../components/Button";

type ValuePredictionProps = {
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDialogOpens: Dispatch<SetStateAction<boolean>>;
  searchId: any;
  selectedFieldName: string;
  extract: { [key: string]: any }[];
  setExtract: Dispatch<SetStateAction<{ [key: string]: any }[]>>;
  getExtracts: () => void;
  tableName: string;
  columnName: string;
  setApplyFilter: Dispatch<SetStateAction<boolean>>;
  selectedItems: any;
  setEncodedCondition: Dispatch<SetStateAction<string | null>>;
  encodedCondition: string | null;
  selectedFieldNameTableTwo: string;
  selectedColumnNameTwo: string;
  selectedItemTwo: any;
  selectedTableNameTwo: any;
  selectedTableName: any;
  selectedColumnName: any;
  setTabOpen: any;
  hasTableTwo: any;
  selectedItem: any;
  isPartner: any;
  selectedFiledTypeTableTwo: any;
  selectedFieldType: any;
};

export const ValuePrediction: FC<ValuePredictionProps> = ({
  setIsDialogOpen,
  setIsDialogOpens,
  selectedItemTwo,
  setTabOpen,
  selectedColumnName,
  searchId,
  selectedFieldName,
  tableName,
  columnName,
  setApplyFilter,
  selectedItems,
  setEncodedCondition,
  selectedFieldNameTableTwo,
  selectedItem,
  selectedColumnNameTwo,
  selectedTableNameTwo,
  selectedTableName,
  hasTableTwo,
  isPartner,
  selectedFiledTypeTableTwo,
  selectedFieldType,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientDetail, setClientDetail] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedValueTableTwo, setSelectedValueTableTwo] = useState<
    string | null
  >(null);
  const [isFilteredDataType, setIsFilteredDataType] = useState<
    filterTypeData[]
  >([]);
  const [isConditionalFilteredDataType, setIsConditionalFilteredDataType] =
    useState<filterConditionalTypeData[]>([]);
  const toasterId = useId("toaster");
  const types = isFilteredDataType;
  const conditionDataType = isConditionalFilteredDataType;
  const clientpartner = useSelector(
    (state: any) => state.clientpartner.storedValue
  );

  const conditionalClientPartner = useSelector(
    (state: any) => state.conditionalclientpartner.storedValue
  );

  const handleCancel = () => {
    setSelectedValue(null);
    setSelectedValueTableTwo(null);
    setClientDetail([]);
    setMenuOpen(false);
    setApplyFilter(false);
    setIsDialogOpen(false);
    setIsDialogOpens(false);
  };

  const getClientDetails = (tableName: string, columnName: string) => {
    setLoading(true);
    apiServices.extract

      .getClientList(tableName, columnName)
      .then((response: any) => {
        setLoading(false);
        if (!response.data.isError) {
          if (response.data[0].options) {
            setClientDetail(response.data[0].options);
          }
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        console.error("API Error:", e);
      });
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(date);
  };
  const handleConditionApply = () => {
    if (searchId) {
      if (selectedValue) {
        setApplyFilter(true);
        setMenuOpen(false);

        try {
          if (selectedItems?.Condition) {
            const parsedCondition = JSON.parse(selectedItems.Condition);

            parsedCondition.tableOne = {
              table: selectedTableName || parsedCondition.tableOne?.table || "",
              fieldName:
                selectedColumnName || parsedCondition.tableOne?.fieldName || "",
              label: selectedFieldName || parsedCondition.tableOne?.label || "",
              fk: parsedCondition.tableOne?.fk || "",
              fieldValue: selectedValue,
              condition: parsedCondition.tableOne?.condition || "equals",
              partner: parsedCondition.tableOne?.partner || false,
            };

            if (selectedValueTableTwo) {
              parsedCondition.tableTwo = {
                table:
                  selectedTableNameTwo || parsedCondition.tableTwo?.table || "",
                fieldName:
                  selectedColumnNameTwo ||
                  parsedCondition.tableTwo?.fieldName ||
                  "",
                label:
                  selectedFieldNameTableTwo ||
                  parsedCondition.tableTwo?.label ||
                  "",
                fk: parsedCondition.tableTwo?.fk || "",
                fieldValue: selectedValueTableTwo,
                condition: parsedCondition.tableTwo?.condition || "equals",
                partner: parsedCondition.tableTwo?.partner || false,
              };
            }

            const encodedString = encodeURIComponent(
              JSON.stringify(parsedCondition)
            );
            setEncodedCondition(encodedString);
            setIsDialogOpen(false);
            setIsDialogOpens(false);
            setTabOpen(false);
            setMenuOpen(false);
          } else {
          }
        } catch (error) {
          console.error("Error parsing or updating Condition JSON:", error);
        }
      } else {
      }
    } else {
    }
  };

  return (
    <>
      <OverlayLoader isLoading={loading} />
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "250px",
          maxHeight: "250px",
        }}
      >
        {!selectedItem && (
          <Field
            label={
              <span>
                Enter the Value of {isPartner ? "Partner " : "Customer "}{" "}
                {selectedFieldName}
              </span>
            }
          >
            {selectedFieldType === "timestamp" ? (
              <DatePicker
                allowTextInput
                value={selectedValue ? new Date(selectedValue) : undefined}
                formatDate={(date) => formatDate(date)}
                onSelectDate={(date) => {
                  setSelectedValue(date?.toISOString() || null);
                }}
                placeholder="Date"
              />
            ) : selectedFieldType === "uuid" ? (
              <Dropdown />
            ) : selectedFieldType === "string" ? (
              <CreatableSelect
                isClearable
                options={clientDetail}
                isLoading={loading}
                onFocus={() => {
                  if (tableName && columnName) {
                    getClientDetails(tableName, columnName);
                  } else {
                  }
                }}
                onChange={(newValue) => {
                  setSelectedValue(newValue?.value || null);
                }}
              />
            ) : (
              <Input
                onChange={(e: any) => {
                  const value = e.target.value;
                  setSelectedValue(value);
                }}
                name="selectedValue"
                type={types.toString() === "number" ? "number" : "text"}
                placeholder={types.toString() === "number" ? "Number" : "Value"}
              />
            )}
          </Field>
        )}

        {(selectedItemTwo === "" ||
          (selectedItem === "" && selectedItemTwo === "")) &&
          hasTableTwo == true && (
            <Field
              label={
                <span>
                  Enter the Value of {isPartner ? "Partner " : "Customer "}{" "}
                  {selectedFieldNameTableTwo}
                </span>
              }
            >
              {selectedFiledTypeTableTwo === "timestamp" ? (
                <DatePicker
                  allowTextInput
                  value={
                    selectedValueTableTwo
                      ? new Date(selectedValueTableTwo)
                      : undefined
                  }
                  formatDate={(date) => formatDate(date)}
                  onSelectDate={(date) => {
                    setSelectedValueTableTwo(date?.toISOString() || null);
                  }}
                  placeholder="Date"
                />
              ) : selectedFiledTypeTableTwo === "uuid" ? (
                <Dropdown />
              ) : selectedFiledTypeTableTwo === "string" ? (
                <CreatableSelect
                  isClearable
                  options={clientDetail}
                  isLoading={loading}
                  onFocus={() => {
                    if (selectedTableNameTwo && selectedColumnNameTwo) {
                      getClientDetails(
                        selectedTableNameTwo,
                        selectedColumnNameTwo
                      );
                    } else {
                    }
                  }}
                  onChange={(newValue) => {
                    setSelectedValueTableTwo(newValue?.value || null);
                  }}
                />
              ) : (
                <Input
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedValueTableTwo(value);
                  }}
                  name="selectedValueTableTwo"
                  type={
                    conditionDataType.toString() === "number"
                      ? "number"
                      : "text"
                  }
                  placeholder={
                    conditionDataType.toString() === "number"
                      ? "Number"
                      : "Value"
                  }
                  // value={selectedValueTableTwo}
                />
              )}
            </Field>
          )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
          margin: "10px",
        }}
      >
        <ASCButton
          shape="rounded"
          appearance="secondary"
          onClick={handleCancel}
          label="Cancel"
        />
        <ASCButton
          shape="rounded"
          style={{ backgroundColor: "#0f6cbd" }}
          appearance="primary"
          className="asc-button-primary"
          onClick={handleConditionApply}
          label="Apply"
        />
      </div>
    </>
  );
};
