import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getAssetDetailById,
  getAssetPaymentDetailById,
  getAssetPaymentsListItems,
  getAssetPaymentsCommissionDetailById,
  getAssetPaymentsCommissionsListItems,
  getAssetCommissionsDistributionDetailById,
  getAssetCommissionsDistributionListItems,
} from "../../../../redux/Case/assetSlice";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import { getCaseCategoryByCaseType } from "../../../../utils";
import { Label, Toaster } from "@fluentui/react-components";
import { makeStyles, tokens } from "@fluentui/react-components";
import CaseDetailDrawer from "../CaseDetailDrawer";
import { useToastMessage } from "../../../../components/ToastMessage/ToastMessage";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function AssetCommissionDistribution({ tab }: { tab?: string }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const { clientId, action, caseId, subItemId, subSubItemId, distributionId } =
    useParams();
  const dispatch: AppDispatch = useDispatch();
  const { showToast, toasterId } = useToastMessage();

  const assets = useSelector((state: RootState) => state.assets);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const [caseType, setCaseType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(tab || "distribution");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId && subItemId && subSubItemId) {
      dispatch(getAssetDetailById({ id: caseId, customerId: clientId }));
      dispatch(getAssetPaymentDetailById(subItemId));
      dispatch(
        getAssetPaymentsCommissionsListItems({
          paymentId: subItemId,
          caseId,
          customerId: clientId,
        })
      );
      dispatch(
        getAssetCommissionsDistributionListItems({
          commissionId: subSubItemId,
        })
      );
    }
  }, [caseId, clientId, subItemId, subSubItemId, dispatch]);

  useEffect(() => {
    if (assets?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(assets.activeItem.case_type));
    }
  }, [assets.activeItem]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getAssetPaymentDetailById(subItemId));
    }
  }, [subItemId, activeTab]);

  useEffect(() => {
    if (subSubItemId) {
      dispatch(getAssetPaymentsCommissionDetailById(subSubItemId));
    }
  }, [subSubItemId, activeTab]);

  useEffect(() => {
    if (distributionId) {
      dispatch(getAssetCommissionsDistributionDetailById(distributionId));
    }
  }, [subSubItemId, distributionId, activeTab]);

  useEffect(() => {
    if (
      action &&
      action != "add" &&
      assets.payments.commissions.distribution > 0 &&
      subSubItemId
    ) {
      navigate(
        `/clients/${clientId}/assets/${caseId}/payments/${subItemId}/commission/${subSubItemId}/${
          tab || "distribution"
        }/${
          distributionId ||
          assets.payments.commissions.distribution.listItems[0]
            .commission_allocation_id
        }/distribution/${action || "view"}`
      );
    }
  }, [
    assets.payments.commissions.distribution.listItems,
    subItemId,
    subSubItemId,
    action,
    clientId,
    caseId,
    tab,
    navigate,
  ]);

  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <CaseNavList
        subItem="payments"
        section="assets"
        tab={activeTab}
        caseType={caseType}
        caseDetail={assets.payments.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="assets"
          caseType={caseType}
          caseDetail={assets.payments.commissions.distribution.activeItem}
          caseItemList={assets.payments.commissions.distribution.listItems}
          tab={activeTab}
          subItem="payments"
          notify={showToast}
        />
        <div>
          {assets?.payments.commissions.distribution.loading ? (
            <ContentLoader />
          ) : (
            <>
              {assets?.payments.commissions.distribution.listItems.length >
                0 && (
                <CaseList
                  module="distribution"
                  data={assets?.payments.commissions.distribution.listItems}
                  activeItem={
                    assets?.payments.commissions.distribution.activeItem
                  }
                  section="assets"
                  activeTab={tab}
                />
              )}
              {!action &&
                assets?.payments.commissions.distribution.listItems.length ===
                  0 && (
                  <div className={styles.emptyState}>
                    <Label size="large" style={{ color: "red" }}>
                      There are no active records - click Add New to create a
                      new record
                    </Label>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={assets?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default AssetCommissionDistribution;
