import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";

import { getCaseCategoryByCaseType } from "../../../../utils";
import { Label, Toaster } from "@fluentui/react-components";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import { makeStyles, tokens } from "@fluentui/react-components";
import CaseDetailDrawer from "../CaseDetailDrawer";
import {
  getPolicyDetailById,
  getPolicyFundDetailById,
  getPolicyFundsListItems,
} from "../../../../redux/Case/policySlice";
import { useToastMessage } from "../../../../components/ToastMessage/ToastMessage";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

const PolicyFunds = ({ tab = "fund" }: { tab?: string }) => {
  const navigate = useNavigate();
  const { clientId, action, caseId, subItemId } = useParams();
  const styles = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { showToast, toasterId } = useToastMessage();
  const policies = useSelector((state: RootState) => state.policies);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const [caseType, setCaseType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(tab);
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getPolicyDetailById({ id: caseId, customerId: clientId }));
      dispatch(getPolicyFundsListItems({ id: caseId, customerId: clientId }));
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (policies?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(policies.activeItem.case_type));
    }
  }, [policies?.activeItem]);
  useEffect(() => {
    if (policies?.funds.listItems.length > 0) {
      const fundId = subItemId || policies.funds.listItems[0]?.fund_id;
      navigate(
        `/clients/${clientId}/policies/${caseId}/funds/${fundId}/${tab}/${
          action || "view"
        }`
      );
    }
  }, [
    clientId,
    caseId,
    subItemId,
    tab,
    action,
    policies.funds.listItems,
    navigate,
  ]);
  useEffect(() => {
    if (subItemId) {
      dispatch(getPolicyFundDetailById(subItemId));
    }
  }, [subItemId, dispatch, policies.funds.listItems]);

  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <CaseNavList
        subItem="funds"
        section="policies"
        tab={activeTab}
        caseType={caseType}
        caseDetail={policies?.funds?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="policies"
          caseType={caseType}
          caseDetail={policies?.funds?.activeItem}
          caseItemList={policies?.funds?.listItems}
          tab={activeTab}
          subItem="funds"
          notify={showToast}
        />
        <div>
          {policies?.funds?.listLoading ? (
            <ContentLoader />
          ) : policies?.funds?.listItems?.length > 0 ? (
            <CaseList
              module="funds"
              data={policies.funds.listItems}
              activeItem={policies.funds.activeItem}
              section="policies"
              activeTab={activeTab}
            />
          ) : (
            !action && (
              <div className={styles.emptyState}>
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </div>
            )
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={policies?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
};

export default PolicyFunds;
